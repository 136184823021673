import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from "prop-types";
import { Modal } from "antd";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const ConfirmationBox = ({ showConfirmationBox, message, confirmSubmit, declineSubmit }) => {
    const classes = useStyles();

    return (
        <Modal visible={showConfirmationBox} closable={false} footer={null}>
            <h2 style={{ textAlign: "center" }}>
              {message}
            </h2>
            <div className="d-flex j-center f-row">
              <div className="confirmbtn btn" onClick={confirmSubmit}>
                Yes
              </div>
              <div
                className="confirmbtn btn"
                onClick={declineSubmit}
              >
                No
              </div>
            </div>
          </Modal>
    );
}

ConfirmationBox.propTypes = {
    showConfirmationBox: PropTypes.bool,
    confirmSubmit: PropTypes.func,
    declineSubmit: PropTypes.func,
};

export default ConfirmationBox;