import React from "react";
import "./Setting.css";
import SettingNavigationLabel from "../Common/SettingNavigationLabel";

const Setting = (props) => {
  return (
    <ul className="tabsBlock">
      <SettingNavigationLabel label="SMS Setting" url="sms-setting" active={props.page === "SMSSetting" ? true : false} />
      <SettingNavigationLabel label="Email Setting" url="email-setting" active={props.page === "EmailSetting" ? true : false} />
      <SettingNavigationLabel label="Payment Gateway" url="payment-gateway" active={props.page === "PaymentGateway" ? true : false} />
      <SettingNavigationLabel label="Service Type" url="service-type" active={props.page === "ServiceType" ? true : false} />
      <SettingNavigationLabel label="Consultancy Prices" url="consultancy-prices" active={props.page === "ConsultancyPrices" ? true : false} />
      <SettingNavigationLabel label="Company Type" url="company-type" active={props.page === "CompanyType" ? true : false} />
    </ul>
  );
};

export default Setting;
