import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MUIDataTable from "mui-datatables";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Button from "@material-ui/core/Button";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { fetchOrganizations } from "../../../actions/organization";

const myTheme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveBase: {
        maxHeight: "50vh",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    float: "right",
    cursor: "pointer",
  },
  helpText: {
    width: "80%",
  },
  btn: {
    padding: "4px 10px 3px 10px",
    borderRadius: 36,
    backgroundColor: "#f99600",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    width: "auto",
    display: "inline-block",
    cursor: "pointer",
  },
}));

const AssetCompanySelect = ({
  companyList,
  onCompanySelect,
  isOpen,
  onClose,
}) => {
  const classes = useStyles();
  const [organization, setOrganization] = useState([]);
  const [orgCompany, setOrgCompany] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getOrgData() {
      const response = await dispatch(fetchOrganizations({limit: 1000}));
      const orgData = Object.values(response.payload.entities.organizations);
      mapCompanyToOrg(orgData);
    }
    getOrgData();
  }, [dispatch]);

  const mapCompanyToOrg = (orgData) => {
    orgData.map((org) => {
      let companies = companyList.filter(
        (company) => company.organization_id === org.organization_id
      );
      if (companies.length > 0) org.companies = companies;

      org.selectedCompanyId =
        companies.length > 0 ? companies[0].company_id : "";
    });
    setOrganization(orgData);
  };

  const getSelectedCompany = (companyValues) => {
    let orgData = organization.find(
      (org) => org.organization_id === companyValues[0].organization_id
    );
    return orgData.selectedCompanyId;
  };
  const handleChange = (event) => {
    let companies = companyList.find(
      (company) => company.company_id === event.target.value
    );
    let orgData = organization;
    orgData.map((org) => {
      if (org.organization_id === companies.organization_id) {
        org.selectedCompanyId = event.target.value;
      }
    });

    setOrganization([]);
    setTimeout(() => {
      setOrganization(orgData);
    }, 0);
  };

  const columns = [
    {
      name: "organization_name",
      label: "Organization",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "companies",
      label: "Company",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value ? (
              <FormControl>
                <Select
                  value={getSelectedCompany(value)}
                  onChange={handleChange}
                >
                  {value.map((comp) => (
                    <MenuItem value={comp.company_id}>
                      {comp.company_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <p className={classes.helpText}>
                Please add a company to add assets
              </p>
            )}
          </>
        ),
      },
    },
    {
      name: "selectedCompanyId",
      label: "Business Id",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className="">
            {value !== "" &&
              companyList.find((company) => company.company_id === value)
                .business_id}
          </div>
        ),
      },
    },
    {
      name: "selectedCompanyId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button
            variant="contained"
            classes={{
              contained: classes.btn,
            }}
            disabled={value == ""}
            onClick={() => goToAssets(value)}
          >
            Add Asset
          </Button>
        ),
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    fixedHeader: true,
    download: false,
    print: false,
    viewColumns: false,
  };

  const goToAssets = (company_id) => {
    let selectedCompany = companyList.find(
      (comp) => comp.company_id === company_id
    );
    console.log(selectedCompany);
    onCompanySelect({
      company_id: company_id,
      company_name: selectedCompany.company_name,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
        Selected Company
        <HighlightOffIcon className={classes.closeBtn} onClick={onClose} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <MuiThemeProvider theme={myTheme}>
          <MUIDataTable
            title={""}
            data={organization}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </DialogContent>
    </Dialog>
  );
};

AssetCompanySelect.propTypes = {
  companyList: PropTypes.array,
  onCompanySelect: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AssetCompanySelect;