import React, { useState, useEffect } from "react";
import FolderIcon from '@material-ui/icons/Folder';
import PropTypes from "prop-types";

import { Typography, Tooltip } from 'antd';
const { Paragraph } = Typography;

const Vault = ({data, onVaultSelect}) => {

	return (
		<div className="vault" onClick={() => onVaultSelect(data.key)}>
            <div className="vault-content">
            <Tooltip title={data.name}>
                <Paragraph className='vault-text'
                    ellipsis={true}
                >
                    <>{data.name}</>
                </Paragraph>
                </Tooltip>
                
                <p>
                    {data.countString}
                </p>
            </div>
            <FolderIcon className="vault-bg" />
        </div>
    )
};

Vault.propTypes = {
    data: PropTypes.shape({}).isRequired,
    onVaultSelect: PropTypes.func,
  };

export default Vault;