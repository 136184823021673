import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin, Modal, Form, Input, Button, message, Pagination, Avatar } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";
import moment from 'moment'; 

import { fetchMessageCommunicationInbox, updateMessageCommunication } from "../../actions/messages";
import MessageReplyCard from './MessageReplyCard';

const MessagesCard = ({tenderId, tenderTitle, creator_id}) => {
  const dispatch = useDispatch();

  const [totalCount, setTotalCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [messages, setMessages] = useState([]);
  const [currentTenderId, setCurrentTenderId] = useState();
  const [currentTenderTitle, setCurrentTenderTitle] = useState();
  const [currentSenderId, setCurrentSenderId] = useState();
  const [currentMessageId, setCurrentMessageId] = useState();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
       getMsgData(1, pageSize);
    }, [dispatch]);

    const getMsgData = async(page, pageSize) => {
        setConfirmLoading(true);
        const response = await dispatch(fetchMessageCommunicationInbox({limit: pageSize, offset: pageSize*(page-1), order_by: 'created_at', sort_by: 'DESC' }));
        const count = response?.payload?.data?.meta?.count;
        const result = response?.payload?.data?.result;
        setConfirmLoading(false);
        setTotalCount(count);
        setMessages(result);
    }

    const replyToMsg = (msg, index) => {
        setVisible(false);
        setCurrentTenderId(msg?.entity_meta?.tender_id);
        setCurrentTenderTitle(msg?.entity_meta?.title);
        setCurrentSenderId(msg?.sender_id);
        setCurrentMessageId(msg?.message_id)
        setVisible(true);
        
        if(!msg.is_read)
            markAsRead(msg.message_id, index);
    }

    const onPaginationChange = (page, pageSize) => {
        setPageSize(pageSize);
        getMsgData(page, pageSize);
    }

    const markAsRead = async(message_id, index) => {
        await dispatch(updateMessageCommunication(message_id, {is_read: true}));
        setMessages((c) => {
            const newList = [...c];
            newList[index].is_read = true;
            return newList;
        });
    }

    const markAsReplied = async() => {
        const index = messages.findIndex(msg => {
                return msg.message_id === currentMessageId;
            }
        )
        await dispatch(updateMessageCommunication(currentMessageId, {is_replied: true}));
        setMessages((c) => {
            const newList = [...c];
            newList[index].is_replied = true;
            return newList;
        });
    }
 
  return (
    <Spin spinning={confirmLoading}>
        <div className="dataBlock card msgCard">
            <ul className="dataTitle list2">
              <li className="w-5">From</li>
              <li>Date</li>
              <li>Time</li>
              <li>Tender</li>
              <li>Last Message By</li>
            </ul>
            {
                messages?.map((msg,index) => (
                    <div key={index} className="list-items" onClick={() => replyToMsg(msg,index)}>
                        <div className="profilePic">
                            <Avatar className="msgAvatar">{msg?.sender_meta?.name.substring(0,1)}</Avatar>
                        </div>
                        <ul className={ !msg.is_read ? 'dataContent list2 bold' : 'dataContent list2'}>
                            <li className="d-flex w-3">
                            <span>{msg?.sender_meta?.name}{msg.is_read}</span>
                            </li>
                            <li>
                            <span>{moment(msg.created_at).format('DD-MM-YY')}</span>
                            </li>
                            <li>
                            <span>{moment(msg.created_at).format('hh:mm A')}</span>
                            </li>
                            <li>
                            <span>{msg?.entity_meta?.title}</span>
                            </li>
                            <li className="d-flex">
                            <span>{msg.is_replied ? msg?.receiver_meta?.name : msg?.sender_meta?.name }
                                {/* {
                                    msg.is_replied &&
                                        <RollbackOutlined className="reply_outline"/>
                                } */}
                            </span>
                            {/* <span className="startIcon"></span> */}
                            </li>
                        </ul>
                        </div>
                ))
            }
            
            <Pagination
                className="msgsPagination"
                size="small"
                showSizeChanger
                onChange={onPaginationChange}
                defaultCurrent={1}
                total={totalCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
        </div>
        {
            visible && 
            <MessageReplyCard hideModal={() => setVisible(false)} modalOpen={visible} markAsReplied={markAsReplied} tenderId={currentTenderId} tenderTitle={currentTenderTitle} receiverId={currentSenderId} messageId={currentMessageId}/>
        }
    </Spin>
  )
}

export default MessagesCard;