import React from "react";
import PropTypes from "prop-types";
import Header from "../Header/Header";
import Tenders from "./Tenders/Tenders";
import "./Dashboard.css";
import MessagesCard from "../MessagesCard";
import profilePic from "../../assets/user-img2.png";
import profilePic2 from "../../assets/user-img3.png";
import profilePic3 from "../../assets/user-img4.png";

const Dashboard = (props) => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="leftSidePanel">
          <Tenders />
        </div>
        <div className="rightSidePanel">
          <MessagesCard />
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
