import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import { handleResponse, handleError, handleQuery, handleDataSuccessResponse } from "../utils";
import { domainUrl } from "../helper";

export const FETCH_PRETENDERS_REQUEST = "FETCH_PRETENDERS_REQUEST";
export const FETCH_PRETENDERS_SUCCESS = "FETCH_PRETENDERS_SUCCESS";
export const FETCH_PRETENDERS_FAILURE = "FETCH_PRETENDERS_FAILURE";

export const FETCH_PRETENDER_REQUEST = "FETCH_PRETENDER_REQUEST";
export const FETCH_PRETENDER_SUCCESS = "FETCH_PRETENDER_SUCCESS";
export const FETCH_PRETENDER_FAILURE = "FETCH_PRETENDER_FAILURE";

export const POST_PRETENDER_REQUEST = "POST_PRETENDER_REQUEST";
export const POST_PRETENDER_SUCCESS = "POST_PRETENDER_SUCCESS";
export const POST_PRETENDER_FAILURE = "POST_PRETENDER_FAILURE";

export const PATCH_PRETENDER_REQUEST = "PATCH_PRETENDER_REQUEST";
export const PATCH_PRETENDER_SUCCESS = "PATCH_PRETENDER_SUCCESS";
export const PATCH_PRETENDER_FAILURE = "PATCH_PRETENDER_FAILURE";

export const PUT_PRETENDER_REQUEST = "PUT_PRETENDER_REQUEST";
export const PUT_PRETENDER_SUCCESS = "PUT_PRETENDER_SUCCESS";
export const PUT_PRETENDER_FAILURE = "PUT_PRETENDER_FAILURE";

export const DELETE_PRETENDER_REQUEST = "DELETE_PRETENDER_REQUEST";
export const DELETE_PRETENDER_SUCCESS = "DELETE_PRETENDER_SUCCESS";
export const DELETE_PRETENDER_FAILURE = "DELETE_PRETENDER_FAILURE";

export const PATCH_PRETENDER_STATUS_REQUEST = "PATCH_PRETENDER_STATUS_REQUEST";
export const PATCH_PRETENDER_STATUS_SUCCESS = "PATCH_PRETENDER_STATUS_SUCCESS";
export const PATCH_PRETENDER_STATUS_FAILURE = "PATCH_PRETENDER_STATUS_FAILURE";

export const FETCH_ASSETS_PRETENDER_REQUEST = "FETCH_ASSETS_PRETENDER_REQUEST";
export const FETCH_ASSETS_PRETENDER_SUCCESS = "FETCH_ASSETS_PRETENDER_SUCCESS";
export const FETCH_ASSETS_PRETENDER_FAILURE = "FETCH_ASSETS_PRETENDER_FAILURE";

export const POST_ASSET_PRETENDER_REQUEST = "POST_ASSET_PRETENDER_REQUEST";
export const POST_ASSET_PRETENDER_SUCCESS = "POST_ASSET_PRETENDER_SUCCESS";
export const POST_ASSET_PRETENDER_FAILURE = "POST_ASSET_PRETENDER_FAILURE";

export const PATCH_ASSET_PRETENDER_REQUEST = "PATCH_ASSET_PRETENDER_REQUEST";
export const PATCH_ASSET_PRETENDER_SUCCESS = "PATCH_ASSET_PRETENDER_SUCCESS";
export const PATCH_ASSET_PRETENDER_FAILURE = "PATCH_ASSET_PRETENDER_FAILURE";

export const DELETE_ASSET_PRETENDER_REQUEST = "DELETE_ASSET_PRETENDER_REQUEST";
export const DELETE_ASSET_PRETENDER_SUCCESS = "DELETE_ASSET_PRETENDER_SUCCESS";
export const DELETE_ASSET_PRETENDER_FAILURE = "DELETE_ASSET_PRETENDER_FAILURE";

export const FETCH_COMPANIES_PRETENDER_REQUEST = "FETCH_COMPANIES_PRETENDER_REQUEST";
export const FETCH_COMPANIES_PRETENDER_SUCCESS = "FETCH_COMPANIES_PRETENDER_SUCCESS";
export const FETCH_COMPANIES_PRETENDER_FAILURE = "FETCH_COMPANIES_PRETENDER_FAILURE";

export const POST_COMPANIES_PRETENDER_REQUEST = "POST_COMPANIES_PRETENDER_REQUEST";
export const POST_COMPANIES_PRETENDER_SUCCESS = "POST_COMPANIES_PRETENDER_SUCCESS";
export const POST_COMPANIES_PRETENDER_FAILURE = "POST_COMPANIES_PRETENDER_FAILURE";

export const PATCH_COMPANIES_PRETENDER_REQUEST = "PATCH_COMPANIES_PRETENDER_REQUEST";
export const PATCH_COMPANIES_PRETENDER_SUCCESS = "PATCH_COMPANIES_PRETENDER_SUCCESS";
export const PATCH_COMPANIES_PRETENDER_FAILURE = "PATCH_COMPANIES_PRETENDER_FAILURE";

export const DELETE_COMPANIES_PRETENDER_REQUEST = "DELETE_COMPANIES_PRETENDER_REQUEST";
export const DELETE_COMPANIES_PRETENDER_SUCCESS = "DELETE_COMPANIES_PRETENDER_SUCCESS";
export const DELETE_COMPANIES_PRETENDER_FAILURE = "DELETE_COMPANIES_PRETENDER_FAILURE";

export const preTenderSchema = new schema.Entity("preTender", [], {
  idAttribute: "pretender_id",
});

export const preTenderAssetSchema = new schema.Entity("preTenderAsset", [], {
    idAttribute: "pretender_line_id",
});
export const preTenderCompanySchema = new schema.Entity("preTenderCompany", [], {
    idAttribute: "company_id",
});


/**
 * Returns a redux api middleware action that makes a request to fetch all pre-tenders.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchPreTenders = (params) =>
 createAction({
   endpoint: `${domainUrl}/tender/v1/pre-tender?${handleQuery(params)}`,
   method: "GET",
   headers: {
     client_code: "ht_front",
     token: sessionStorage.getItem("token"),
     "Content-Type": "application/json",
   },
   types: [
     { type: FETCH_PRETENDERS_REQUEST },
     {
       type: FETCH_PRETENDERS_SUCCESS,
       payload: (action, state, res) =>
         handleResponse([preTenderSchema], action, state, res),
     },
     { type: FETCH_PRETENDERS_FAILURE, payload: handleError },
   ],
 });

 /**
 * Returns a redux api middleware action that makes a request to fetch pre-tender with pre-tender id.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
  export const fetchPreTender = (preTenderId) =>
  createAction({
    endpoint: `${domainUrl}/tender/v1/pre-tender/${preTenderId}`,
    method: "GET",
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: FETCH_PRETENDER_REQUEST },
      {
        type: FETCH_PRETENDER_SUCCESS,
        payload: (action, state, res) =>
        handleDataSuccessResponse([preTenderSchema], action, state, res),
      },
      { type: FETCH_PRETENDER_FAILURE, payload: handleError },
    ],
  });

/**
 * Returns a redux api middleware action that makes a request to create a pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createPreTender = (data) =>
 createAction({
   endpoint: `${domainUrl}/tender/v1/pre-tender`,
   method: "POST",
   body: JSON.stringify(data),
   headers: {
     client_code: "ht_front",
     token: sessionStorage.getItem("token"),
     "Content-Type": "application/json",
   },
   types: [
     { type: POST_PRETENDER_REQUEST },
     {
       type: POST_PRETENDER_SUCCESS,
       payload: (action, state, res) =>
         handleResponse(preTenderSchema, action, state, res),
     },
     { type: POST_PRETENDER_FAILURE, payload: handleError },
   ],
 });

 /**
 * Returns a redux api middleware action that makes a request to update a pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
  export const updatePreTender = (data,preTenderId) =>
  createAction({
    endpoint: `${domainUrl}/tender/v1/pre-tender/${preTenderId}`,
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: PUT_PRETENDER_REQUEST },
      {
        type: PUT_PRETENDER_SUCCESS,
        payload: (action, state, res) =>
          handleResponse(preTenderSchema, action, state, res),
      },
      { type: PUT_PRETENDER_FAILURE, payload: handleError },
    ],
  });

/**
 * Returns a redux api middleware action that makes a request to delete a pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
    export const deletePreTender = (preTenderId) =>
    createAction({
      endpoint: `${domainUrl}/tender/v1/pre-tender/${preTenderId}`,
      method: "DELETE",
      headers: {
        client_code: "ht_front",
        token: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      types: [
        { type: PUT_PRETENDER_REQUEST },
        {
          type: PUT_PRETENDER_SUCCESS,
          payload: (action, state, res) =>
            handleResponse(preTenderSchema, action, state, res),
        },
        { type: PUT_PRETENDER_FAILURE, payload: handleError },
      ],
    });

/**
 * Returns a redux api middleware action that makes a request to submit a pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
    export const submitPreTender = (data,preTenderId) =>
    createAction({
      endpoint: `${domainUrl}/tender/v1/pre-tender/${preTenderId}`,
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        client_code: "ht_front",
        token: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      types: [
        { type: PATCH_PRETENDER_REQUEST },
        {
          type: PATCH_PRETENDER_SUCCESS,
          payload: (action, state, res) =>
            handleResponse(preTenderSchema, action, state, res),
        },
        { type: PATCH_PRETENDER_FAILURE, payload: handleError },
      ],
    });

 /**
 * Returns a redux api middleware action that makes a request to get assets for pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
  export const fetchAssetsPreTender = (preTenderId) =>
  createAction({
    endpoint: `${domainUrl}/tender/v1/pre-tender/${preTenderId}/asset`,
    method: "GET",
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: FETCH_ASSETS_PRETENDER_REQUEST },
      {
        type: FETCH_ASSETS_PRETENDER_SUCCESS,
        payload: (action, state, res) =>
          handleResponse([preTenderAssetSchema], action, state, res),
      },
      { type: FETCH_ASSETS_PRETENDER_FAILURE, payload: handleError },
    ],
  });

/**
 * Returns a redux api middleware action that makes a request to create asset for pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createAssetPreTender = (data,preTenderId) =>
 createAction({
   endpoint: `${domainUrl}/tender/v1/pre-tender/${preTenderId}/asset`,
   method: "POST",
   body: JSON.stringify(data),
   headers: {
     client_code: "ht_front",
     token: sessionStorage.getItem("token"),
     "Content-Type": "application/json",
   },
   types: [
     { type: POST_ASSET_PRETENDER_REQUEST },
     {
       type: POST_ASSET_PRETENDER_SUCCESS,
       payload: (action, state, res) =>
         handleResponse(preTenderAssetSchema, action, state, res),
     },
     { type: POST_ASSET_PRETENDER_FAILURE, payload: handleError },
   ],
 });

 /**
 * Returns a redux api middleware action that makes a request to update asset for pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
  export const updateAssetPreTender = (data,preTenderLineId) =>
  createAction({
    endpoint: `${domainUrl}/tender/v3/pre-tender/asset/${preTenderLineId}`,
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: PATCH_ASSET_PRETENDER_REQUEST },
      {
        type: PATCH_ASSET_PRETENDER_SUCCESS,
        payload: (action, state, res) =>
          handleResponse(preTenderAssetSchema, action, state, res),
      },
      { type: PATCH_ASSET_PRETENDER_FAILURE, payload: handleError },
    ],
  });

/**
 * Returns a redux api middleware action that makes a request to delete asset for pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
    export const deleteAssetPreTender = (preTenderLineId) =>
    createAction({
      endpoint: `${domainUrl}/tender/v3/pre-tender/asset/${preTenderLineId}`,
      method: "DELETE",
      headers: {
        client_code: "ht_front",
        token: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      types: [
        { type: DELETE_ASSET_PRETENDER_REQUEST },
        {
          type: DELETE_ASSET_PRETENDER_SUCCESS,
          payload: (action, state, res) =>
            handleResponse(preTenderAssetSchema, action, state, res),
        },
        { type: DELETE_ASSET_PRETENDER_FAILURE, payload: handleError },
      ],
    });

/**
 * Returns a redux api middleware action that makes a request to fetch companies for pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchCompanyPreTender = (preTenderId) =>
 createAction({
   endpoint: `${domainUrl}/tender/v2/pre-tender/${preTenderId}/company`,
   method: "GET",
   headers: {
     client_code: "ht_front",
     token: sessionStorage.getItem("token"),
     "Content-Type": "application/json",
   },
   types: [
     { type: FETCH_COMPANIES_PRETENDER_REQUEST },
     {
       type: FETCH_COMPANIES_PRETENDER_SUCCESS,
       payload: (action, state, res) =>
         handleResponse([preTenderCompanySchema], action, state, res),
     },
     { type: FETCH_COMPANIES_PRETENDER_FAILURE, payload: handleError },
   ],
 });

/**
 * Returns a redux api middleware action that makes a request to create company for pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createCompanyPreTender = (data,preTenderId) =>
 createAction({
   endpoint: `${domainUrl}/tender/v2/pre-tender/${preTenderId}/companies`,
   method: "POST",
   body: JSON.stringify(data),
   headers: {
     client_code: "ht_front",
     token: sessionStorage.getItem("token"),
     "Content-Type": "application/json",
   },
   types: [
     { type: POST_COMPANIES_PRETENDER_REQUEST },
     {
       type: POST_COMPANIES_PRETENDER_SUCCESS,
       payload: (action, state, res) =>
         handleResponse([preTenderCompanySchema], action, state, res),
     },
     { type: POST_COMPANIES_PRETENDER_FAILURE, payload: handleError },
   ],
 });

 /**
 * Returns a redux api middleware action that makes a request to create company for pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
  export const deleteCompanyPreTender = (preTenderId, companyId) =>
  createAction({
    endpoint: `${domainUrl}/tender/v2/pre-tender/${preTenderId}/company/${companyId}`,
    method: "DELETE",
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: DELETE_COMPANIES_PRETENDER_REQUEST },
      {
        type: DELETE_COMPANIES_PRETENDER_SUCCESS,
        payload: (action, state, res) =>
          handleResponse(preTenderCompanySchema, action, state, res),
      },
      { type: DELETE_COMPANIES_PRETENDER_FAILURE, payload: handleError },
    ],
  });

 /**
 * Returns a redux api middleware action that makes a request to set status for pre-tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
  export const updateStatusPreTender = (data,preTenderId) =>
  createAction({
    endpoint: `${domainUrl}/tender/v3/pre-tender/${preTenderId}/status`,
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: PATCH_PRETENDER_STATUS_REQUEST },
      {
        type: PATCH_PRETENDER_STATUS_SUCCESS,
        payload: (action, state, res) =>
          handleResponse(preTenderSchema, action, state, res),
      },
      { type: PATCH_PRETENDER_STATUS_FAILURE, payload: handleError },
    ],
  });