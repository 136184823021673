import React, { useEffect, useState, useRef } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Spin, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import Header from "../../components/Header";
import { fetchTenderByParams } from '../../actions/tender';
import { getUser } from '../../actions/auth';
import { useHistory } from 'react-router';
import { getFilterValues } from '../../utils';

const AllTenders = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let role_id = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));
    const tenders = useSelector(state => get(state, `entities.tenders`));
    const stateUser = useSelector(state => get(state, `entities.users`));
    const [tendersFetched, setTendersFetched] = useState(false);
    const [tenderInfo, setTenderInfo] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [pagination, setPagination] = useState({
		pageSize: 10,
        total:0,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        onShowSizeChange: (page, pageSize) => {
            setPagination({
                ...pagination,
                pageSize: pageSize
            })
        }
	  });

    const searchInput = useRef();

    const getAllTenders = async() => {
        await dispatch(fetchTenderByParams({limit:1000, is_published: 1, order_by: 'updated_at', sort_by: 'DESC'}));
        await dispatch(fetchTenderByParams({limit:1000, is_published: 0,created_by: sessionStorage.getItem('user_id'), order_by: 'updated_at', sort_by: 'DESC'}));
        
        setTendersFetched(true);
    }

    useEffect(() => {
        getAllTenders();
    },[])

    const fetchUsers = async() => {
        const creators = getFilterValues('created_by', Object.values(tenders));
            await Promise.all(creators?.map(async user => {
                if(!stateUser[user.value]) {
                    await dispatch(getUser(user.value));
                }
            }));
            const sortedTenders = Object.values(tenders).sort(function(a,b){
              return new Date(b.updated_at) - new Date(a.updated_at);
            });
            const tenderInfos = sortedTenders?.map((tender, index) => {
                return {
                    ...tender,
                  created_by: stateUser[tender.created_by]?.name,
                 
                  status: tender?.is_awarded === 1 ? 'Awarded' : (tender?.is_published === 1 ? 'Published' : 'Draft')
                 // winner: suppliers[index]?.supplier_name,
                };
              });
            setTenderInfo(tenderInfos)
    }
    useEffect(() => {
        if(tendersFetched) {
            fetchUsers();
        }
    },[tendersFetched]);

    const navigateToTender = async(id, tableMeta) => {
        if(tableMeta.status == 'Draft') {
            history.push(`/tender/${id}`);
        }
        else {
            history.push(`/tender/detail/${id}`);
        }
    }


      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex)
      };

      const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
      };

    
    const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',

    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

    const columns = [
        {
          dataIndex: "title",
          title: "Tender Title",
          ...getColumnSearchProps('title')
        },
        {
            dataIndex: "organization_name",
            title: "Organization",
            filters: getFilterValues("organization_name", tenderInfo),
		      	onFilter: (value, record) => record.organization_name.indexOf(value) === 0,
          },
           {
            dataIndex: "company_name",
            title: "Company",
            filters: getFilterValues("company_name", tenderInfo),
			      onFilter: (value, record) => record.company_name.indexOf(value) === 0,
          },
        {
            dataIndex: "created_by",
            title: "Created By",
            filters: getFilterValues("created_by", tenderInfo),
			onFilter: (value, record) => record.created_by.indexOf(value) === 0,
          },
          {
            dataIndex: "bid_count",
            title: "Bids Submitted",
            sorter: (a, b) => a.bid_count - b.bid_count,
             render: (value, tableMeta, updateValue) => (
               
              <div>{value}{tableMeta.no_of_suppliers ? `/${tableMeta.no_of_suppliers}` : ``}</div>
              ),
          },
          {
            dataIndex: "status",
            title: "Status",
            filters: getFilterValues("status", tenderInfo),
			      onFilter: (value, record) => record.status.indexOf(value) === 0,
          },
          {
            dataIndex: "created_at",
            title: "Created Date",
            sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
            render: (value, tableMeta, updateValue) => (
             <div>{moment(value).format("DD-MM-YYYY")}</div>
            ),
          },
          {
            dataIndex: "updated_at",
            title: "Updated Date",
            sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
            render: (value, tableMeta, updateValue) => (
             <div>{moment(value).format("DD-MM-YYYY")}</div>
            ),
          },
        {
          dataIndex: "tender_id",
          title: "Action",
          render: (value, tableMeta, updateValue) => (
            <div className="viewIcon" onClick={() => navigateToTender(value, tableMeta)}>View</div>
          ),
        },
      ];
    
	return (
    <>
    <div className="__Tender__">

      <Header />
      <div className="mainContainer" style={{padding: 30}}>
          <h2 className="title">Tenders</h2>
      <Table
              className="ant-table-root"
			    scroll={{ x: 0, y: '40vh' }}
                loading={!tendersFetched}
              columns={columns}
              dataSource={tenderInfo}
              pagination={pagination}
              bordered
              rowKey='tender_id'
            />
      </div>
    </div>
    </>
  );
};
 export default AllTenders;
