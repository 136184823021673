import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Checkbox } from 'antd';
import logo from "../../assets/hissitaito-logo-2@3x.png";
import "../../components/Login/Login.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { domainUrl } from "../../helper";
import { getUser, login } from '../../actions/auth';
import { useDispatch, useSelector } from "react-redux";

const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 5,
    span: 16,
  },
};

const Login = (props) => {
  const [message, setMessage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  let auth = useSelector(state => state.authReducer.auth);

  const setUserData = async() => {
    sessionStorage.setItem('token',auth?.token);
    sessionStorage.setItem('user_id',auth?.user_id);
    await dispatch(getUser(auth?.user_id));
    history.push("/");
  }

  useEffect(() => {
    if(auth) {
      if(auth?.token) {
        setUserData();
      }
      else if(!auth?.success) {
        setMessage(auth?.error?.message);
      }
    }
    
  },[auth]);

  const performLogin = async (e) => {
    const loginData = e;
    setMessage("Please Wait...");
    await dispatch(login(loginData));
  };

  return (
    <div class="login__container">
      <div className="loginOuter">
        <div className="ht-logo2">
          <img src={logo} alt="logo" />
        </div>
          {message && <span className="errorMessageLogin">{message}</span>}
        <Form
      {...layout}
      name="basic"
      initialValues={{
      }}
      onFinish={performLogin}
    >
      <Form.Item
        label=""
        name="email"
        className="loginItem"
        rules={[
          {
            required: true,
            message: 'Please enter user name',
          },
        ]}
      >
        <Input className="form-control" placeholder="Username"/>
      </Form.Item>

      <Form.Item
        label=""
        name="password"
        className="loginItem"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password className="form-control" placeholder="Password"/>
      </Form.Item>
      <Form.Item {...tailLayout} className="btnFormItem">
        <Button type="primary" htmlType="submit" className="loginBtn">
          Login
        </Button>
      </Form.Item>
      <div className="labels">Forgot Your Password?</div>
    </Form>
        {/* <div className="formGroup">
          {message && <span>{message}</span>}
          {emailError && (
            <span className="errorMessageLogin">{emailError}</span>
          )}
          <input
            className="form-control"
            type="text"
            placeholder="Username"
            name="email"
            value={email}
            onChange={(e) => (
              setEmail(e.target.value), setEmailError(""), setMessage("")
            )}
          />
          {passwordError && (
            <span className="errorMessageLogin">{passwordError}</span>
          )}
          <input
            className="form-control"
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => (
              setPassword(e.target.value), setPasswordError(""), setMessage("")
            )}
          />
          <div className="loginBtn" onClick={() => performLogin()}>
            Login
          </div>
          <div className="labels">Forgot Your Password?</div>
        </div> */}
      </div>
    </div>
  );
};

Login.propTypes = {};

export default Login;
