import { schema, normalize } from "normalizr";
import { CUSTOM_AUTHORIZED_REQUEST } from '../middleware/apiAuthorization';
import {
	handleResponse,
	handleError,
} from "../utils";
import apiUrls  from "../utils/apiUrls";

export const FETCH_TENDER_COMPANIES_REQUEST = "FETCH_TENDER_COMPANIES_REQUEST";
export const FETCH_TENDER_COMPANIES_SUCCESS = "FETCH_TENDER_COMPANIES_SUCCESS";
export const FETCH_TENDER_COMPANIES_FAILURE = "FETCH_TENDER_COMPANIES_FAILURE";

export const CREATE_TENDER_COMPANIES_REQUEST = "CREATE_TENDER_COMPANIES_REQUEST";
export const CREATE_TENDER_COMPANIES_SUCCESS = "CREATE_TENDER_COMPANIES_SUCCESS";
export const CREATE_TENDER_COMPANIES_FAILURE = "CREATE_TENDER_COMPANIES_FAILURE";

export const UPDATE_TENDER_COMPANIES_REQUEST = "UPDATE_TENDER_COMPANIES_REQUEST";
export const UPDATE_TENDER_COMPANIES_SUCCESS = "UPDATE_TENDER_COMPANIES_SUCCESS";
export const UPDATE_TENDER_COMPANIES_FAILURE = "UPDATE_TENDER_COMPANIES_FAILURE";

export const DELETE_TENDER_COMPANIES_REQUEST = "DELETE_TENDER_COMPANIES_REQUEST";
export const DELETE_TENDER_COMPANIES_SUCCESS = "DELETE_TENDER_COMPANIES_SUCCESS";
export const DELETE_TENDER_COMPANIES_FAILURE = "DELETE_TENDER_COMPANIES_FAILURE";

const companySchema = new schema.Entity("tenderCompanies", [], {
	idAttribute: "company_id",
});

// const tenderCompanySchema = new schema.Entity(
// 	'tenderCompanies',
// 	{ ...companySchema},
// 	{
// 		idAttribute: 'tender_id',
// 		mergeStrategy: (entityA, entityB) => ({
// 			...entityA,
// 			...entityB,
// 		  }),

// 	}
// );


/**
 * Returns a redux api middleware action that makes a request to fetch companies in a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchTenderCompany = (tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderCompanyRequest(tender_id),
			method: "GET",
			types: [
				{ type: FETCH_TENDER_COMPANIES_REQUEST },
				{
					type: FETCH_TENDER_COMPANIES_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([companySchema], action, state, res),
				},
				{ type: FETCH_TENDER_COMPANIES_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to create companies for a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createTenderCompany = (data, tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderCompaniesRequest(tender_id),
			method: "POST",
            body: JSON.stringify(data),
			types: [
				{ type: CREATE_TENDER_COMPANIES_REQUEST },
				{
					type: CREATE_TENDER_COMPANIES_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([companySchema], action, state, res),
				},
				{ type: CREATE_TENDER_COMPANIES_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to edit companies in a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const deleteTenderCompany = (tender_id, company_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderCompanyByCompanyId(tender_id, company_id),
			method: "DELETE",
			types: [
				{ type: DELETE_TENDER_COMPANIES_REQUEST },
				{
					type: DELETE_TENDER_COMPANIES_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([companySchema], action, state, res),
				},
				{ type: DELETE_TENDER_COMPANIES_FAILURE, payload: handleError },
			],
		}
	}
}