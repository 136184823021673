import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table } from 'antd';
import Header from "../Header";
import "./Organization.css";
import axios from "axios";
import AddModal from "./AddModal";
import ConfirmationBox from '../ConfirmationBox';
import { domainUrl } from "../../helper";
import {
	fetchOrganizations,
} from "../../actions/organization";

const Organization = (props) => {
  const [openOrganizationFormModel, setOpenOrganizationFormModel] = useState({
    isOpen: false,
    isReadOnly: false,
  });
  const [organization, setOrganization] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10
  });
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deleteOrg, setDeleteOrg] = useState({});
  const dispatch = useDispatch();

  const getOrgNames = () => {
    let orgs = organizations.map(org => {
      return {
        text: org.organization_name,
        value: org.organization_name
        };
    })
    console.log(orgs);
    return orgs;
  }

  const columns = [

    {
      dataIndex: "organization_name",
      key: "organization_name",
      title: "Organization",
      filters: getOrgNames(),
      onFilter: (value, record) => record.organization_name.indexOf(value) === 0,
      sorter: {
        compare: (a, b) => {
          var nameA = a.organization_name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.organization_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        },
      },
    },
    {
      dataIndex: "organization_id",
      title: "View",
      key: "organization_id",
      render: (value) => {
        return (
          <div className="viewIcon" onClick={() => openOrganizationForm(value, { readOnly: true })}>View</div>
          );
     },
    },
    {
      dataIndex: "organization_id",
      key: "organization_id",
      title: "Modify",
      render: (value) => {
        return (
          <div
          className="btn-primary"
          onClick={() => openOrganizationForm(value, { readOnly: false })}
        >
          Modify
        </div>
          );
     },
    },
    {
      dataIndex: "organization_id",
      key: "organization_id",
      title: "Delete",
      render: (value) => {
        return (
          <div className="deleteIcon" onClick={() => deleteOrganization(value)}>
            Delete
          </div>
          );
     },
    },
  ];

  const getAllOrganizations = async () => {
    const response = await dispatch(fetchOrganizations({limit: 1000}));
    setOrganizations(Object.values(response.payload.entities.organizations));
  };

  useEffect(() => {
    getAllOrganizations();
  }, []);

  const getSelectedOrg = (org_id) => {
    return organizations.find((ele) => ele.organization_id === org_id);
  };

  const openOrganizationForm = (org_id, opts) => {
    let selected = getSelectedOrg(org_id);
    setOpenOrganizationFormModel({ isOpen: true, isReadOnly: opts?.readOnly });
    setOrganization(selected);
  };

  const closeOrganizationForm = () => {
    setOpenOrganizationFormModel({ isOpen: false });
    setOrganization();
  };

  const addOrganization = async (data) => {
    const headers = {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    if (data?.organization_id) {
      //modify current organization;
      const { organization_id, ...rest } = data;
      rest.company_type_id = +rest.company_type_id;

      const res = await axios.put(
        `${domainUrl}/masterservicenew/master/organization/${organization_id}`,
        { ...rest },
        { headers }
      );

      getAllOrganizations();
      closeOrganizationForm();
      return;
    }

    //create new organization
    const body = {
      ...data,
      company_type_id: +data.company_type_id,
      country_id: +data.country_id,
      city_id: +data.city_id,
    };
    const res = await axios.post(
      `${domainUrl}/masterservicenew/master/organization`,
      body,
      { headers }
    );

    getAllOrganizations();
    closeOrganizationForm();
  };

  const deleteOrganization = (id) => {
    setDeleteOrg(id);
    setShowConfirmationBox(true);
  };

  const declineSubmit = () => {
    setShowConfirmationBox(false);
    setDeleteOrg({});
  }

  const confirmDelete = async () => {
    const res = await axios.delete(
      `${domainUrl}/masterservicenew/master/organization/${deleteOrg}`,
      {
        headers: {
          client_code: "ht_front",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    setShowConfirmationBox(false);
    setDeleteOrg({});
    getAllOrganizations();
  }

  return (
    <>
      <Header />
      <div className="mainContainer">
        <div className="innerContainer">
        <h2 className="title">Organizations</h2>
        <Table
            className="ant-table-root"
            scroll={{ x: 0, y: '40vh' }}
            columns={columns}
            dataSource={organizations}
            pagination={pagination}
            bordered
            rowKey='organization_id'
          />
          <div className="button1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.701"
              height="29.701"
              viewBox="0 0 29.701 29.701"
            >
              <path
                fill="#fff"
                d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                transform="translate(-1 -1)"
              />
            </svg>
            <span
              onClick={() => openOrganizationForm(null, { readOnly: false })}
            >
              Add New
            </span>
          </div>
        </div>
      </div>

      {openOrganizationFormModel?.isOpen && (
        <AddModal
          readOnly={openOrganizationFormModel?.isReadOnly}
          data={organization}
          onClose={closeOrganizationForm}
          onSubmit={addOrganization}
        />
      )}
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={'Are you sure you want to delete this organization?' } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
    </>
  );
};

export default Organization;
