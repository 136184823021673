import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { Table, Rate } from 'antd';
import FavoriteIcon from '@material-ui/icons/Favorite';

import ConfirmationBox from '../ConfirmationBox';

import {
    fetchSuppliers,
    updateSupplier,
    deleteSupplier
} from "../../actions/suppliers";
import { compNames, getFilterValues } from '../../utils';

const useStyles = makeStyles((theme) => ({
    favIcon: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    fav: {
        marginRight: 5,
    }
}));

const SupplierList = forwardRef(({ value, showFeedBack, viewSupplierDetails }, ref) => {

        const suppliers = useSelector(state => Object.values(get(state, `entities.suppliers`) || {}).sort(function(a,b){
            return new Date(b.created_at) - new Date(a.created_at);
          }));

        const classes = useStyles();
        const [openAssetFormModel, setOpenAssetFormModel] = useState({
            isOpen: false,
            isReadOnly: false,
        });
        const [openCompanySelectModel, setOpenCompanySelectModel] = useState({
            isOpen: false,
        });
       // const [suppliers, setSuppliers] = useState([]);
        const [companies, setCompanies] = useState([]);
        const [asset, setAsset] = useState({});

        const [pagination, setPagination] = useState({
            pageSize: 10
            });
        const [showConfirmationBox, setShowConfirmationBox] = useState(false);
        const [supplierId, setSupplierId] = useState();

        const dispatch = useDispatch();

        useEffect(() => {
            if(value === 1)
                getSuppliers();
        }, [value]);

        // const mapCompanyIdNames = (assetData, companyData) => {
        // 	assetData.map((asset) => {
        // 		const assetCompany = companyData.find(
        // 			(company) => company.company_id === asset.company_id
        // 		);
        // 		asset.company_name = assetCompany.company_name;
        // 		asset.organization_name = assetCompany.organization_name;
        // 	});
        // 	setAssets(assetData);
        // };

        const columns = [{
                dataIndex: "supplier_name",
                title: "Supplier Name",
                key: "supplier_name",
                filters: getFilterValues("supplier_name", suppliers),
                onFilter: (value, record) => record.supplier_name.indexOf(value) === 0,
                sorter: {
                    compare: (a, b) => {
                    return compNames(a.supplier_name, b.supplier_name);
                    },
                },
            },
            {
                dataIndex: "country_name",
                title: "Country",
                key: "country_name",
                filters: getFilterValues("country_name", suppliers),
                onFilter: (value, record) => record.country_name.indexOf(value) === 0,
                sorter: {
                    compare: (a, b) => {
                    return compNames(a.country_name, b.country_name);
                    },
                },
            },
            {
                dataIndex: "rating",
                title: "Rating",
                key: "rating",
                sorter: {
                    compare: (a, b) => {
                    return a.rating - b.rating;
                    },
                },
                render: (value, tableMeta, updateValue) => (
                    <Rate style={{fontSize: '14px'}} allowHalf value={value} disabled/>
                ),
            },
            {
                dataIndex: "supplier_id",
                title: "View",
                key: "supplier_id",
                width: 70,
                render: (value, tableMeta, updateValue) => (
                    <div
                    className="viewIcon"
                    onClick={() => openSupplierForm(value, { readOnly: true, allowRating: true })}
                >
                    View
                </div>
                ),
            },
            {
                dataIndex: "supplier_id",
                title: "Modify",
                key: "supplier_id",
                width: 90,
                render: (value, tableMeta, updateValue) => (
                    <div
                    className="btn-primary"
                    onClick={() =>
                        openSupplierForm(value, { readOnly: false, allowRating: true })
                    }
                >
                    Modify
                </div>
                ),
            },
            {
                dataIndex: "supplier_id",
                title: "Delete",
                key: "supplier_id",
                width: 80,
                render: (value, tableMeta, updateValue) => (
                    <div
                    className="deleteIcon"
                    onClick={() => deleteData(value)}
                >
                    Delete
                </div>
                ),
            },
            {
                dataIndex: "supplier_id",
                title: "Favourite",
                key: "supplier_id",
                render: (value, tableMeta, updateValue) => (
                    <div
                    onClick={() => updateFav(value)}
                >
                    <p className={classes.favIcon} > <FavoriteIcon style={{ color: getIsFav(value) === 0 ? '#c0c0c0': '#DC143C' }} className={classes.fav} /> {getIsFav(value) === 0 ? 'Mark as favourite ': 'Unmark as favourite'}</p>
                    
                </div>
                ),
            },
        ];

        const getSuppliers = async () => {
            await dispatch(fetchSuppliers({limit: 1000, order_by: 'created_at', sort_by: 'DESC'}));
            //const supplierData = Object.values(response.payload.entities.suppliers);
            //setSuppliers(supplierData);
        }

        const getIsFav = (supplier_id) => {
            const supplier = getSelectedSupplier(supplier_id);
            return supplier.is_favourite;
        }

        const getSelectedSupplier = (supplier_id) => {
            return suppliers.find(supplier => supplier.supplier_id == supplier_id)
        };

        const updateFav = async (supplier_id) => {
            const supplier = getSelectedSupplier(supplier_id);
            supplier.is_favourite = supplier.is_favourite === 0 ? 1 : 0;
            delete supplier.supplier_id;
            const updateResponse = await dispatch(updateSupplier(supplier_id, supplier));
            getSuppliers();
        }

        const openSupplierForm = (supplier_id, opts) => {
            let selected = getSelectedSupplier(supplier_id);
            viewSupplierDetails(selected, opts);
        };

        const deleteData = (id) => {
            setSupplierId(id);
            setShowConfirmationBox(true);
        };
        
        const declineSubmit = () => {
            setShowConfirmationBox(false);
            setSupplierId('');
        }

        const deleteSupplierData = async () => {
            const response = await dispatch(deleteSupplier(supplierId));
            showFeedBack({
                open: true,
                success: response.payload.result?.success || response.payload.body?.success,
                error: !response.payload.result?.success || !response.payload.body?.success,
                info: false,
                message: response.payload.result?.success ? response.payload.result.message : response.payload.body.error.message,
            })
            getSuppliers();
            setShowConfirmationBox(false);
            setSupplierId('');
        };

        useImperativeHandle(ref, () => ({
        	manageSuppliers() {
		      getSuppliers();
		    },
		    deleteSupplier(data) {
		      deleteSupplierData(data.supplier_id)
		    }

		  }));


	return (
		<>
			<Table
                className="ant-table-root"
                scroll={{ x: 0, y: '40vh' }}
                columns={columns}
                dataSource={suppliers}
                pagination={pagination}
                bordered
                rowKey='supplier_id'
            />
			<div className="button1">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="29.701"
					height="29.701"
					viewBox="0 0 29.701 29.701"
				>
					<path
						fill="#fff"
						d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
						transform="translate(-1 -1)"
					/>
				</svg>
				<span
					onClick={() => {
						viewSupplierDetails({},{ isOpen: true, allowRating: true });
					}}
				>
					{" "}
					Add New
				</span>
			</div>
            <ConfirmationBox showConfirmationBox={showConfirmationBox} message={'Are you sure you want to delete this supplier?'} confirmSubmit={deleteSupplierData} declineSubmit={declineSubmit} />
		</>
	);
});

export default SupplierList;