import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MessageToast from "../../components/MessageToast";
import { useDispatch } from "react-redux";
import { sendEmailCommunication } from "../../actions/communication";
import { message, Spin } from "antd";

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    float: "right",
    cursor: "pointer",
  },
  paper: {
    padding: 15,
    marginBottom: 20,
  },
  gridContainer: {
    margin: 5,
  },
  gridItem: {
    border: "1px solid #c0c0c0",
    "&:nth-child(even)": {
      textAlign: "center",
    },
    "&:nth-child(odd)": {
      borderRight: "1px solid #c0c0c0",
    },
  },
  inputFieldGroup: {
    alignItems: "center",
  },
}));

const EmailFormatter = ({ contentList, isOpen, onClose, tender_id, is_pretender }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [contentToSend, setContentToSend] = useState(contentList);
  const [emails, setEmails] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);
  const [includeAttachments, setIncludeAttachments] = useState(false);

  const [showFeedback, setShowFeedback] = useState({
    open: false,
    success: false,
    error: false,
    info: false,
    message: "",
  });
  const sendEmail = async() => {
    setIsLoading(true);
    if(emails.length === 0 || (emails.length ===1 && emails[0] === '')){
      message('Please enter the email ids');
      return;
    }
    const data = {
      email_to: emails.join(','),
    }
    if(is_pretender){
      data.pretender_id = parseInt(tender_id);
       if(!includeAttachments)
        await dispatch(sendEmailCommunication('PRETENDER_INFO', data));
      else
        await dispatch(sendEmailCommunication('PRETENDER_INFO_DOC', data));
    }
    else{
      data.tender_id = parseInt(tender_id);
      if(!includeAttachments)
        await dispatch(sendEmailCommunication('TENDER_INFO', data));
      else
        await dispatch(sendEmailCommunication('TENDER_INFO_DOC', data));
    }
    setShowFeedback({
      open: true,
      success: false,
      error: false,
      info: false,
      message: "",
    });
    setIsLoading(false);
  };

  const onAddNewEmail = () => {
    const currEmailAccounts = emails;

    setEmails([...currEmailAccounts, ""]);
  };

  const onDeleteEmail = (index) => {
    const filteredEmailAccounts = emails?.filter((_, i) => i !== index);
    setEmails([...filteredEmailAccounts]);
  };

  const onDefaultEmailChange = (index, e) => {
    const _ = emails?.slice() ?? [];
    _[index] = e.target.value;

    setEmails(_);
  };

  const onContentSelect = (e) => {
    setIncludeAttachments(e.target.checked);
    // const _ = contentToSend;
    // _[index].selected = e.target.checked;
    // setContentToSend([]);
    // setInterval(() => setContentToSend(_), 0);
  };

  // const onContentSelect = (index, e) => {
  //   const _ = contentToSend;
  //   _[index].selected = e.target.checked;
  //   setContentToSend([]);
  //   setInterval(() => setContentToSend(_), 0);
  // };

  const closeFeedback = () => {
    setShowFeedback({
      open: false,
      success: false,
      error: false,
      info: false,
      message: "",
    });
    onClose();
  };

  return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        onClose={onClose}
        maxWidth="sm"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
    <Spin spinning={isLoading}>

        <DialogContent dividers={true}>

          {showFeedback.open && (
            <MessageToast
              open={showFeedback.open}
              success={true}
              error={false}
              message={"Email sent successfully"}
              onClose={closeFeedback}
            />
          )}
          <Paper elevation={3} className={classes.paper}>
            <h3>Choose Receiver</h3>
            {emails?.map((el, index) => (
              <Grid
                container
                spacing={2}
                key={index}
                className={classes.inputFieldGroup}
              >
                <Grid item xs={6}>
                  {index === 0 && "Email"}
                </Grid>
                <Grid item>
                  <input
                    className="inputField"
                    type="email"
                    name={"email"}
                    value={el || ""}
                    onChange={(e) => onDefaultEmailChange(index, e)}
                  />
                </Grid>
                <Grid
                  item
                  onClick={() => onDeleteEmail(index)}
                  className="deleteIcon"
                >
                  Delete
                </Grid>
              </Grid>
            ))}
            <div className="inputFieldGroup">
              <div className="button1 button2">
                <span onClick={onAddNewEmail}>Add Email</span>
              </div>
            </div>
          </Paper>
          <Paper elevation={3} className={classes.paper}>
            {/* <h3>Choose Content</h3> */}
             <FormControlLabel
                control={
                  <Checkbox
                    checked={includeAttachments}
                    onChange={(e) => onContentSelect(e)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Include File Attachments"
              />
            {/* <Checkbox
              checked={includeAttachments}
              onChange={(e) => onContentSelect(e)}
              name='Include File Attachments'
            /> */}
            {/* <FormGroup>
              <Grid
                container
                xs={8}
                spacing={2}
                className={classes.gridContainer}
              >
                {contentToSend.map((content, index) => (
                  <>
                    <Grid
                      item
                      xs={6}
                      key={content.value}
                      className={classes.gridItem}
                    >
                      {content.value}
                    </Grid>
                    <Grid item xs={6} key={index} className={classes.gridItem}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={content.selected}
                            onChange={(e) => onContentSelect(index, e)}
                            name={content.value}
                          />
                        }
                        label=""
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </FormGroup> */}
          </Paper>
          <Paper elevation={3} className={classes.paper}>
            <h3>Comments</h3>
            <textarea style={{ width: "100%" }} />
          </Paper>
        </DialogContent>
        <DialogActions>
          <div className="buttonBlock">
            <div
              className="btn cancelBtn"
              style={{ marginRight: 15 }}
              onClick={onClose}
            >
              Cancel
            </div>
            <div className="btn" onClick={sendEmail}>
              Send
            </div>
          </div>
        </DialogActions>
    </Spin>

      </Dialog>
  );
};

EmailFormatter.propTypes = {};

export default EmailFormatter;

