import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { EditOutlined } from "@ant-design/icons";
import { Modal, Divider } from "antd";
import ConfirmationBox from '../ConfirmationBox';
import EmailFormatter from "../EmailFormatter";

import { useDispatch } from "react-redux";
import FileDisplay from '../FileDisplay';

import { fetchPreTender, fetchAssetsPreTender, fetchCompanyPreTender, updateStatusPreTender} from '../../actions/pre-tender';
import { updateTender } from '../../actions/tender';
import { fetchOrganization } from '../../actions/organization';
import ToolTip from "../ToolTip";

const emailSections = [
    {
      value: "Tender Info",
      selected: false,
    },
    {
      value: "Attached File",
      selected: false,
    },
  ];

  const editIconProps = {
    style: {
      borderRadius: "50%",
      color: "white",
      background: "#57b8ff",
      padding: "10px",
    },
  };

const PreTenderDetails = ({ preTenderId, setIsLoading, finishTenderCreation, isSupplierSummary, allowEdit, move, modifyTender, preTenderDetails, showToolTip }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [info, setInfo] = useState({});
  const [sentEmailModel, setSentEmailModel] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [statusData, setStatusData] = useState({});
  const [statusMsg, setStatusMsg] = useState('');
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [reason, setReason] = useState('');
 
  const editIconProps = {
    style: {
      borderRadius: "50%",
      color: "white",
      background: "#57b8ff",
      padding: "10px",
    },
  };
  
  useEffect(() => {
    const getInfo = async () => {
      const preTenderResp = await dispatch(fetchPreTender(preTenderId));
      const preTenderDetails = preTenderResp.payload.entities?.preTender[preTenderId];
      let preTenderAssetDetails = [];
      const preTenderAssetResp =  await dispatch(fetchAssetsPreTender(preTenderId));
      preTenderAssetDetails = Object.values(preTenderAssetResp.payload.entities?.preTenderAsset || []);
      const preTenderCompanyResp = await dispatch(fetchCompanyPreTender(preTenderId));
      const preTenderCompanyDetails = Object.values(preTenderCompanyResp.payload.entities?.preTenderCompany || []);
      let preTenderOrgDetails;
      if(preTenderDetails.organization_id) {
      const preTenderOrgResp = await dispatch(fetchOrganization(preTenderDetails.organization_id));
      preTenderOrgDetails = preTenderOrgResp.payload.entities?.organizations[preTenderDetails.organization_id];
      }
      setInfo({
          ...preTenderDetails,
          organization: preTenderOrgDetails?.organization_name  || '',
          assets: preTenderAssetDetails,
          companies: preTenderCompanyDetails
      })

      setIsLoading(false);
    }
    if(preTenderId) {
      getInfo();
    }
  },[preTenderId]);

  useEffect(() => {
    const getInfo = async () => {
      let preTenderAssetDetails = [];
      if(preTenderDetails?.assetsFormValues)
        preTenderAssetDetails = preTenderDetails?.assetsFormValues 
      const preTenderCompanyDetails = preTenderDetails?.companyNames
      console.log(preTenderCompanyDetails);
      let preTenderOrgDetails;
      if(preTenderDetails.organization_id) {
      const preTenderOrgResp = await dispatch(fetchOrganization(preTenderDetails.organization_id));
      preTenderOrgDetails = preTenderOrgResp.payload.entities?.organizations[preTenderDetails.organization_id];
      }
      setInfo({
          ...preTenderDetails,
          organization: preTenderOrgDetails?.organization_name  || '',
          assets: preTenderAssetDetails,
          companies: preTenderCompanyDetails
      })

      setIsLoading(false);
    }
    if(preTenderDetails) {
      getInfo();
    }
  },[preTenderDetails]);

  const statusPretender = (data,msg) => {
      setStatusData(data);
      setStatusMsg(msg);
      setShowConfirmationBox(true);
  }

  const declineSubmit = () => {
    setShowConfirmationBox(false);
    setStatusData({});
}

const confirmDelete = async () => {
  if(statusData.status !== 'rejected') {
    setIsLoading(true);
    const response = await dispatch(updateStatusPreTender(statusData, preTenderId));
    const status = response.payload?.entities?.preTender[preTenderId] ? true : false;
    setShowConfirmationBox(false);
    
    setIsLoading(false);
    finishTenderCreation(status, status ? `Pre Tender successfully ${statusData.status}` : `Pre Tender not ${statusData.status}`, status ? true : false);
    setStatusMsg('');
    setStatusData({});
    const tenderId = response.payload?.entities?.preTender[preTenderId].tender_id;
    if(tenderId) {
       await dispatch(updateTender({state: '8'}, tenderId));
       setTimeout(() => {
          history.push(`/tender/${tenderId}`); 
       }, 1000);
      
    }
  }
  else {
    setShowConfirmationBox(false);
    setShowRejectModal(true);
  }
};

const rejectionReason = (e) => {
  setReason(e.target.value)
}

const confirmReject = async() => {
  setShowRejectModal(false);
  setIsLoading(true);
    const response = await dispatch(updateStatusPreTender({...statusData, rejection_reason: reason}, preTenderId));
    const status = response.payload?.entities?.preTender[preTenderId] ? true : false;
    
    setIsLoading(false);
    finishTenderCreation(status, status ? `Pre Tender successfully ${statusData.status}` : `Pre Tender not ${statusData.status}`);
    setStatusMsg('');
    setStatusData({});
}

  return (
    <div className="summaryOuter">
      {
        showToolTip && (
          <div className="summaryHeadder">
            <h2>Summary <ToolTip text_id="tender.summary.info" placement="top" /></h2>
          </div>
        )
      }
      <div className="mainContainer">
        {
          allowEdit && 
          <div className="block01">
           <EditOutlined onClick={() => move(-6)} {...editIconProps} />
          </div>
        }
        
        <div className="textBlock">
          <div className="labelBLock">Title</div>
          <div className="contentBLock">{info?.title}</div>
        </div>
        <div className="textBlock">
          <div className="labelBLock">Description</div>
          <div
            style={{ whiteSpace: "pre-wrap" }}
            className="contentBLock"
            dangerouslySetInnerHTML={{ __html: info?.description }}
          ></div>
        </div>
        <div className="textBlock">
          <div className="labelBLock">Tender Type</div>
          <div className="contentBLock">{info?.tender_type === 'r' ? "Repair" : info?.tender_type_other}</div>
        </div>
        <div className="textBlock">
          <div className="labelBLock">Organization</div>
          <div className="contentBLock">{info?.organization}</div>
        </div>
        <div className="textBlock">
          <div className="labelBLock">Company</div>
          <div className="contentBLock">
              <ul>
                  {
                      info?.companies?.map((company,index) => (
                          <li key={index}>{company.company_name}</li>
                      ))
                  }
              </ul>
          </div>
        </div>
      </div>

      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile">Assets</div>
          {
          allowEdit && 
           <EditOutlined onClick={() => move(-3)} {...editIconProps} />
        }
        </div>
        {info?.assets?.map((item, index) => (
          <React.Fragment key={index}>
            <div
              style={{ margin: "10px 0", flexDirection: "row", justifyContent: 'space-between' }}
              className=" textBlock list01"
            >
              <div className="labelBLock">{item.asset_name || item.line_text}</div>
              <div className="labelBLock" style={{textAlign: 'right'}}>Cost: {item.amount || 0}</div>
            
            </div>
           
                    <Divider />
          </React.Fragment>
        ))}
        <div className="labelBLock " style={{textAlign: 'right',fontWeight:'bold', fontSize: 17}}>Total: {info.subtotal || 0}</div>
      </div>

      <div className="mainContainer">
      <div className="titleSummary">
          <div className="titile">Files</div>
          {
          allowEdit && 
           <EditOutlined onClick={() => move(-2)} {...editIconProps} />
        }
        </div>
        <FileDisplay entityType={'P'} entityId={preTenderId} hideTitle={true}/>
      </div>
      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile">Additional Information/Comment</div>
          {
          allowEdit && 
           <EditOutlined onClick={() => move(-1)} {...editIconProps} />
        }
        </div>
        <p
          style={{ whiteSpace: "pre-wrap" }}
          className="contentBLock"
          dangerouslySetInnerHTML={{ __html: info?.additional_info }}
        ></p>
      </div>
            {
              !isSupplierSummary && !modifyTender &&
        <>    
      <div
        className="d-flex j-space"
        style={{ marginBottom: 20, flexWrap: "wrap" }}
      >
        <div
          className="btn"
           onClick={() => statusPretender({status: 'accepted'}, 'Please confirm to accept the tender.')}
        >
          Accept
        </div>
        <div
          className="btn"
          style={{ background: "#fabf01" }}  onClick={() => statusPretender({status: 'converted'}, 'Please confirm to convert this Pre-Tender to Tender.')}
        >
          Convert to Tender
        </div>
        <div  className="btn" onClick={() => setSentEmailModel(true)}>
          Send email with all Pre Tender info
        </div>
        <div
          className="btn"
          style={{ background: "red" }} onClick={() => statusPretender({status: 'rejected'}, 'Please confirm to reject the tender.')}
        >
          Reject
        </div>
        
      </div>

      {sentEmailModel && (
        <EmailFormatter
          isOpen={sentEmailModel}
          onClose={() => setSentEmailModel(false)}
          contentList={emailSections}
          tender_id={preTenderId}
          is_pretender={true}
        />
      )}
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={statusMsg } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
      <Modal visible={showRejectModal} footer={null}>
        <div className="hedding">
          <h2>Reason for rejecting tender?</h2>
        </div>

        <div className="d-flex f-col">
          <textarea className="comments" onChange={rejectionReason}></textarea>

          <div className="btn" onClick={confirmReject}>
                Continue
          </div>
        </div>
      </Modal>
    </>
  }
      </div>
  );
};

export default PreTenderDetails;
