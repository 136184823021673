import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

const LoggedInState = ({role, component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem("TOKEN") ? true : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
            <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
export default LoggedInState;
