import { schema } from "normalizr";
import { CUSTOM_AUTHORIZED_REQUEST } from '../middleware/apiAuthorization';
import {
	handleResponse,
	handleTenderResponse,
	handleError,
	handleBlobResponse,
} from "../utils";
import apiUrls  from "../utils/apiUrls";

export const FETCH_TENDERTYPE_REQUEST = "FETCH_TENDERTYPE_REQUEST";
export const FETCH_TENDERTYPE_SUCCESS = "FETCH_TENDERTYPE_SUCCESS";
export const FETCH_TENDERTYPE_FAILURE = "FETCH_TENDERTYPE_FAILURE";

export const FETCH_TENDER_REQUEST = "FETCH_TENDER_REQUEST";
export const FETCH_TENDER_SUCCESS = "FETCH_TENDER_SUCCESS";
export const FETCH_TENDER_FAILURE = "FETCH_TENDER_FAILURE";

export const FETCH_TENDERS_REQUEST = "FETCH_TENDERS_REQUEST";
export const FETCH_TENDERS_SUCCESS = "FETCH_TENDERS_SUCCESS";
export const FETCH_TENDERS_FAILURE = "FETCH_TENDERS_FAILURE";

export const CREATE_TENDER_REQUEST = "CREATE_TENDER_REQUEST";
export const CREATE_TENDER_SUCCESS = "CREATE_TENDER_SUCCESS";
export const CREATE_TENDER_FAILURE = "CREATE_TENDER_FAILURE";

export const UPDATE_TENDER_REQUEST = "UPDATE_TENDER_REQUEST";
export const UPDATE_TENDER_SUCCESS = "UPDATE_TENDER_SUCCESS";
export const UPDATE_TENDER_FAILURE = "UPDATE_TENDER_FAILURE";

export const DELETE_TENDER_REQUEST = "DELETE_TENDER_REQUEST";
export const DELETE_TENDER_SUCCESS = "DELETE_TENDER_SUCCESS";
export const DELETE_TENDER_FAILURE = "DELETE_TENDER_FAILURE";

export const FETCH_TENDER_PDF_REQUEST = "FETCH_TENDER_PDF_REQUEST";
export const FETCH_TENDER_PDF_SUCCESS = "FETCH_TENDER_PDF_SUCCESS";
export const FETCH_TENDER_PDF_FAILURE = "FETCH_TENDER_PDF_FAILURE";

export const tenderTypeSchema = new schema.Entity("tenderTypes", [], {
	idAttribute: "tender_type_value",
});

export const tenderSchema = new schema.Entity("tenders", [], {
	idAttribute: "tender_id",
});

/**
 * Returns a redux api middleware action that makes a request for all the tender types.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchTenderTypes = () => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderTypeRequest,
			method: "GET",
			types: [
				{ type: FETCH_TENDERTYPE_REQUEST },
				{
					type: FETCH_TENDERTYPE_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([tenderTypeSchema], action, state, res),
				},
				{ type: FETCH_TENDERTYPE_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to create a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchTenderById = (tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderById(tender_id),
			method: "GET",
			types: [
				{ type: FETCH_TENDER_REQUEST },
				{
					type: FETCH_TENDER_SUCCESS,
					payload: (action, state, res) =>
					handleTenderResponse(tenderSchema, action, state, res),
				},
				{ type: FETCH_TENDER_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to create a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchTenderByParams = (params) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderRequestWithParams(params),
			method: "GET",
			types: [
				{ type: FETCH_TENDERS_REQUEST },
				{
					type: FETCH_TENDERS_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([tenderSchema], action, state, res),
				},
				{ type: FETCH_TENDERS_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to create a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createTender = (data) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderRequest,
			method: "POST",
			body: JSON.stringify(data),
			types: [
				{ type: CREATE_TENDER_REQUEST },
				{
					type: CREATE_TENDER_SUCCESS,
					payload: (action, state, res) =>
						handleResponse(tenderSchema, action, state, res),
				},
				{ type: CREATE_TENDER_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to update a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const updateTender = (data, tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderById(tender_id),
			method: "PUT",
			body: JSON.stringify(data),
			types: [
				{ type: UPDATE_TENDER_REQUEST },
				{
					type: UPDATE_TENDER_SUCCESS,
					payload: (action, state, res) =>
						handleResponse(tenderSchema, action, state, res),
				},
				{ type: UPDATE_TENDER_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to delete a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const deleteTender = (tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderById(tender_id),
			method: "DELETE",
			types: [
				{ type: DELETE_TENDER_REQUEST },
				{
					type: DELETE_TENDER_SUCCESS,
					payload: (action, state, res) =>
						handleResponse(tenderSchema, action, state, res),
				},
				{ type: DELETE_TENDER_FAILURE, payload: handleError },
			],
		}
	}
}


/**
 * Returns a redux api middleware action that makes a request to generate.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const generateTenderPdf = (tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderPdfRequest(tender_id),
			method: "GET",
			types: [
				{ type: FETCH_TENDER_PDF_REQUEST },
				{
					type: FETCH_TENDER_PDF_SUCCESS,
					payload: (action, state, res) => handleBlobResponse(action, state, res),
				},
				{ type: FETCH_TENDER_PDF_FAILURE, payload: handleError },
			],
		}
	}
}