module.exports = {
    'name': 'Otsikko',
    'closing_date': 'Sulkeutumispäivä',
    'bids': 'Tarjoukset',
    'status': 'Tila',
    'created_by': 'Luonut',
    'awarded_to': 'Myönnetty',
    'tenders': 'Kilpailutukset',
    'add_new_tender': 'Luo Uusi Kilpailutus',
    'view_all_tender': 'Katso Kaikki Kilpailutukset',
    'published_tenders': 'Julkaistut Kilpailutukset',
    'published': 'Julkaistu',
    'draft_tenders': 'Kilpailutusluonnokset',
    'draft': 'Luonnos',
    'pre_tenders': 'Alustavat Tarjouspyynnöt',
    'awarded_tenders': 'Päättyneet Kilpailutukset',
    'show_more': 'Näytä Enemmän',
    'show_less': 'Näytä Vähemmän',
    'new': 'Uusi',
    'tenders_submitted_bids': 'Kilpailutukset - Jätetyt Tarjoukset',
    'new_tenders': 'Uudet Kilpailutukset',
    'add_new_pre_tender': 'Luo Uusi Alustava Tarjouspyyntö',
    'submitted': 'Jätetty',
    'awarded': 'Hyväksytty',
    'supplier_registration_form': 'Toimittajan ilmoittautumislomake',
    'company_name': 'Yrityksen nimi',
    'business_id': 'Y-tunnus',
    'main_contact_person': 'Yhteyshenkilö',
    'email': 'Sähköposti',
    'mobile_number': 'Puhelinnumero',
    'alternate_mobile_number': 'Vaihtoehtoinen puhelinnumero',
    'full_address': 'Koko osoite',
    'pin_code': 'Postinumero',
    'cities_operating': 'Kaupungit, joissa toimitte',
    'choose_industry_type': 'Valitse palvelutyyppi',
    'error.name': 'Ole hyvä ja kirjoita nimesi',
    'error.business_id': 'Ole hyvä ja kirjoita Y-tunnuksesi',
    'error.email': 'Ole hyvä ja kirjoita sähköpostisi',
    'error.invalid_email': 'Syöttämäsi sähköposti ei ole kelvollinen.',
    'error.mobile_number': 'Ole hyvä ja kirjoita puhelinnumerosi',
    'error.invalid_mobile_number': 'Syöttämäsi puhelinnumero ei ole kelvollinen',
    'error.address': 'Ole hyvä ja kirjoita osoitteesi',
    'error.pin_code': 'Ole hyvä ja kirjoita postinumerosi',
    'error.invalid_pin_code': 'Syöttämäsi postinumero ei ole kelvollinen',
    'error.city': 'Ole hyvä ja kirjoita kaupunki',
    'error.service_type': 'Ole hyvä ja valitse palvelutyyppisi',
    'error.supplier_name': 'Ole hyvä ja kirjoita toimittajanimi',
    'error.msg': 'Ole hyvä ja kirjoita kysymyksesi',
    'error.organization': 'Ole hyvä ja kirjoita organisaatio',
    'error.company_name': 'Ole hyvä ja kirjoita yrityksen nimi',
    'city': 'Kaupunki',
    'submit': 'Lähetä',
    'submit_request': 'Kiitos pyynnön lähettämisestä!',
    'response_back': 'Arvioimme pyyntönne ja olemme Teihin pian yhteydessä',
    'ok': 'OK',
    'invalid_code': 'Koodi ei ole kelvollinen',
    'organization': 'Organisaatio',
    'company': 'Yritys',
    'last_updated': 'Viimeksi päivitetty',
    'awarded_date': 'Myöntämispäivä',
    'completion_date': 'Työn valmistumispäivä',
    'date_submitted': 'Lähetyspäivä',
    'published_date': 'Julkaisupäivä',
    'delete_tender_msg': 'Haluatko varmasti poistaa tämän tarjouspyynnön?',
    'supplier_details': 'Toimittajatiedot',
    'label_name': 'Nimi',
    'address': 'Osoite',
    'country': 'Maa',
    'industry_type': 'Palvelutyyppi',
    'supplier_rating': 'Toimittajan luokitus',
    'mark_favourite': 'Merkitse suosikiksi',
    'deactivate': 'Deaktivoi',
    'save': 'Tallenna',
    'cancel': 'Peruuta',
    'send_question': 'Lähetä kysymys ylläpitäjälle',
    'ask_question': 'Esitä kysymyksiä',
    'send': 'Lähetä',
    'message': 'Viesti',
    'edit_company_details': 'Muokkaa yrityksen tietoja',
    'add_company_details': 'Anna yritystiedot',
    'company_details': 'Yritystiedot',
    'select_city': 'Valitse kaupunki',
    'select_organization': 'Valitse organisaatio',
    'default_emails': 'Oletussähköpostit',
    'default_email': 'Oletussähköposti',  
    'tender.choose_tender_type.info': 'Valitse tässä mitä haluat kilpailuttaa. Valitsemalla ”Korjaus”-vaihtoehdon, voit kilpailuttaa eri korjaustöitä, esimerkiksi varaosien korjausta. Valitsemalla “Muut”-vaihtoehdon, voit kilpailuttaa mm. siivouksia, sopimuksia, tarkastuksia ja muita vastaavia.',
    'tender.choose_company.info': 'Valitse tässä kenelle kilpailutetaan. Käytä hakutoimintoa apuna. Tällä voit kilpailuttaa myös omia hankintojasi.',
    'tender.tender_details.info': 'Lisää tähän kilpailutuksen otsikko/nimi. Voit antaa myös lisätietoja kyseisestä kilpailutuksesta halutessasi.',
    'tender.choose_suppliers.info': 'Valitse toimittajat, joille haluat lähettää tämän tarjouspyynnön. Jos haluamaasi toimittajaa ei löydy listalta, niin voit lähettää kutsun toimittajillesi lisäämällä sähköpostiosoitteen yksi kerrallaan sivun alalaidassa olevaan tekstikenttään. Toimittajien kirjauduttua, löydät heidät jatkossa toimittajalistalta.',
    'tender.asset_details.info': 'Rakenna tarjous kohteittain. Esim. pääkohde voi olla asunto 123 ja alakohde voi olla tehtävä tai remontin kohde, esim. vessanpöntön vaihto. Tämän koko vaiheen voi hypätä yli, jos seuraavassa vaiheessa liitettävä tarjouspyyntö sisältää kaikki tarvittavat tiedot.',
    'tender.file.info': 'Lisää mahdolliset liitteet, esimerkiksi tarjouspyyntö.',
    'tender.closing_date.info': 'Valitse mihin mennessä haluat vastaanottaa tarjoukset, sekä kuinka monta muistutusta haluat lähettää sulkeutuvasta kilpailutuksesta niille toimittajille, jotka eivät ole vielä vastanneet kilpailutukseen omalla tarjouksellaan.',
    'tender.additional_info.info': 'Lisää tähän mahdollisia kilpailutukseen tai sen kohteeseen liittyviä lisätietoja. Esimerkiksi, voit mainita mistä voi noutaa avaimet tai mikä on ovikoodi.',
    'tender.summary.info': 'Tarkista yhteenvedosta, että kaikki tiedot ja liitteet ovat oikein. Joka kohtaan pääsee siirtymään ja tietoja on mahdollista muokata tarvittaessa.'
};
