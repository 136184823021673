import React, {useState} from 'react';
import { Spin } from 'antd';

import Header from "../../components/Header";
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PreTenderDetails from '../../components/PreTenderDetail';
import ConfirmationBox from '../../components/ConfirmationBox';
import MessageToast from "../../components/MessageToast";
import  {deletePreTender} from '../../actions/pre-tender';

const PreTenderDetail = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { preTenderId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [confirmMsg, setConfirmMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isModify, setIsModify] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [showFeedback, setShowFeedback] = useState({
      open: false,
      success: false,
      error: false,
      info: false,
      message: "",
    });

    const declineSubmit = () => {
      setShowConfirmationModal(false);
      setIsModify(false);
      setIsDelete(false);
      setConfirmMsg('');
  }
  
  const confirmSubmit = async () => {
    setShowConfirmationModal(false);
    setIsModify(false);
    setIsDelete(false);
    setConfirmMsg('');

    if(isModify) {
      history.push(`/pre-tender/${preTenderId}`)
    }
    else if(isDelete) {
      setIsLoading(true);
      const response = await dispatch(deletePreTender(preTenderId));
      console.log(response);
      const status = response?.error || false;
      setIsLoading(false);
      setShowFeedback({
        open: true,
        success: !status,
        error: status,
        info: false,
        message: !status ? "Pre Tender successfully deleted" : 'Could not delete pre tender',
      });
      if(!status) {
        setTimeout(() => {
          history.push(`/`)
        },2000);
      }
    }
  };

const closeFeedback = () => {
    setShowFeedback({
      open: false,
      success: false,
      error: false,
      info: false,
      message: "",
    });
};

const deleteTender = () => {
  setConfirmMsg('Are you sure you want to delete this pre tender?');
  setIsDelete(true);
  setShowConfirmationModal(true);
}

const modifyTender = () => {
  setConfirmMsg('Are you sure you want to modify this pre tender?');
  setIsModify(true);
  setShowConfirmationModal(true);
}

	return (
    <>
      <Header />
      {showFeedback.open && (
        <MessageToast
          open={showFeedback.open}
          success={showFeedback.success}
          error={showFeedback.error}
          message={showFeedback.message}
          onClose={closeFeedback}
        />
      )}
      <div className="mainContainer">
      	<div className="innerContainer">
          <Spin spinning={isLoading}>
          <h2 className="title">Pre Tender Details</h2>
              {
               <PreTenderDetails preTenderId={preTenderId} setIsLoading={setIsLoading} modifyTender={true}/>
              }
              <div className="buttonBlock" style={{justifyContent: 'center'}}>
                <div onClick={deleteTender} className="btn">Delete Pre Tender</div>
                {/* <div onClick={modifyTender} className="btn">
                  Modify Pre Tender
                </div> */}
              </div>
           </Spin>   
        </div>
      </div>
     <ConfirmationBox showConfirmationBox={showConfirmationModal} message={confirmMsg } confirmSubmit={confirmSubmit} declineSubmit={declineSubmit} />
    </>
  );
};
 export default PreTenderDetail;