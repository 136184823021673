import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import Setting from "../Setting";
import SettingHeadingLabel from "../Common/SettingHeadingLabel";

const EmailSetting = (props) => {
  return (
    <>
      <Header />
      <div className="settingOuter">
        <Setting page="EmailSetting" />
        <div className="tabContainer">
          <h2>Email Setting</h2>
          <div className="tabContentBlock">
            <div className="contentBlock">
              <div className="formSettings">
                <div className="form-group">
                  <label className="form-label">Server</label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label">User</label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label">Password</label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label">SSL</label>
                  <input className="form-control" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EmailSetting.propTypes = {};

export default EmailSetting;
