import { schema } from "normalizr";
import { CUSTOM_AUTHORIZED_REQUEST } from '../middleware/apiAuthorization';
import {
	handleReducerResponse,
    handleResponse,
	handleError,
} from "../utils";
import apiUrls  from "../utils/apiUrls";

export const POST_MESSAGES_REQUEST = "POST_MESSAGES_REQUEST";
export const POST_MESSAGES_SUCCESS = "POST_MESSAGES_SUCCESS";
export const POST_MESSAGES_FAILURE = "POST_MESSAGES_FAILURE";

export const UPDATE_MESSAGES_REQUEST = "UPDATE_MESSAGES_REQUEST";
export const UPDATE_MESSAGES_SUCCESS = "UPDATE_MESSAGES_SUCCESS";
export const UPDATE_MESSAGES_FAILURE = "UPDATE_MESSAGES_FAILURE";

export const FETCH_MESSAGES_INBOX_REQUEST = "FETCH_MESSAGES_INBOX_REQUEST";
export const FETCH_MESSAGES_INBOX_SUCCESS = "FETCH_MESSAGES_INBOX_SUCCESS";
export const FETCH_MESSAGES_INBOX_FAILURE = "FETCH_MESSAGES_INBOX_FAILURE";

export const FETCH_MESSAGES_SENDBOX_REQUEST = "FETCH_MESSAGES_SENDBOX_REQUEST";
export const FETCH_MESSAGES_SENDBOX_SUCCESS = "FETCH_MESSAGES_SENDBOX_SUCCESS";
export const FETCH_MESSAGES_SENDBOX_FAILURE = "FETCH_MESSAGES_SENDBOX_FAILURE";

export const messagesInboxSchema = new schema.Entity("inbox", [], {
  idAttribute: "message_id",
});

export const messagesSendboxSchema = new schema.Entity("sendbox", [], {
  idAttribute: "message_id",
});

/**
 * Returns a redux api middleware action that makes a request to send messages to tender creator.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createMessageCommunication = (data) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.messageRequest,
			method: "POST",
            body: JSON.stringify(data),
			types: [
				{ type: POST_MESSAGES_REQUEST },
				{
					type: POST_MESSAGES_SUCCESS,
					payload: (action, state, res) =>
						handleReducerResponse(action, state, res),
				},
				{ type: POST_MESSAGES_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to update message meta to tender creator.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const updateMessageCommunication = (message_id, data) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.messageRequestById(message_id),
			method: "PATCH",
            body: JSON.stringify(data),
			types: [
				{ type: UPDATE_MESSAGES_REQUEST },
				{
					type: UPDATE_MESSAGES_SUCCESS,
					payload: (action, state, res) =>
						handleReducerResponse(action, state, res),
				},
				{ type: UPDATE_MESSAGES_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to get message list.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchMessageCommunicationInbox = (params) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.messageListRequestInbox(params),
			method: "GET",
			types: [
				{ type: FETCH_MESSAGES_INBOX_REQUEST },
				{
					type: FETCH_MESSAGES_INBOX_SUCCESS,
					payload: (action, state, res) =>
						handleReducerResponse(action, state, res),
				},
				{ type: FETCH_MESSAGES_INBOX_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to get message list Sendbox.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchMessageCommunicationSendbox = (params) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.messageListRequestSendbox(params),
			method: "GET",
			types: [
				{ type: FETCH_MESSAGES_SENDBOX_REQUEST },
				{
					type: FETCH_MESSAGES_SENDBOX_SUCCESS,
					payload: (action, state, res) =>
						handleReducerResponse(action, state, res),
				},
				{ type: FETCH_MESSAGES_SENDBOX_FAILURE, payload: handleError },
			],
		}
	}
}
