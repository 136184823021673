import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Moment from 'react-moment';
import {Spin, Card, Form, Input, Button, Radio, Row, Col, Divider, DatePicker, Space, Select } from 'antd';

import InspectionCategory from './InspectionCategory';
import FileUpload from '../FileUpload';
import FileDisplay from '../FileDisplay';

import { fetchMasterInspectionData, updateInspectionReports, createInspectionReportsLine } from "../../actions/inspection-report";
import { domainUrl } from "../../helper";
import { inspectionReportSummary } from "../../utils/constants";


const tailLayout = {
	wrapperCol: { offset: 10, span: 24 },
};
const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

const { Option } = Select;
const InspectionReport = ({ reportDetails, inspectionReportId, finishReportCreation, isReadOnly }) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [inspectionMaster, setInspectionMaster] = useState([]);
	const [fileList, setFileList] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		const getMasterReport = async() => {
		  const inspection = await dispatch(fetchMasterInspectionData({limit: 1000}));
		  setInspectionMaster( Object.values(inspection.payload.entities.masterinspections));
		}
		if(!isReadOnly)
			getMasterReport();
		}, [dispatch]);


	const onFinish = async (values) => {
		console.log(values);

		setIsLoading(true);
		let reportData= {
			inspection_name: values.inspection_name,
			inspected_by: values.inspected_by,
			inspection_date: moment(values.inspection_date).format('YYYY-MM-DD'),
			answer_one: values.answer_one,
			answer_two: values.answer_two,
			total_year_one: values.total_year_one,
			total_year_two: values.total_year_two,
			total_year_three: values.total_year_three,
			total_year_four: values.total_year_four,
			summary_answer_one: values.summary_answer_one,
			summary_answer_two: values.summary_answer_two,
			summary_answer_three: values.summary_answer_three,
			summary_answer_four: values.summary_answer_four,
		}
		const reportResponse = await dispatch(updateInspectionReports(reportData, inspectionReportId));
		let reportLines = [];
		values.lineItems.map((item,index) => {
			let obj = {};
			if(item) {
				obj.inspection_item_id = parseInt(Object.keys(values.lineItems)[index]);
				if(item.inspection_amc_cost) {
					obj.inspection_amc_id = item.inspection_amc_cost[2];
					obj.inspection_item_value = parseInt(item.inspection_item_value) || item.inspection_amc_cost[1];
					obj.inspection_remarks = item.inspection_remarks || '';
					reportLines.push(obj);
				}
			}
		});
		for(let i=0; i<reportLines.length; i++) {
			const reportLinesResponse = await dispatch(createInspectionReportsLine(reportLines[i], inspectionReportId));
		}

		setIsLoading(false);
		finishReportCreation(reportResponse.payload?.result[0] === inspectionReportId || false);

	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const onChange = (date, dateString) => {
		console.log(date, dateString);
	}

	const total_cost = [
		{ 'total_year_one': 0 },
		{ 'total_year_two': 0 },
		{ 'total_year_three': 0 },
		{ 'total_year_four': 0 }
	]

	const calculateCost = (arrayValue, lineItemId, updateItemValue) => {
		let index = arrayValue[0] - 1;
		if(updateItemValue) {
			const fields = form.getFieldsValue();
			fields.lineItems[lineItemId].inspection_item_value = parseInt(arrayValue[1]);
			form.setFieldsValue(fields);
		}
		if(index < 4) {
			let key = Object.keys(total_cost[index]);
			let cost_key = form.getFieldValue(['lineItems',lineItemId,'inspection_item_value']);
			
			let value = parseInt(form.getFieldValue(key))+parseInt(cost_key);
			form.setFieldsValue({ [key]: value });
		}


	}

	const setInitValues = () => {
		total_cost.map(cost => {
				let key = Object.keys(cost)[0];
				form.setFieldsValue({ [key]: 0 });
					
			})
	}

	const onFieldsChange = (changedFields, allFields) => {
		console.log(changedFields[0]);
		let fieldEdited = changedFields[0];
		if(changedFields[0].name?.length>1 && ((changedFields[0].name[2] === 'inspection_amc_cost'  && changedFields[0].value?.length === 3) || changedFields[0].name[2] === 'inspection_item_value' )) {
			setInitValues();
			allFields.map(changedFields => {
				console.log(changedFields.name?.length>1 && changedFields.name[2] === 'inspection_amc_cost' && changedFields.value?.length === 3);
				if(changedFields.name?.length>1 && changedFields.name[2] === 'inspection_amc_cost' && changedFields.value?.length === 3) {
					calculateCost(changedFields.value, changedFields.name[1], changedFields.name[1] === fieldEdited.name[1] && fieldEdited.name[2] === 'inspection_amc_cost' ? true : false);
				}
			})
		}
		
	}

	return (
		<>
		<Spin spinning={isLoading}>
			<Card title="" className="card-layout inpection-report-card">
				<Form
					form={form}
					name="basic"
					labelAlign='left'
					initialValues={reportDetails}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					onFieldsChange={onFieldsChange}
				>
					<Row>
						<Col span={16}>
							<Form.Item
								label="Inspection Report Name"
								name="inspection_name"
								rules={[
									{
										required: true,
										message: 'Please enter inspection report name.',
									},
								]}
							>
								<Input placeholder="Inspection Report Name" disabled={isReadOnly} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={30}>
						<Col md={24} lg={6} xl={11}>
							<h4>Property</h4>
							<Form.Item
								label="Property To Be Inspected"
								name="address"
								labelCol={{lg:{span: 11, offset: 0}, md:{span: 8, offset: 0}}}
							>
								<Input.TextArea disabled/>
							</Form.Item>
						</Col>
						<Col md={24} lg={6} xl={7}>
							<h4>Customer</h4>
							<Form.Item
								label="Buyer"
								name="company_name"
								labelCol={{lg:{span: 8, offset: 0}, md:{span: 8, offset: 0}}}
							>
								<Input disabled/>
							</Form.Item>
						</Col>
						<Col md={24} lg={6} xl={6}>
							<h4>Evaluator:</h4>
							<Form.Item
								label="Creator"
								name="inspected_by"
								labelCol={{lg:{span: 8, offset: 0}, md:{span: 8, offset: 0}}}
							>
								<Input disabled={isReadOnly} />
							</Form.Item>
							{
								!isReadOnly ? (
									<Form.Item
								label="Date"
								name= "inspection_date"
								labelCol={{lg:{span: 8, offset: 0}, md:{span: 8, offset: 0}}}
							>
								
									<DatePicker onChange={onChange} />
								
								</Form.Item>
								) : (
									<Form.Item
								label="Date"
								labelCol={{lg:{span: 8, offset: 0}, md:{span: 8, offset: 0}}}
							>
								<Input disabled={isReadOnly} value={reportDetails.inspection_date && moment(reportDetails.inspection_date).format("YYYY-MM-DD")}/>
								
							</Form.Item>
								)
							}
							

							
						</Col>
					</Row>
					<Divider />
					<Row gutter={30}>
						<Col span={24}>
							<h3>{reportDetails.asset_name}</h3>
						</Col>
						{
							reportDetails.asset_properties.map((prop, index) => (
								<Col md={24} lg={12} xl={12} key={index}>
									<Form.Item
										label={prop.property_name}
										labelCol={{span: 9, offset: 0}}
									>
										<Input value={prop.property_value} disabled/>
									</Form.Item>
								</Col>
							))
						}

					</Row>
					<Divider />
					<div className="report_corrections">

						<h2>FUTURE CORRECTIONS</h2>
						<p>Fitness rating:</p>
						<Row>
							<Col span={1}>
								1
							</Col>
							<Col>
								- Repaired within 0-1 years
							</Col>
						</Row>
						<Row>
							<Col span={1}>
								2
							</Col>
							<Col>
								- Repaired within 1-2 years
							</Col>
						</Row>
						<Row>
							<Col span={1}>
								3
							</Col>
							<Col>
								- Repaired within 2-3 years
							</Col>
						</Row>
						<Row>
							<Col span={1}>
								4
							</Col>
							<Col>
								- Repaired within 3-5 years
							</Col>
						</Row>
						<Row>
							<Col span={1}>
								OK
							</Col>
							<Col>
								- All okay, no cost in 5 years
							</Col>
						</Row>
					</div>
					{
						!isReadOnly && inspectionMaster.map((category,index) => (
							<>
								<InspectionCategory categoryData={category} isReadOnly={isReadOnly} indexKey={index}/>
								<Divider />
							</>
						))
					}

{
						isReadOnly && reportDetails?.inspection_lines.map((category,index) => (
							<>
								<InspectionCategory categoryData={category} isReadOnly={isReadOnly} indexKey={index}/>
								<Divider />
							</>
						))
					}

					<Row>
					<Col span={24}>
					<h3>FAULT FREQUENCY ACCORDING TO SERVICE JOURNAL</h3>
					</Col>
					<Form.Item label=""
								name="answer_one">
							<Radio.Group name='ansGroup1' disabled={isReadOnly} >
							<Space direction="vertical">
								<Radio value="1">Commendable, 0-1 bug last year</Radio>
								<Radio value="2">Good, 2-3 bugs in the last year</Radio>
								<Radio value="3">Satisfactory, 4-5 bugs in the last year</Radio>
								<Radio value="4">Bad. more than 5 faults in the last year</Radio>
								</Space>
						  </Radio.Group>
						</Form.Item>
					</Row>

					<Row>
						<Col span={24}>
					<h3>SPARE PARTS AVAILABILITY</h3>
					</Col>
					<Form.Item label=""
								name="answer_two">
							<Radio.Group name='ansGroup2' disabled={isReadOnly} >
							<Space direction="vertical">
								<Radio value="1">Spare parts are well available.</Radio>
								<Radio value="2">The availability of spare parts may decline in 5 years.	</Radio>
								<Radio value="3">All parts are no longer available as new, some possible repairs are based on demolition parts.</Radio>
								<Radio value="4">Hardly any new spare parts are available.</Radio>
								</Space>
						  </Radio.Group>
						</Form.Item>
					</Row>
					<Divider />
					<div>

						<h2>FUTURE COSTS</h2>
						<p>Fitness rating:</p>
					<Row>
				
					<Form.Item label="Repaired within 0-1 years"
								name="total_year_one">
									
									<Input disabled/>
									</Form.Item>
									</Row>

									<Row>
				
					<Form.Item label="Repaired within 1-2 years"
								name="total_year_two">
									
									<Input disabled/>
									</Form.Item>
									</Row>
									<Row>
				
					<Form.Item label="Repaired within 2-3 years"
								name="total_year_three">
									
									<Input disabled/>
									</Form.Item>
									</Row>
									<Row>
				
					<Form.Item label="Repaired within 3-5 years"
								name="total_year_four">
									
									<Input disabled/>
									</Form.Item>
									</Row>
									
									</div>
									<Divider />
									<div>

						<h2>SUMMARY</h2>
					<Row>
						{
							inspectionReportSummary.map((summary,index) => (
								<Col span={24} key={index}>
								<Form.Item
									label={summary.question}
									name={summary.key}
									labelCol={{lg:{span: 9, offset: 0}, md:{span: 24, offset: 0}}}
									wrapperCol={{span: 15, offset: 0}}
								>
									<Select
									placeholder="-- Select --"
									optionLabelProp="label"
									disabled={isReadOnly} 
									>
										{
											summary.answers.map((ans,index) => (
												<Option key={index} value={ans}>{ans}</Option>
											))
										}
									</Select>
								</Form.Item>
								</Col>
							))
						}
					</Row>
					</div>
					<Divider />
					{
						 !isReadOnly ? ( 
						 <>
						 	<FileUpload
                    fileList={fileList}
                    filePostURL={FILE_POST_URL}
                    showTitle={false}
                    entityType={"IR"}
                    entityID={inspectionReportId}
                    onFilesStatusChange={(e) => setFileList(e)}
                  />
						<Form.Item {...tailLayout}>
						
						<Button className="ant-custom-btn" type="primary" htmlType="submit">
							Submit
						</Button>
						<Button className="ant-custom-btn" htmlType="button" onClick={() => history.goBack()}>
							Cancel
						</Button>
						
						</Form.Item>
						</>) : (<FileDisplay entityType={'IR'} entityId={inspectionReportId}/>)
					}
					
				</Form>

			</Card>
			</Spin>
		</>
	);
};

InspectionReport.propTypes = {
	reportDetails: PropTypes.shape({}),
};

export default InspectionReport;