import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import Tenders from "../SupplierDashboard/Tenders/Tenders";
import "../../components/SupplierDashboard/SupplierDashboard.css";
import profilePic from "../../assets/user-img2.png";
import profilePic2 from "../../assets/user-img3.png";
import profilePic3 from "../../assets/user-img4.png";
import TenderCard from "../__TenderCard";
import { useHistory } from "react-router";
import MessagesCard from "../MessagesCard";

const Dashboard = (props) => {

  const history = useHistory();
  
  return (
    <>
      <Header />
      <div className="container">
        <div className="leftSidePanel">
          <TenderCard status="awarded" />
          <TenderCard status="current" />
          <TenderCard status="draft" />
          <TenderCard status="pre">
          
          </TenderCard>
        </div>
        <div className="rightSidePanel">
          <MessagesCard />
          <div className="card02 mt-2">
            <div className="searchForm">
              <div class="dashboardTitle">
                <div>Search Tenders</div>
              </div>

              <div className="formGroup">
                <label>Title</label>
                <div className="costumSelect">
                  <select name="country" id="slct" className="inputFiled">
                    <option value="">Choose an option</option>
                    <option value="1">Country 1</option>
                    <option value="2">Country 2</option>
                    <option value="3">Country 3</option>
                  </select>
                </div>
                {/* <div className="errorMessage">{errorMessage.country}</div> */}
              </div>
              <div className="formGroup">
                <label>Housing Company</label>
                <div className="costumSelect">
                  <select name="country" id="slct" className="inputFiled">
                    <option value="">Choose an option</option>
                    <option value="1">Country 1</option>
                    <option value="2">Country 2</option>
                    <option value="3">Country 3</option>
                  </select>
                </div>
                {/* <div className="errorMessage">{errorMessage.country}</div> */}
              </div>
              <div className="formGroup">
                <label>Closing date less than</label>
                <div className="days">
                  <input className="inputFiled" />
                  <span>Days</span>
                </div>
              </div>
              <div className="formGroup">
                <label>Country</label>
                <div className="costumSelect">
                  <select name="country" id="slct" className="inputFiled">
                    <option value="">Choose an option</option>
                    <option value="1">Country 1</option>
                    <option value="2">Country 2</option>
                    <option value="3">Country 3</option>
                  </select>
                </div>
                {/* <div className="errorMessage">{errorMessage.country}</div> */}
              </div>

              <div className="formGroup">
                <label>City</label>
                <div className="costumSelect">
                  <select name="country" id="slct" className="inputFiled">
                    <option value="">Choose an option</option>
                    <option value="1">Country 1</option>
                    <option value="2">Country 2</option>
                    <option value="3">Country 3</option>
                  </select>
                </div>
                {/* <div className="errorMessage">{errorMessage.country}</div> */}
              </div>
              <div className="formGroup">
                <label>Address</label>
                <textarea className="inputFiled textarea01"></textarea>
              </div>
              <div className="formGroup">
                <div className="searchBtn">Search</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
