import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import {
	handleResponse,
	handleError,
} from "../utils";
import { domainUrl } from "../helper";

export const FETCH_SERVICES_REQUEST = "FETCH_SERVICES_REQUEST";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const FETCH_SERVICES_FAILURE = "FETCH_SERVICESFAILURE";

export const serviceSchema = new schema.Entity("services", [], {
	idAttribute: "service_type_id",
});

/**
 * Returns a redux api middleware action that makes a request for a collection of Assets.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchServiceTypes = () =>
	createAction({
		endpoint: `${domainUrl}/masterservicenew/master/service_type?order_by=created_at&sort_by=DESC`,
		method: "GET",
		headers: {
			client_code: "ht_front",
			"Content-Type": "application/json",
		},
		types: [
			{ type: FETCH_SERVICES_REQUEST },
			{
				type: FETCH_SERVICES_SUCCESS,
				payload: (action, state, res) =>
					handleResponse([serviceSchema], action, state, res),
			},
			{ type: FETCH_SERVICES_FAILURE, payload: handleError },
		],
	});
