import React, { useState, useCallback, useEffect } from "react";
import { Form, Input, message, Select, Button, Divider, Col, Row, Space } from "antd";
import EuroIcon from '@material-ui/icons/Euro';
import { useTender } from "../PreTenderSteps";

import "../../Tender/AdditionalInfo/AdditionalInfo.css";
import "../../Tender/EnterAssetDetails/EnterAssetDetails.css";
import { MinusCircleOutlined } from "@ant-design/icons";
import { fetchAssets } from "../../../actions/assets";
import { useDispatch } from "react-redux";
import { createAssetPreTender, updatePreTender, deleteAssetPreTender, fetchAssetsPreTender, updateAssetPreTender } from "../../../actions/pre-tender";
import ToolTip from "../../ToolTip";

//TODO : Rewrite component : Instead of one state, use 2 to handle assets and items separately. will become easier to understand then

const EnterAssetDetails = () => {
  const dispatch = useDispatch();
  const { preTenderDetails, onStepChange: move, modifyData } = useTender();

  const [form] = Form.useForm();
  
  const [formValue, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [asset_names, setAssetNames] = useState([]);
  const [selectedAssetDb, setSelectedAssetDb] = useState([]);

   useEffect(() => {
     let assetAdded = (!preTenderDetails?.assetsFormValues || preTenderDetails?.assetsFormValues?.length === 0) ? [ {asset_name: undefined, amount: 0 }] : preTenderDetails?.assetsFormValues;
     setFormValues({assets: assetAdded, subtotal: preTenderDetails?.subtotal})
   },[]);

  const onBack = () => {
    move(-1, { assetsFormValues: formValue.assets, subtotal: formValue.subtotal });
  };

  const onFinish = async (e) => {
    if(preTenderDetails.is_submitted !== 1) {
      //await deleteOldAssets();
    }
    try{
      let pretenderAssets = [];
      const createAssetCalls = await Promise.all(
        e?.assets?.map(async (asset, index) => {
          let obj = Object.assign({}, asset);
            let data = asset.asset_name ? (isNaN(parseInt(asset.asset_name[0])) ? {line_text: asset.asset_name[0]} : {asset_id: parseInt(asset.asset_name[0])}) : {};
            if(Array.isArray(asset.asset_name))
             data = isNaN(parseInt(asset.asset_name[0])) ? {line_text: asset.asset_name[0]} : {asset_id: parseInt(asset.asset_name[0])};
            else
              data = asset;
            data.amount = isNaN(parseInt(asset.amount)) ? 0 : +asset.amount;
            if(preTenderDetails.is_submitted !== 1 && (data.asset_name || data.line_text)) {
              let assetCall;
              if(!asset.pretender_line_id)
                assetCall = await dispatch(createAssetPreTender(data, preTenderDetails.pretender_id));
              else {
                data = asset;
                if(!asset.asset_id && !Array.isArray(asset.asset_id))
                  data.line_text = asset.asset_name[0];
                else if(Array.isArray(asset.asset_id)) {
                  data.asset_id = +asset.asset_id[0];
                }
                data.amount = isNaN(parseInt(asset.amount)) ? 0 : +asset.amount;
                assetCall = await dispatch(updateAssetPreTender(data, asset.pretender_line_id));
              }
            obj.pretender_line_id = Object.values(assetCall.payload?.entities?.preTenderAsset || {})[0].pretender_line_id;

            }
              obj.asset_name = (asset.asset_name && isNaN(parseInt(asset.asset_name[0]))) ? obj.asset_name : [selectedAssetDb[index]?.label];
            if(selectedAssetDb[index]?.value)
              obj.asset_id = [selectedAssetDb[index]?.value]
            pretenderAssets.push(obj);
        }));
    //  console.log(createAssetCalls);
      if(preTenderDetails.is_submitted !== 1) {
      await dispatch(updatePreTender({
        subtotal: parseInt(e.subtotal) === null ? 0 : +e.subtotal,
        state: parseInt(preTenderDetails.state) > 4 ? preTenderDetails.state : '4',
      },preTenderDetails.pretender_id));
    }
      move(preTenderDetails.is_submitted !== 1 ? 1 : 3, {assetsFormValues: pretenderAssets, subtotal: e.subtotal, selectedAssetDb }, preTenderDetails.is_submitted === 1);
    }
    catch (e) {
      message.error("Unable to create asset(s).");
      console.error(e);
      setIsLoading(false);
    }
  // else {
  //   move(3, {assetsFormValues: e.assets, subtotal: e.subtotal });
  // }
  };

  const deleteOldAssets = async () => {
    const getAllExistingAssets = await dispatch(fetchAssetsPreTender(preTenderDetails?.pretender_id))
    await Promise.all(
      Object.values(getAllExistingAssets.payload.entities.preTenderAsset || [])?.map(async (asset) => {
        await dispatch(deleteAssetPreTender(asset.pretender_line_id));
      }));
  };

  const deleteAssetById = async(line_id) => {
    if(line_id)
      await dispatch(deleteAssetPreTender(line_id));
  }

  const segKeyData = (key,valueKey, data) => {
    let keyArray = data.map(obj => { 
      return {label: obj[key], value: String(obj[valueKey])}});
      return keyArray.filter((value, index, self) => self.map(x => x.value).indexOf(value.value) == index)
  }

  const handleSearch = async (value) => {
    if(value) {
      const response = await dispatch(fetchAssets({limit: 1000, asset_name: value}));
      const assetData = Object.values(response.payload.entities?.assets || []);
      setAssetNames(segKeyData('asset_name','asset_id',assetData));
    }
    else {
      setAssetNames([]);
    }
  }
  const calculateCost = (arrayValue) => {
    let value = (parseInt(form.getFieldValue('subtotal')) || 0)+parseInt(arrayValue);
    // setFormValues({
    //   ...formValue,
    //   subtotal: value
    // })
    form.setFieldsValue({ ['subtotal']: value });

	}

	const setInitValues = () => {
			form.setFieldsValue({ ['subtotal']: 0 });
	}

  const onFieldsChange = (changedFields, allFields) => {
		if(changedFields[0]?.name?.length>1 && changedFields[0]?.name[2] === 'amount' ) {
			
      setInitValues();
			allFields.map(changedFields => {
				if(changedFields.name?.length>1 && changedFields.name[2] === 'amount') {
					calculateCost(changedFields.value);
				}
			})
		

		}
		
	}

  const publishData = () => {
    move(0, {
      assetsFormValues: formValue.assets, subtotal: formValue.subtotal, selectedAssetDb
    },true);
    modifyData();
  }

  return (
    <div className="__AssetDetails__ preTenderAsset">
      <div className="parentBlock2">
        <div className="hedding">
          <h2>Asset Details <ToolTip text_id="tender.asset_details.info" placement="top" /></h2>
        </div>
        {
          formValue?.assets?.length > 0 && 
       
        <Form form={form} name="dynamic_form_nest_item" initialValues={formValue} onFinish={onFinish} onFieldsChange={onFieldsChange} autoComplete="off">
      <Form.List name="assets">
      
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Row align="middle" key={key}>
                  {
                    fieldKey != 0 &&
                      <Divider />
                  }
                  
              <Col lg={10}>
                <Form.Item
                  {...restField}
                  label="Select Asset" 
                  labelAlign={'left'} 
                  labelCol={{span: 10}}
                  wrapperCol={{span: 15}}
                  colon={false}
                  name={[name, 'asset_name']}
                  fieldKey={[fieldKey, 'asset_name']}
                >
                  <Select
                    showSearch
                    mode={!formValue?.assets[fieldKey]?.pretender_line_id && "tags"}
                    className="preTender_asset"
                    placeholder="Enter Asset"
                    showArrow={false}
                    onSearch={handleSearch}
                    notFoundContent={null}
                    optionFilterProp="label"
                    options={asset_names}
                    disabled={formValue?.assets[fieldKey]?.pretender_line_id}
                    onChange={(value,option) => {
                      console.log(option);
                      if (value?.length > 1) {
                        // if you want only one element :).
                        value.pop();
                      }
                      else {
                        let assets = selectedAssetDb;
                        assets[fieldKey] = option[0]?.label ? option[0] : value[0];
                        setSelectedAssetDb(assets);
                      }
                     
                    }}
                  >
                  </Select>
                </Form.Item>
                </Col>
                  <Col lg={6} offset={1}>
                <Form.Item
                  {...restField}
                  label="Price"
                  labelAlign={'left'} 
                  labelCol={{span: 8, offset: 2}}
                  wrapperCol={{span: 15}}
                  colon={false}
                  name={[name, 'amount']}
                  fieldKey={[fieldKey, 'amount']}
                  
                >
                  <Input placeholder="Cost" />
                  
                </Form.Item>
                </Col>
                <Col lg={4}>
                <EuroIcon style={{fontSize: 14}}/>
                </Col>
                <Col>
                <MinusCircleOutlined onClick={() => {deleteAssetById(formValue?.assets[fieldKey]?.pretender_line_id); remove(name)}} />
                </Col>
                </Row>
            ))}
            <Form.Item>
            <div className="formGroup">
            <label className="leftCol"></label>
            <div  onClick={() => add()} className="addAssets">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64.413"
                height="64.413"
                viewBox="0 0 64.413 64.413"
              >
                <g
                  id="Icon_material-add-circle-outline"
                  data-name="Icon material-add-circle-outline"
                  transform="translate(0 0)"
                >
                  <path
                    id="Icon_material-add-circle-outline-2"
                    data-name="Icon material-add-circle-outline"
                    d="M38.427,19.1H31.986V31.986H19.1v6.441H31.986V51.309h6.441V38.427H51.31V31.986H38.427ZM35.207,3A32.206,32.206,0,1,0,67.413,35.206,32.218,32.218,0,0,0,35.207,3Zm0,57.971A25.765,25.765,0,1,1,60.972,35.206,25.8,25.8,0,0,1,35.207,60.971Z"
                    transform="translate(-3 -3)"
                    fill="#bfc5e2"
                  />
                </g>
              </svg>
              <span>Add Asset</span>
            </div>
          </div>
              
            </Form.Item>
          </>
        )}
      </Form.List>
      <Divider />
          <Form.Item 
                      label="Total" 
                      name="subtotal"
                      labelAlign='right'
                      labelCol={{span:4, offset: 17}}
                      wrapperCol={{span:4}} 
                      colon={false}
                >
                  <Input disabled/>
                </Form.Item>
 
        <div className="buttonBlock">
          <div onClick={isLoading ? null : onBack} className="btn cancelBtn">
            Back
          </div>
          {
              preTenderDetails?.is_submitted === 1 && 
              <div className="btn" onClick={publishData}>
                Publish
              </div>
            }
          <Button type="primary" htmlType="submit" className="btn">
          Next
        </Button>

        </div>
    </Form>
     }
        
      </div>
    </div>
  );
};

export default EnterAssetDetails;
