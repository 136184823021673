import React, { useState } from "react";
import axios from "axios";
import { message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import "../AdditionalInfo/AdditionalInfo.css";
import "./AttachFile.css";

import FileUpload from "../../../components/FileUpload";
import { awsDocumentUrl, domainUrl } from "../../../helper";
import { genHeaders, useTender } from "../TenderCreationSteps";

import ConfirmationBox from '../../ConfirmationBox';
import { useDispatch } from "react-redux";
import { updatePreTender } from "../../../actions/pre-tender";
import FileDisplay from "../../../components/FileDisplay";

const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

const AttachFile = ({updateTenderData}) => {
  const dispatch = useDispatch();
  const { tenderDetails, onStepChange: move } = useTender();

   const [fileToUploadList, setFileToUploadList] = useState(tenderDetails?.files ?? []);
  const [fileList, setFileList] = useState(tenderDetails?.files ?? []);
  const [isUploading, setIsUploading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const onBack = () => {
    move(-1, { files: fileList });
  };

  const onNext = async() => {
    const filesToUpload = fileToUploadList.filter(doc => doc.documentId === null);
    if(filesToUpload.length > 0) {
       setShowConfirmationModal(true);
    }
    else{
      await updateTenderData({
        state: '6',
      })
      if(tenderDetails.is_published !== 1) {
        move(1, { files: fileList });
      }
      else {
        move(2, { files: fileList },true);
      }
    }
  };

  const onUploadStatusChange = (e) => {
    setIsUploading(e);
  };

  const onFilesStatusChange = (e) => {
    setFileToUploadList(e);
  };

   const onFilesUploadStatusChange = (e) => {
    setFileList(e);
  };

  const confirmSubmit = (e) => {
    setShowConfirmationModal(false);
    move(1, { files: fileList });
  }

  const declineSubmit = (e) => {
    setShowConfirmationModal(false);
  }

  return (
    <div className="__AttachFile__">
      <div className="parentBlock2">
        <FileUpload
          filePostURL={FILE_POST_URL}
          entityType={"T"}
          entityID={tenderDetails?.tender_id}
          onFilesStatusChange={onFilesStatusChange}
          onUploadStatusChange={onUploadStatusChange}
          onFilesUploadStatusChange={onFilesUploadStatusChange}
        />
        <FileDisplay entityType={'T'} fileList={fileList} entityId={tenderDetails?.tender_id} hideTitle={false} deleteFiles={true}/>
        <div className="buttonBlock">
          <div onClick={isUploading ? null : onBack} className="btn cancelBtn">
            Back
          </div>
          <div onClick={isUploading ? null : onNext} className="btn">
            Next
          </div>
        </div>
      </div>
      <ConfirmationBox showConfirmationBox={showConfirmationModal} message={'You have added files but not uploaded. Proceed without uploading?' } confirmSubmit={confirmSubmit} declineSubmit={declineSubmit} />
    </div>
  );
};

export default AttachFile;
