import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin, Modal, Form, Input, Button, message } from 'antd';
import { FormattedMessage } from "react-intl";

import { createMessageCommunication } from "../../../actions/messages";

const SendQuestion = ({tenderId, tenderTitle, creator_id}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const onFinish = async(values) => {
    setConfirmLoading(true);
    const data = {
      subject: tenderTitle,
      body: values.msg,
      entity_type: "tenders",
      entity_id: +tenderId,
      receiver_id: +creator_id,
      meta: {},
    };

    const response = await dispatch(createMessageCommunication(data));
    if(response.error) {
      message.error('Unable to send messages')
    }
    else {
      message.success('Question send successfully');
    }
    form.resetFields();
    setConfirmLoading(false);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Spin spinning={confirmLoading}>
      <Button type="primary" className="btn" onClick={showModal}>
        <FormattedMessage id='send_question' />
        
      </Button>
      <Modal
        title={<FormattedMessage id='ask_question' />}
        visible={visible}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={<FormattedMessage id='message' />}
            name="msg"
            rules={[
              {
                required: true,
                message: <FormattedMessage id='error.msg' />,
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} showCount maxLength={300}/>
          </Form.Item>
         <div className="d-flex j-space manage_form" >
            <Button className="btn cancelBtn" type="secondary" onClick={handleCancel}>
              <FormattedMessage id={'cancel'} />
            </Button>
            <Button className="btn" type="primary" htmlType="submit">
              <FormattedMessage id={'send'} />
            </Button>
          </div>
          </Form>
      </Modal>
      </Spin>
  )
}

export default SendQuestion;