import React from "react";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import InspectionLine from '../InspectionLine'

const InspectionCategory = ({ categoryData, isReadOnly, indexKey }) => {
	const history = useHistory();

	return (
		<>
				<Row className="category_row" key={'c'+indexKey}>
					<Col md={6} lg={8} xl={8}>
						{categoryData.inspection_category_name}
					</Col>
						<Col span={7}>
							Fitness Rating
						</Col>
						
						<Col span={2} offset={1}>
							Comments
						</Col>
				</Row>
				<Row className="category_row" key={'r'+indexKey}>
					<Col md={6} lg={8} xl={8}>
					</Col>
						<Col span={1}>
							1
						</Col>
						<Col span={1}>
							2
						</Col>
						<Col span={1}>
							3
						</Col>
						<Col span={1}>
							4
						</Col>
						<Col span={1}>
							OK
						</Col>
						<Col span={1}>
						</Col>	
						<Col span={2} offset={1}>
						</Col>
				</Row>
				{
					!isReadOnly && 
					categoryData?.inspection_items.map((item, index) => (
						<InspectionLine key={index} lineData={item} isReadOnly={isReadOnly}/>
					))
				}
				{
					isReadOnly && 
					categoryData?.inspection_lines.map((item, index) => (
						<InspectionLine key={index} lineData={item} isReadOnly={isReadOnly}/>
					))
				}
		</>
	);
};

InspectionCategory.propTypes = {
	categoryData: PropTypes.shape({}),
	isReadOnly: PropTypes.bool,
};

export default InspectionCategory;