import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import Protected from '../Protected';
import NotAuthorized from '../../pages/notAuthorized';

const PrivateRoute = ({role, component: Component, ...rest }) => {
  const isAuthenticated = sessionStorage.getItem("token") ? true : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Protected roles={role} fallback={NotAuthorized}>
            <Component {...props} />
          </Protected>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
