import "./EuroInput.less";
import { useState } from "react";

const EuroInput = ({ children, style, onDiff, initialValue, ...rest }) => {
  const [inputValue, setInputValue] = useState(initialValue || 0);

  const onInputChange = (e) => {
    const currVal = inputValue;
    const nextVal = e?.target?.value ?? 0;
    setInputValue(nextVal);

    console.log(e.target.value);
    if (onDiff) {
      onDiff(currVal, nextVal);
    }
  };

  return (
    <div className="__EuroInput__inputcontainer" style={style}>
      <input
        style={{ background: rest?.disabled ? "#cacaca" : null, color: rest?.hide ? "#cacaca" : '#000' }}
        min={0}
        value={inputValue}
        onChange={onInputChange}
        className="formFiled"
        {...rest}
        type="number"
      />
    </div>
  );
};

export default EuroInput;
