import "./App.less";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IntlProvider } from 'react-intl'
import SMSSetting from "./components/SMSSetting/SMSSetting";
import EmailSetting from "./components/EmailSetting/EmailSetting";
import PaymentGateway from "./components/PaymentGateway/PaymentGateway";
import ServiceType from "./components/ServiceType/ServiceType";
import ConsultancyPrices from "./components/ConsultancyPrices/ConsultancyPrices";
import CompanyType from "./components/CompanyType/CompanyType";
import Login from "./components/Login/Login";
import Dashboard from "./pages/dashboard";
import LoggedInState from './components/LoggedInState';
import PrivateRoute from "./components/PrivateRoute";
import Organization from "./components/Organization";
import Company from "./components/Company";
import Tender from "./pages/tender";
import TenderDetail from "./components/TenderDetail/TenderDetail";
import TenderDetailSupplier from "./components/TenderDetailSupplier/TenderDetail";
import BidReceived from "./pages/bidReceived";
import Asset from "./pages/asset";
import SupplierManagement from "./pages/supplierManagement";
import InspectionReport from "./pages/inspectionReport";
import AddInspectionReport from "./pages/addInspectionReport";
import ViewInspectionReport from './pages/viewInspectionReport';
import PreTender from './pages/preTender';
import PreTenderDetail from './pages/preTenderDetail'
import AdminPreTender from './pages/adminPreTender';
import { getUser } from "./actions/auth";
import AllTenders from './pages/allTenders';
import Bid from "./pages/bid";
import BidSubmitted from './pages/bidSubmitted';
import axios from "axios";
import { useEffect, useState } from "react";
import SupplierRegistration from './pages/supplierRegistration';
axios.defaults.baseURL = "http://65.1.179.129";

function App(props) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  let userId = sessionStorage.getItem('user_id');
console.log(userId)

  const getUserData = async() => {
    setIsLoading(true);
    await dispatch(getUser(userId));
    setIsLoading(false);
  }

  useEffect(() => {
    if(userId) {
      setIsLoading(true);
      getUserData();
    }
    else {
      setIsLoading(false);
    }
  },[userId])
  
  return (
    <> {
      !isLoading ?
   
    (<div className="App">
      <Router>
        <Switch>
          <LoggedInState exact path="/login" component={Login} />
          <PrivateRoute exact path="/" role={[1, 2, 3]} component={Dashboard} />
          //admin
          <PrivateRoute exact path="/sms-setting" role={[1]} component={SMSSetting} />
          //admin
          <PrivateRoute exact path="/email-setting" role={[1]} component={EmailSetting} />
          //admin
          <PrivateRoute
            exact
            path="/bid/received/:tenderId/:name"
            role={[1,2]}
            component={BidReceived}
          />
          //admin
          <PrivateRoute
            exact
            path="/payment-gateway"
            role={[1]}
            component={PaymentGateway}
          />
          //admin
          <PrivateRoute exact path="/service-type" role={[1]} component={ServiceType} />
          //admin
          <PrivateRoute
            exact
            path="/consultancy-prices"
            role={[1]}
            component={ConsultancyPrices}
          />
          //admin
          <PrivateRoute exact path="/company-type" role={[1]} component={CompanyType} />
          //admin
          <PrivateRoute exact path="/organization" role={[1]} component={Organization} />
          //admin
          <PrivateRoute exact path="/company" role={[1,2]} component={Company} />
          //admin
          <PrivateRoute exact path="/asset" role={[1,2]} component={Asset} />
          //admin
          <PrivateRoute exact path="/supplier" role={[1]} component={SupplierManagement} />
          //admin
          <PrivateRoute exact path="/inspection-report" role={[1]} component={InspectionReport} />
          //admin
          <PrivateRoute exact path="/inspection-report/add/:assetId" role={[1]} component={AddInspectionReport} />
          //admin
          <PrivateRoute exact path="/inspection-report/:inspectionId" role={[1]} component={ViewInspectionReport} />
          //admin
          <PrivateRoute exact path="/tender" role={[1,2]} component={Tender} />
          //admin
          <PrivateRoute exact path="/tenders" role={[1,2]} component={AllTenders} />
          //admin
          <PrivateRoute exact path="/tender/:tenderId" role={[1,2]} component={Tender} />
          //admin
          <PrivateRoute exact path="/pre-tender/:preTenderId/supplier/:supplierId" role={[1]} component={AdminPreTender} />
          //supplier
          {/*<PrivateRoute
            exact
            path="/SupplierDashboard"
            role={[3]} 
            component={SupplierDashboard}
          />*/}
          //supplier
          <PrivateRoute exact path="/pre-tender" role={[3]} component={PreTender} />
          //supplier
          <PrivateRoute exact path="/pre-tender/:preTenderId" role={[3]} component={PreTender} />
          //supplier
          <PrivateRoute exact path="/pre-tender/detail/:preTenderId" role={[3]} component={PreTenderDetail} />
          //supplier
          <PrivateRoute exact path="/bid/:tenderId" role={[3]} component={Bid} />
          //supplier
          <PrivateRoute exact path="/tenderBid/:tenderId" role={[3]} component={BidSubmitted} />
          //admin
          <PrivateRoute
            exact
            path="/tender/detail/:tenderId"
            role={[1,2]}
            component={TenderDetail}
          />
          //supplier
          <PrivateRoute
            exact
            path="/tender/detail/supplier/:tenderId"
            role={[3]}
            component={TenderDetailSupplier}
          />
          <Route
            exact
            path="/register/:invite_type/:invite_code"
            component={SupplierRegistration}
          />

          <Route>
            <p>404 Not Found</p>
          </Route>
        </Switch>
      </Router>
    </div>) : (<></>)
     }
     </>
  );
}

export default App;
