import { handleQuery } from '../utils';

const baseUrls = {
    domainURL: 'http://65.1.179.129',
    masterService: 'masterservicenew/master',
    tender_v1: 'tender/v1/tender',
    tender_v2: 'tender/v2/tender',
    bid_v1: 'bid/v1/tender',
}

const apiUrls = {
    tenderTypeRequest: `${baseUrls.domainURL}/${baseUrls.masterService}/tender_type`,

    tenderRequest: `${baseUrls.domainURL}/${baseUrls.tender_v1}`,
    tenderRequestWithParams: params => `${baseUrls.domainURL}/${baseUrls.tender_v1}?${handleQuery(params)}`,
    tenderById: tender_id => `${baseUrls.domainURL}/${baseUrls.tender_v1}/${tender_id}`,

    tenderAssetRequest: tender_id => `${baseUrls.domainURL}/${baseUrls.tender_v1}/${tender_id}/asset`,
    tenderAssetByTenderId: tender_id => `${baseUrls.domainURL}/${baseUrls.tender_v1}/asset/${tender_id}`,
    tenderAssetByLineId: tender_line_id => `${baseUrls.domainURL}/${baseUrls.tender_v1}/asset/${tender_line_id}`,

    tenderAssetItemRequest: tender_line_item_id => `${baseUrls.domainURL}/${baseUrls.tender_v1}/asset/${tender_line_item_id}/items`,
    tenderAssetItemBulkRequest: tender_line_item_id => `${baseUrls.domainURL}/${baseUrls.tender_v2}/asset/${tender_line_item_id}/items/bulk`,
    tenderAssetItemByLineId: tender_line_item_id => `${baseUrls.domainURL}/${baseUrls.tender_v2}/asset/items/${tender_line_item_id}`,
    
    tenderCompanyRequest: tender_id => `${baseUrls.domainURL}/${baseUrls.tender_v2}/${tender_id}/company`,
    tenderCompaniesRequest: tender_id => `${baseUrls.domainURL}/${baseUrls.tender_v2}/${tender_id}/companies`,
    tenderCompanyByCompanyId: (tender_id, company_id) => `${baseUrls.domainURL}/${baseUrls.tender_v2}/${tender_id}/company/${company_id}`,
    
    tenderSupplierRequest: tender_id => `${baseUrls.domainURL}/${baseUrls.tender_v2}/${tender_id}/supplier`,
    tenderSuppliersRequest: tender_id => `${baseUrls.domainURL}/${baseUrls.tender_v2}/${tender_id}/suppliers`,
    tenderSupplierBySupplierId: (tender_id, supplier_id) => `${baseUrls.domainURL}/${baseUrls.tender_v2}/${tender_id}/supplier/${supplier_id}`,
   
    tenderSupplierInviteRequest: tender_id => `${baseUrls.domainURL}/${baseUrls.tender_v1}/${tender_id}/supplier/invite`,
    tenderSupplierInviteByEmail: (tender_id, supplier_email) => `${baseUrls.domainURL}/${baseUrls.tender_v1}/${tender_id}/supplier/invite?supplier_email=${supplier_email}`,
   
    tenderListRequest: params => `${baseUrls.domainURL}/${baseUrls.tender_v2}/supplier?${handleQuery(params)}`,
   
    tenderPdfRequest: tender_id => `${baseUrls.domainURL}/${baseUrls.tender_v1}/${tender_id}/download`,

    communicationRequest: `${baseUrls.domainURL}/communication/v1/communicate`,

    tenderBidsRequest: (params, tender_id) => `${baseUrls.domainURL}/${baseUrls.bid_v1}/${tender_id}/bid?${handleQuery(params)}`,

    messageRequest: `${baseUrls.domainURL}/communication/v1/messages`,
    messageRequestById: message_id => `${baseUrls.domainURL}/communication/v1/messages/${message_id}`,
    messageListRequestInbox: params => `${baseUrls.domainURL}/communication/v2/messages/inbox?${handleQuery(params)}`,
    messageListRequestSendbox: params => `${baseUrls.domainURL}/communication/v2/messages/sendbox?${handleQuery(params)}`,
}

export default apiUrls;