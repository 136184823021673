import React, {useEffect, useState} from 'react';
import { Button, Tooltip, Spin } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';

import Header from "../../components/Header";
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getUser } from '../../actions/auth';
import { useHistory, useParams } from 'react-router';
import PreTenderDetails from '../../components/PreTenderDetail';
import MessageToast from "../../components/MessageToast";

const AdminPreTender = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {supplierId, preTenderId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [showFeedback, setShowFeedback] = useState({
        open: false,
        success: false,
        error: false,
        info: false,
        message: "",
      });

     const supplier = useSelector(state => get(state, `entities.users.${supplierId}.name`));

    useEffect(() => {
        const getSupplierName = async() => {
           await dispatch(getUser(supplierId));
        }
        getSupplierName();
    },[supplierId]);

    const finishTenderCreation = (isSuccess, message, preventRedirect) => {
        setShowFeedback({
          open: true,
          success: isSuccess,
          error: !isSuccess,
          info: false,
          message: message,
        });
        if(!preventRedirect) {
          setTimeout(() => {
            history.push(`/`)
          },2000);
        }
      }

    const closeFeedback = () => {
        setShowFeedback({
          open: false,
          success: false,
          error: false,
          info: false,
          message: "",
        });
    };

	return (
    <>
     {showFeedback.open && (
        <MessageToast
          open={showFeedback.open}
          success={showFeedback.success}
          error={showFeedback.error}
          message={showFeedback.message}
          onClose={closeFeedback}
        />
      )}
      <Header />
      <div className="mainContainer">
      	<div className="innerContainer">
          <Spin spinning={isLoading}>
          <h2 className="title">Pre Tender Details</h2>
          <h3 className="title">Received from {supplier}</h3>
              {
               <PreTenderDetails preTenderId={preTenderId} setIsLoading={setIsLoading} finishTenderCreation={finishTenderCreation}/>
              }
           </Spin>   
        </div>
      </div>
      
    </>
  );
};
 export default AdminPreTender;
