import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import get from 'lodash/get';
import {
	handleResponse,
	handleError,
    handleReducerResponse,
} from "../utils";
import { domainUrl } from "../helper";

export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE";

export const VALIDATE_TOKEN_REQUEST = "VALIDATE_TOKEN_REQUEST";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAILURE = "VALIDATE_TOKEN_FAILURE";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const authSchema = new schema.Entity("auth", {}, {
	idAttribute: "user_id",
});

export const userSchema = new schema.Entity("users", {}, {
	idAttribute: "id",
});

export const login = (data) => 
    createAction({
        endpoint: `${domainUrl}/auth/login`,
		method: "POST",
        body: JSON.stringify(data),
        headers: {
            client_code: "ht_front",
            "Content-Type": "application/json",
          },
		types: [
			{ type: FETCH_LOGIN_REQUEST },
			{
				type: FETCH_LOGIN_SUCCESS,
				payload: handleReducerResponse,
			},
			{ type: FETCH_LOGIN_FAILURE, payload: handleError },
		],
    });

/**
 * Returns a redux api middleware action that makes a request to validate token.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const validateToken = () =>
	createAction({
		endpoint: `${domainUrl}/auth/token`,
		method: "POST",
        body: JSON.stringify({token: sessionStorage.getItem('token')}),
        headers: {
            client_code: "ht_front",
            "Content-Type": "application/json",
          },
		types: [
			{ type: VALIDATE_TOKEN_REQUEST },
			{
				type: VALIDATE_TOKEN_SUCCESS,
				payload: (action, state, res) =>
					handleResponse([userSchema], action, state, res),
			},
			{ type: VALIDATE_TOKEN_FAILURE, payload: handleError },
		],
	});

/**
 * Returns a redux api middleware action that makes a request to validate token.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const getUser = (userId) =>
 createAction({
     endpoint: `${domainUrl}/users/${userId}`,
     method: "GET",
     headers: {
         client_code: "ht_front",
         token: sessionStorage.getItem("token"),
         "Content-Type": "application/json",
       },
     bailout: store => get(store, `entities.users.${userId}`, false),
     types: [
         { type: FETCH_USER_REQUEST },
         {
             type: FETCH_USER_SUCCESS,
             payload: (action, state, res) =>
                 handleResponse(userSchema, action, state, res),
         },
         { type: FETCH_USER_FAILURE, payload: handleError },
     ],
 });
