import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { DeleteTwoTone } from '@ant-design/icons';
import moment from 'moment';

import "./TenderCard.less";
import { genHeaders } from "../Tender/Tender";
import { useDispatch } from "react-redux";
import { fetchPreTenders, deletePreTender } from "../../actions/pre-tender";
import { getUser } from "../../actions/auth";
import ConfirmationBox from '../ConfirmationBox';

const TOGGLE_LIMIT = 4;

const SUBMITTED = "submitted";
const NEWTENDER = "new";
const DRAFT = "draft";
const AWARDED = "awarded";

const TenderCard = ({ status }) => {
  const dispatch = useDispatch();
  const [tenders, setTenders] = useState();
  const [loading, setLoading] = useState(false);
  const [toggleMore, setToggleMore] = useState(true);
  const [error, setError] = useState();
  const [title] = useState(() => {
    if (status === "current") return ["tenders_submitted_bids", SUBMITTED];
    if (status === "draft") return ["new_tenders", NEWTENDER];
    if (status === "pre") return ["pre_tenders", null];
    if (status === "awarded") return ["awarded_tenders", AWARDED];
  });

   const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deleteTenderId, setDeleteTender] = useState('');
  const history = useHistory();

  useEffect(() => {
    const getTendersInfo = async () => {
      try {
        setLoading(true);
        setError(false);

        const queryParam = status ? `&status=${status}` : "";

        let tenderInfo = await axios.get(
          `/tender/v2/tender/supplier?order_by=updated_at&sort_by=DESC${queryParam}`,
          genHeaders()
        );

        tenderInfo = tenderInfo.data.data.result;

        const creators = await Promise.all(
          tenderInfo?.map((tender) =>
            axios
              .get(`/users/${tender.created_by}`, genHeaders())
              .then((r) => r.data.data)
          )
        );

        tenderInfo = tenderInfo?.map((tender, index) => {
          return {
            name: tender?.title,
            created_by: creators[index]?.name,
            stat: title[1],
            id: tender?.tender_id,
            closing_date: tender?.closing_date,
            updated_at: tender?.updated_at,
            no_of_suppliers: tender?.no_of_suppliers,
            award_datetime: tender?.award_datetime,
            company_name: tender?.company_name,
            organization_name: tender?.organization_name,
          };
        });

        setTenders(tenderInfo);
        setLoading(false);
      } catch (e) {
        setError(true);
        setLoading(false);
        setTenders(null);
      }
    };

    if(status !== 'pre')
      getTendersInfo();
    else
      getPreTenderInfo();
  }, []);

      const getPreTenderInfo = async() => {
      setLoading(true);
      setError(false);
      const response = await dispatch(fetchPreTenders({limit: 1000, order_by: 'updated_at', sort_by: 'DESC', status: 'pending'}));
      let preTendersPayload = response?.payload.entities?.preTender;
     // const userResponse = await dispatch(getUser(preTenders[0]?.created_by));
      const userName = ''; //userResponse.payload.entities?.users[preTenders[0].created_by]?.name || '';
      let preTenders = response?.payload?.result?.map( id => {
       return {
          name: preTendersPayload[id]?.title,
          created_by: userName,
          stat: preTendersPayload[id]?.is_submitted === 0 ? 'draft' : 'submitted',
          id: id,
          organization_name: preTendersPayload[id]?.organization_name,
          company_name: preTendersPayload[id]?.company_name,
          submitted_date: preTendersPayload[id]?.submitted_date,
        }
      });
      console.log(preTenders)
      setTenders(preTenders);
      setLoading(false);
    }

   const declineSubmit = () => {
    setShowConfirmationBox(false);
    setDeleteTender('');
  }

    const confirmDelete = async () => {
        setShowConfirmationBox(false);
      await dispatch(deletePreTender(deleteTenderId));
      getPreTenderInfo();
      };

  const deleteDraftTender = (tender_id) => {
    console.log(tender_id);
    setDeleteTender(tender_id); 
    setShowConfirmationBox(true);
  }

  const onTenderClick = (e, tender) => {
     if(e.target.nodeName.toLowerCase() === 'path') {
      e.stopPropagation();
      return;
    }
    if (tender?.id) {
      if (status === "draft" || status === "awarded") {
        history.push(`/tender/detail/supplier/${tender.id}`);
      }
      if(status === 'current') {
        history.push(`/tenderBid/${tender.id}`)
      }
      if (status === "pre") {
        history.push(tender.stat === 'draft' ? `/pre-tender/${tender.id}`: `/pre-tender/detail/${tender.id}`);
      }
    }
  };

  let content;

  if (loading) {
    content = <Spin style={{ display: "flex", justifyContent: "center" }} />;
  } else if (error || !tenders?.length) {
    // content = (
    // <p style={{ width: "100%", textAlign: "center" }}>
    // Error while loading tenders
    // </p>
    // );
  } else {
    content = (
      <>
        <div className="cardcontainer">
          <ul className="title">
            <li><FormattedMessage id={'name'}/></li>
              <li><FormattedMessage id={'company'} /></li>
            {
              status !== 'pre' &&
            <li><FormattedMessage id={'created_by'}/></li>
            }
             {
              (status !== 'pre' &&  status !== AWARDED) &&
                 <li><FormattedMessage id={'last_updated'}/></li>
            }
            {
              status === AWARDED && 
                <li><FormattedMessage id={'awarded_date'} /></li>
            }
            {
              status === 'pre' &&
              <>
            <li><FormattedMessage id={'status'}/></li>
            <li><FormattedMessage id={'date_submitted'} /></li> 
            {
              (status === 'pre') && (
                <li style={{width: '5%'}}></li>
              )
            }
            </>
            }
          </ul>
          {tenders
            ?.filter((_, i) => i < (toggleMore ? TOGGLE_LIMIT : tenders.length))
            ?.map((tender, index) => (
              <ul
                key={index}
                className="content"
                style={{ cursor: "pointer" }}
                onClick={(e) => onTenderClick(e, tender)}
              >
                <li className="d-flex">
                  <span>{tender?.name}</span>
                </li>
              <li>{tender?.company_name}</li>
                {
              status !== 'pre' &&
                <li>
                  <span>{tender?.created_by}</span>
                </li>
                }
                {
                  (status !== 'pre' &&  status !== AWARDED) &&
                    <li>{moment(tender?.updated_at).format("DD-MM-YYYY")}</li>
                }
                {
                  status === AWARDED && 
                    <li>{moment(tender?.award_datetime).format("DD-MM-YYYY")}</li>
                }
                {
              status === 'pre' &&
              <>
                <li>
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        tender?.stat === SUBMITTED || tender?.stat === AWARDED
                          ? "green"
                          : tender?.stat === DRAFT
                          ? "red"
                          : tender?.stat === NEWTENDER
                          ? "#fabf01"
                          : null,
                    }}
                  >
                  <FormattedMessage id={tender?.stat}/>
                    
                  </span>
                </li>
                <li>{tender?.submitted_date && moment(tender?.submitted_date).format("DD-MM-YYYY")}</li>
                {
                  (status === 'pre' && tender?.stat === DRAFT) && 
                    <DeleteTwoTone twoToneColor={'red'} onClick={() => deleteDraftTender(tender.id)}/>
                }
                </>
              }
              </ul>
            ))}
            {
              status === 'pre' && (
                <div onClick={() => history.push("/pre-tender")} className="addTenderBtn">
                  <span className="btn-icon"></span> <span><FormattedMessage id={'add_new_pre_tender'}/></span>
                </div>
              )
            }
        </div>
        {tenders?.length > TOGGLE_LIMIT && (
          <div className="viewAll" onClick={() => setToggleMore(!toggleMore)}>
            {toggleMore && <FormattedMessage id={'show_more'}/>}
            {!toggleMore && <FormattedMessage id={'show_less'}/>}
          </div>
        )}
      </>
    );
  }

  return (
    <div className="__TenderCard__">
      <div className="card">
        <h2><FormattedMessage id={title[0]}/></h2>
        {content}
      </div>
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={'Are you sure you want to delete this tender?' } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
    </div>
  );
};

export default TenderCard;
