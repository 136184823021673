import React, { useState } from "react";
import "./AddModal.css";

const AddModal = (props) => {
  const [consultancyPrice, setConsultancyPrice] = React.useState(props.data);
  const [errorMessage, setErrorMessage] = React.useState({});
  function handleInputChange(event) {
    const data = {
      ...consultancyPrice,
      [event.target.name]: event.target.value,
    };
    setConsultancyPrice(data);
    setErrorMessage({});
  }
  function handleSubmit() {
    if (!consultancyPrice) {
      setErrorMessage({
        planName: "Please Enter Name",
        price: "Please Enter Price",
        description: "Please Enter Description",
      });
    } else if (!consultancyPrice.planName || consultancyPrice.planName === "") {
      setErrorMessage({
        planName: "Please Enter Name",
      });
    } else if (!consultancyPrice.price || consultancyPrice.price === "") {
      setErrorMessage({
        price: "Please Enter Price",
      });
    } else if (!consultancyPrice.description || consultancyPrice.description === "") {
      setErrorMessage({
        price: "Please Enter Description",
      });
    } else if (
      consultancyPrice &&
      consultancyPrice.planName !== "" &&
      consultancyPrice.price !== ""
    ) {
      props.onSubmit(consultancyPrice);
    }
  }

  return (
    <div className="pageOverlay">
      <div className="modalOuter">
        <div className="closeBtn" onClick={() => props.onClose()}>
          close
        </div>
        <div className="inputFieldGroup">
          <label>Name</label>
          <input
            className="inputFiled"
            type="text"
            name="planName"
            placeholder=""
            value={consultancyPrice ? consultancyPrice.planName : ""}
            onChange={handleInputChange}
          />
          <div className="errorMessage">{errorMessage.planName}</div>
        </div>
        <div className="inputFieldGroup">
          <label>Price</label>
          <input
            className="inputFiled"
            type="text"
            name="price"
            placeholder=""
            value={consultancyPrice ? consultancyPrice.price : ""}
            onChange={handleInputChange}
          />
          <div className="errorMessage">{errorMessage.price}</div>
        </div>
        <div className="inputFieldGroup">
          <label>Description</label>
          <input
            className="inputFiled"
            type="text"
            name="description"
            placeholder=""
            value={consultancyPrice ? consultancyPrice.description : ""}
            onChange={handleInputChange}
          />
          <div className="errorMessage">{errorMessage.description}</div>
        </div>
        <div className="buttonBlock">
          <div className="btn cancelBtn" onClick={() => props.onClose()}>
            Cancel
          </div>
          <div className="btn" onClick={() => handleSubmit()}>
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
