import React, { useState } from "react";
import axios from "axios";

import "../../Tender/AdditionalInfo/AdditionalInfo.css";
import { genHeaders, useTender } from "../PreTenderSteps";
import TextEditor from '../../TextEditor';
import { domainUrl } from "../../../helper";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { updatePreTender } from "../../../actions/pre-tender";
import ToolTip from "../../ToolTip";

const AdditionalInfo = () => {
  const dispatch = useDispatch();
  const { preTenderDetails, onStepChange: move, modifyData } = useTender();

  const [info, setInfo] = useState(preTenderDetails?.additional_info ?? "");

  const onNext = async() => {
    if(preTenderDetails.is_submitted !== 1) {
    if (info.trim() !== "") {
      let putResponse = await dispatch(updatePreTender({
        state: parseInt(preTenderDetails.state) > 6 ? preTenderDetails.state : '6',
        additional_info: info,
      }, preTenderDetails?.pretender_id));
  
      let editedPreTender = putResponse.payload?.entities?.preTender[preTenderDetails?.pretender_id]
      if (editedPreTender?.pretender_id) {
        move(1, { additional_info: info });
      }
      else {
        message.error("Unable to reach server.");
      }
    }
    else {
      move(1, { additional_info: info });
    }
  }
  else {
    move(1, {
      additional_info: info,
    },true);
  }
  };
  const onBack = () => {
    move(-1, { additional_info: info });
  };

  const saveAdditionalInfo = (data) => {
    setInfo(data);
  }

  const publishData = () => {
    move(0, {
      additional_info: info,
    },true);
    modifyData();
  }

  return (
    <div className="__AdditionalInfo__">
      <div className="hedding">
        <h2>Additional Information/Comment <ToolTip text_id="tender.additional_info.info" placement="top" /></h2>
      </div>
      <div className="commentblock">
        <h3 className="left">Additional Information/Comment</h3>
        <div className="right">
          <TextEditor textData={info} onChangeData={saveAdditionalInfo}/>
          
        </div>
      </div>
      <div className="buttonBlock">
        <div onClick={onBack} className="btn cancelBtn">
          Back
        </div>
        {
          preTenderDetails?.is_submitted === 1 && 
          <div className="btn" onClick={publishData}>
            Publish
          </div>
        }
        <div onClick={onNext} className="btn">
          Next
        </div>
      </div>
    </div>
  );
};

// Tender.propTypes = {};

export default AdditionalInfo;
