import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import './ToolTip.css';



const ToolTip = ({text_id, placement}) => {

	return (

    <span className="tool_tip">
      <FormattedMessage id={text_id} />
    </span>
          
        //   <Tooltip overlayClassName="tool_tip" placement={placement} title={<FormattedMessage id={text_id} />}>
        //     <InfoCircleOutlined className="info_icon"/>
        // </Tooltip>
	);
};

ToolTip.propTypes = {
	tender_id: PropTypes.number,
};

export default ToolTip;