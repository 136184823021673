import React, { useEffect, useState } from "react";
import { DatePicker, message } from "antd";
import moment from 'moment';

import "../AdditionalInfo/AdditionalInfo.css";
import "./ChooseClosingDate.css";
import { useTender } from "../TenderCreationSteps";
import ToolTip from '../../ToolTip';

const ChooseClosingDate = ({updateTenderData}) => {
  const { tenderDetails, onStepChange: move, modifyData, tenderModifiedDetails } = useTender();

  const [closingDate, setClosingDate] = useState([]);
  const [reminder, setReminder] = useState(tenderDetails?.days_reminder ?? 1);
  const [errorMessage, setErrorMessage] = useState("");
  const onDateChange = (e) => {
    const formattedDate = e?.format(`y-MM-DD`);
    setClosingDate([e, formattedDate]);
  };

  useEffect(() => {
    if(tenderDetails?.is_published === 0 && tenderDetails?.closing_date !== null) {
      setClosingDate([moment(tenderDetails?.closing_date), tenderDetails?.closing_date])
    }

  },[tenderDetails?.closing_date])

  useEffect(() => {
    console.log(tenderModifiedDetails);
    if(tenderModifiedDetails?.is_published === 1 && tenderModifiedDetails?.closing_date !== null) {
      setClosingDate([moment(tenderModifiedDetails?.closing_date[1]), tenderModifiedDetails?.closing_date[1]]);
      setReminder(tenderModifiedDetails?.days_reminder ?? 1)
    }

  },[tenderModifiedDetails?.closing_date])

  const onBack = () => {
    move(-1, { closingDate, reminder });
  };

  const onNext = async() => {

    setErrorMessage(null);

    if (!(closingDate && closingDate.length && closingDate[1]) || !reminder) {
      setErrorMessage(
        "Both Closing Date & Reminder Interval are mandatory.Please fill both."
      );
      return;
    }
    if(tenderDetails.is_published !== 1) {
      const status = await updateTenderData( {
        closing_date: closingDate[1],
        days_reminder: +reminder,
        state: '7',
      });
      if(status) {
        move(1, { closing_date: closingDate, days_reminder: +reminder  });
      }
      else {
        message.error("Unable to reach server");
      }
    }
    else {
      move(1, {
        closing_date: closingDate, days_reminder: +reminder 
      },true);
    }
  };

  const publishData = () => {
    move(0, {
      closing_date: closingDate, days_reminder: +reminder 
    },true);
    modifyData();
  }

  return (
    <div className="__ClosingDate__">
      <div className="hedding">
          <h2>Choose Closing Date <ToolTip text_id="tender.closing_date.info" placement="top" /></h2>
        </div>
      <div className="formGroup">
        <label className="leftCol">Closing Date</label>
        <div className="selectDate">
          <DatePicker
            value={closingDate[0]}
            onChange={onDateChange}
            style={{ padding: "10px", borderRadius: "10px" }}
          />
        </div>
      </div>
      <div className="formGroup">
        <label className="leftCol">Send Reminders Before</label>
        <input
          type="number"
          min={1}
          value={reminder}
          onChange={({ target }) => setReminder(target.value)}
          className="formFiled days"
        />
        <span className="dayslabel">Days</span>
      </div>
      {errorMessage && (
        <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
          {errorMessage}
        </p>
      )}
      <div className="buttonBlock">
        <div onClick={onBack} className="btn cancelBtn">
          Back
        </div>
        {
          tenderDetails?.is_published === 1 && 
          <div className="btn" onClick={publishData}>
            Publish
          </div>
        }
        <div onClick={onNext} className="btn">
          Next
        </div>
      </div>
    </div>
  );
};

// Tender.propTypes = {};

export default ChooseClosingDate;
