import React from "react";
import PropTypes from "prop-types";
import Header from "../Header/Header";
import Tenders from "./Tenders/Tenders";
import "./Dashboard.css";
import MessagesCard from "../MessagesCard";

const Dashboard = (props) => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="leftSidePanel">
          <Tenders />
        </div>
        <div className="rightSidePanel">
          <MessagesCard />
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
