import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DeleteOutlined } from '@ant-design/icons';
import { Spin, message } from "antd";

import { fetchFiles, fetchDocument, deleteDocument } from "../../actions/documents";



const FileDisplay = ({entityType, fileList, entityId, hideTitle, deleteFiles}) => {

    const dispatch = useDispatch();

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deletedId, setDeletingId] = useState('');

    const getDocuments = async() => {
		  const documents = await dispatch(fetchFiles({entity_type: entityType, entity_id: entityId}));
		  setUploadedFiles(Object.values(documents.payload?.entities?.files || []));
		}
    useEffect(() => {
		  getDocuments();
		}, [fileList]);

		const onFileClick = async (e, file) => {
      console.log(e.target.nodeName);
      if(e.target.nodeName.toLowerCase() === 'path' || e.target.nodeName.toLowerCase() === 'svg') {
        e.stopPropagation();
        return;
      }
			const response = await dispatch(fetchDocument(file.document_id));
      let link =  response.payload?.entities?.files[file.document_id]?.signed_link || ''
      const win = window.open(link, "_blank");
          win.focus();
		  };

    const onFileDelete = async (file) => {
      setDeletingId(file.document_id);
      setIsLoading(true);
      const response = await dispatch(deleteDocument(file.document_id));
      if(response.payload.success) {
        message.success(response.payload.message);
      }
      else {
        message.error(response.payload.message || 'Document not deleted');
      }
      await getDocuments();
      setIsLoading(false);
    };

	return (
		<>
        <Spin spinning={isLoading}>

    {
      !hideTitle && 
      <h2>Files Uploaded</h2>
    }
		
        {
			uploadedFiles.map((file,fileIndex) => (
          <div
              onClick={(e) => onFileClick(e, file)}
              key={fileIndex}
              style={{ cursor: "pointer", position: 'relative' }}
              className="filesNames mt-2"
            >
              <div className="filesDes">
                <span>File Description</span>
                <span>{file.file_description}</span>
              </div>
              <div className="fileFormat">
                <div className="fileType">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="51.467"
                    height="68.622"
                    viewBox="0 0 51.467 68.622"
                  >
                    <path
                      id="Icon_awesome-file-image"
                      data-name="Icon awesome-file-image"
                      d="M51.467,16.344v.812H34.311V0h.812A3.217,3.217,0,0,1,37.4.942L50.525,14.069A3.217,3.217,0,0,1,51.467,16.344Zm-18.228,5.1a3.226,3.226,0,0,1-3.217-3.217V0H3.217A3.217,3.217,0,0,0,0,3.217V65.406a3.217,3.217,0,0,0,3.217,3.217H48.25a3.217,3.217,0,0,0,3.217-3.217V21.444ZM15.084,23.589a6.433,6.433,0,1,1-6.433,6.433A6.433,6.433,0,0,1,15.084,23.589ZM42.962,55.756H8.651l.065-6.5,5.3-5.3a1.532,1.532,0,0,1,2.209.065l5.3,5.3L35.391,35.448a1.608,1.608,0,0,1,2.275,0l5.3,5.3Z"
                      fill="#57b8ff"
                    />
                  </svg>
                </div>
                <div className="fileInfo">
                  <div className="text">{file.file_name}</div>
                  {/* <div className="fileSize">200kb</div> */}
                </div>
                {
                  deleteFiles && 
                    <DeleteOutlined
                      onClick={() => onFileDelete(file)}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "30px",
                        color: "red",
                        fontSize: '12px',
                      }}
                    />
                }
                
              </div>
            </div>
            ))
            }
			{
				uploadedFiles.length === 0 && 
				<p>No Files Uploaded</p>
			}
          </Spin>

		</>
	);
};

FileDisplay.propTypes = {
	entityType: PropTypes.string,
  entityId: PropTypes.string,
};

export default FileDisplay;