import React, { useEffect, useState, useContext } from "react";
import get from 'lodash/get';
import { useSelector } from "react-redux";
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/hissitaito-logo-1@3x.png";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { AdminMenu, PropertyManagerMenu, SupplierMenu, Languages } from '../../utils/constants';
import { Context } from "../LanguageWrapper/LanguageWrapper";

const Header = (props) => {
  const context = useContext(Context);

  const [showMenu, setShowMenu] = useState(false);
  const [menuItem, setMenuItem] = useState([]);
  const [profileShowMenu, setProfileShowMenu] = useState(false);
  const [language, setLanguage] = useState([context.locale]);
  const history = useHistory();
  const userId = sessionStorage.getItem('user_id');
	const name = useSelector(state => get(state, `entities.users.${userId}.name`));
  const role_id = useSelector(state => get(state, `entities.users.${userId}.roles.role_id`));

  useEffect(() => {
    if(role_id) {
      setMenuItem(role_id === 2 ? PropertyManagerMenu : (role_id === 3 ? SupplierMenu: AdminMenu));
    }
  },[role_id]);

  const handleClick = (e) => {
    context.selectLanguage(e.key);
    setLanguage([e.key])
  }

  const manageMenu = (
    <Menu>
      {
        menuItem.map(item => (
          <Menu.Item key={item.label}>
            <Link to={item.link}>{item.label}</Link>
          </Menu.Item>
        ))
      }
    </Menu>
  )

  const languageMenu = (
    <Menu onClick={handleClick} defaultSelectedKeys={language} selectedKeys={language}>
      {
        Languages.map(lang => (
          <Menu.Item key={lang.key}>
            <span>{lang.label}</span>
          </Menu.Item>
        ))
      }
    </Menu>
  );

  return (
    <header className="htHeader">
      <Link to="/" className="ht-logo">
        <img src={logo} className="ht-logo" alt="logo" />
      </Link>
      <div className="rightSideLogin">
        <ul className="">
          {
            (userId && role_id === 3) &&
            <li className="contactSupport">
              <span>Contact support</span> 
            </li>
          }
          {
            userId && 
              <li className="manage">
                <Dropdown overlay={manageMenu} trigger={['click']}>
                  <span onClick={e => e.preventDefault()}>Manage</span> 
                </Dropdown>
              </li>
          }
          
          <li className="lang">
            <Dropdown overlay={languageMenu} trigger={['click']}>
              <span onClick={e => e.preventDefault()}>{Languages.find(lang => lang.key === language[0])?.label} <DownOutlined /></span> 
            </Dropdown>
          </li>
          {
             userId && 
              <li
                className="profilePic"
                onClick={() => setProfileShowMenu(!profileShowMenu)}
              >
                <Avatar
                  style={{
                    backgroundColor: '#57b8ff', marginRight: 5,
                  }}
                  icon={<UserOutlined />}
                />
                <span>Welcome {name},</span> <span className="arrowBtn"></span>
                <div
                  className={
                    profileShowMenu ? "dropdownMenu" : "dropdownMenu display-none"
                  }
                  style={{ marginTop: "35px" }}
                >
                  <div
                    className="menuItem"
                    onClick={() => (
                      sessionStorage.removeItem("token"),sessionStorage.removeItem("user_id"), window.location = '/login'
                    )}
                  >
                    <a>Logout</a>
                  </div>
                </div>
              </li>
          }
          
        </ul>
      </div>
    </header>
  );
};

Header.propTypes = {};

export default Header;
