import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import FileUpload from "../../components/FileUpload";
import EuroInput from "../../components/EuroInput";
import { Link } from "react-router-dom";
import { Card, Radio, Space, Checkbox, message, Spin, Modal } from "antd";
import BidSummary from "./Summary";
import {
  EditOutlined,
  CheckOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useHistory } from "react-router";
import { domainUrl } from "../../helper";
import { genHeaders } from "../../components/Tender/Tender";
import ConfirmationBox from '../../components/ConfirmationBox';
import FileDisplay from "../../components/FileDisplay";

import "./bid.less";

const FULL = "full";
const PARTIAL = "partial";
const TOTAL = "total";
const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

export default function Bid(props) {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [tenderTitle, setTenderTitle] = useState("");
  const [bidType, setBidType] = useState(FULL);
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const { tenderId } = props.match.params;
  const [total, setTotal] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [fileToUploadList, setFileToUploadList] = useState([]);
  const [bidID, setBidID] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [showUploadConfirmationModal, setShowUploadConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const verifyBidPlacement = () => {
    let isInvalid = false;

    if (isNaN(total) || total <= 0) {
      message.error("Total has to be more than 0.");
      return true;
    }

    const isFalsy = (value) =>
      value.__bid_price__ === undefined ||
      isNaN(value.__bid_price__) ||
      value.__bid_price__ <= 0;

    o_loop: for (let asset of assets) {
      const predicate =
        bidType === FULL || (bidType === PARTIAL && asset.__bid_partial__);
      if (predicate && isFalsy(asset)) {
        message.error("Placed asset bid is not acceptable.");
        isInvalid = true;
        break;
      }

      if (predicate && asset?.__item_lvl_pricing__ && asset?.items?.length) {
        let sum = 0;
        console.log(asset.items);
        for (let item of asset.items) {
          if (isFalsy(item)) {
            console.log(item);
            message.error(`Placed item bid value is not acceptable`);
            isInvalid = true;
            break o_loop;
          } else {
            sum += item.__bid_price__;
          }
        }
        if (sum !== asset.__bid_price__) {
          message.error("Item sum should be equal to asset bid value");
          isInvalid = true;
          break;
        }
      }
    }
    // return true;
    return isInvalid;
  };

  const placeBid = async () => {
    if (verifyBidPlacement()) return;

    try {
      const user_id = sessionStorage.getItem("user_id");
      let userData = await axios.get(`/users/${user_id}`, genHeaders());
      userData = userData?.data?.data;
      if (userData?.type === "S") {
        const supplier_id = userData?.entities?.filter(
          (entity) => entity?.entity_type === "supplier"
        )[0]?.entity_id;

        //check for preexisting bids...and if present...delete them.
        //better alternative would be to patch preexisting bids...instead of deleting and creating a new one.
        let bidRes = await axios.get(
          `/bid/v1/tender/${tenderId}/bid?supplier_id=${supplier_id}`,
          genHeaders()
        );

        bidRes = bidRes?.data?.data?.result;

        // have this condn just in case 'supplier_id' query param above returns all results;
        bidRes = bidRes?.filter((el) => el.supplier_id === supplier_id);

        if (bidRes?.length) {
          const bidIds = bidRes?.map((el) =>
            axios.delete(`/bid/v1/bid/${el.bid_id}`, genHeaders())
          );
          await Promise.all(bidIds);
        }

        const createBid = await axios.post(
          `/bid/v1/bid/`,
          {
            tender_id: +tenderId,
            is_partial_bid: bidType === PARTIAL ? true : false,
            sub_total: +total,
          },
          genHeaders()
        );

        const bidID = createBid?.data?.data?.bid_id;

        setBidID(bidID);

        const assetsToConsider = assets?.filter((asset) => {
          if (bidType === FULL) return true;

          if (bidType === PARTIAL) {
            return asset.__bid_partial__ === true;
          }

          return false;
        });

        if (assetsToConsider?.length) {
          const assetsBid = await axios.post(
            `/bid/v1/bid/${bidID}/assets/bulk`,
            assetsToConsider?.map((asset) => ({
              tender_line_id: asset.tender_line_id,
              amount: +asset.__bid_price__,
            })),
            genHeaders()
          );

          const assetsBidIds = assetsBid?.data?.data?.map(
            (bid) => bid.bid_line_id
          );

          await Promise.all(
            assetsToConsider?.map((asset, index) => {
              if (asset?.items?.length && asset?.__item_lvl_pricing__) {
                return axios.post(
                  `/bid/v1/bid/assets/${assetsBidIds[index]}/items/bulk`,
                  asset.items.map((item) => ({
                    tender_line_item_id: item.tender_line_item_id,
                    amount: +item.__bid_price__,
                  })),
                  genHeaders()
                );
              }
              return null;
            })
          );
        }

        setStep(step + 1);
      } else {
        throw new Error("User is not a part of supplier");
      }
    } catch (e) {
      console.error(e);
      message.error("Could not create bid.");
    }
  };

  const onAssetBlur = async (value, index) => {
    setAssets((c) => {
      const newAssets = c.slice();
      newAssets[index].__bid_price__ = +value;
      console.log(newAssets);
      return newAssets;
    });
  };

  const onItemBlur = (value, assetIndex, itemIndex) => {
    setAssets((c) => {
      const newAssets = c.slice();
      newAssets[assetIndex].items[itemIndex].__bid_price__ = +value;
      console.log(newAssets);
      return newAssets;
    });
  };

  const onPartial = (assetIndex, checked) => {
    const newAssets = assets.slice().map((el) => ({ ...el }));
    newAssets[assetIndex].__bid_partial__ = checked;
    setAssets(newAssets);
  };

  const onItemLvlPricing = (assetIndex) => {
    const newAssets = assets?.slice()?.map((el) => ({ ...el }));
    newAssets[assetIndex].__item_lvl_pricing__ = !newAssets[assetIndex]
      .__item_lvl_pricing__;
    setAssets(newAssets);
  };

  const onConfirmation = () => {
    setShowConfirmationModal(false);
    setShowModal(true);
  };

  const onUploadNext = () => {
    const filesToUpload = fileList.filter(doc => doc.documentId === null);
    if(filesToUpload.length > 0) {
       setShowUploadConfirmationModal(true);
    }
    else
      setStep((c) => c + 1);
  };

  const confirmSubmit = (e) => {
    setShowUploadConfirmationModal(false);
    setStep((c) => c + 1);
  }

  const declineSubmit = (e) => {
    setShowUploadConfirmationModal(false);
  }

  useEffect(() => {
    console.log("running effect");
    const newTotal = assets
      ?.filter((el) => {
        if (bidType === FULL) {
          return true;
        } else if (bidType === PARTIAL && el.__bid_partial__) {
          return true;
        } else {
          return false;
        }
      })
      ?.reduce((acc, asset) => acc + +asset.__bid_price__, 0);

    setTotal(newTotal ?? 0);
  }, [bidType, assets]);

  useEffect(() => {
    async function init() {
      try {
        //verify whether supplier has tender;
        const res = await axios.get(
          `${domainUrl}/tender/v2/tender/supplier?status=draft`,
          genHeaders()
        );
        const tenderList = res.data.data.result.map((el) => el.tender_id);
        console.log("list ->", tenderList);

        const tenderIndex = tenderList.indexOf(+tenderId);
        if (tenderIndex === -1) {
          console.log("NOT FOUND");
          history.goBack();
          return;
        }

        const tenderTitle = res.data.data.result[tenderIndex].title;
        const assetInfo = await axios.get(
          `/tender/v1/tender/${tenderId}/asset`,
          genHeaders()
        );
        console.log(assetInfo);
        setLoading(false);
        if (assetInfo.data.success) {
          const addBidInfo = assetInfo.data.data.assets.map((asset) => ({
            __bid_partial__: false,
            __bid_price__: 0,
            __item_lvl_pricing__: false,
            ...asset,
            items: asset?.items?.map((item) => ({
              __bid_price__: 0,
              ...item,
            })),
          }));
          setAssets(addBidInfo);
          setTenderTitle(tenderTitle);
          console.log(addBidInfo);
        } else {
          setLoading(false);
          message.error(assetInfo.data.message);
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
        message.error(e?.message);
      }
    }

    init();
  }, []);

  console.log(fileList);

  return (
    <div className="__Bid__">
      <Header />
      <div className="mainContainer">
        <div className="innerContainer">
          {loading ? (
            <div style={{ height: 500 }} className="d-flex j-center">
              <Spin style={{ margin: "auto" }}></Spin>
            </div>
          ) : (
            <>
              {tenderTitle && (
                <div className="hedding">
                  <h2>{tenderTitle}</h2>
                </div>
              )}
              {step !== 3 && (
                <ul className="tenderHeader">
                  <li className={step >= 1 ? "active" : null}>Place Bids</li>
                  <li className={step >= 2 ? "active" : null}>Upload files</li>
                </ul>
              )}
              {step === 1 && (
                <>
                  <div className="price-root">
                    {assets?.map((o, key) => {
                      return (
                        <div key={key} style={{ margin: "20px 0" }}>
                          <div className="fieldcontainer">
                            <h3>{o.asset_name || o.line_text}</h3>
                            <div className="fieldcontainer__inputcontainer">
                              <EuroInput
                                disabled={bidType === TOTAL}
                                initialValue={o.__bid_price__}
                                onBlur={(e) => onAssetBlur(e.target.value, key)}
                                hide={ bidType === TOTAL }
                              />
                              {bidType === PARTIAL && (
                                <Checkbox
                                  style={{
                                    position: "absolute",
                                    right: "-25px",
                                  }}
                                  checked={o.__bid_partial__}
                                  onChange={(e) =>
                                    onPartial(key, e.target.checked)
                                  }
                                />
                              )}
                            </div>
                          </div>

                          {o?.__item_lvl_pricing__ ? (
                            <>
                              <Space onClick={() => onItemLvlPricing(key)}>
                                <MinusCircleOutlined />
                                Enter asset level pricing alone
                              </Space>
                              {o?.items?.map((item, itemIndex) => (
                                <div
                                  key={itemIndex}
                                  style={{ margin: "10px 0" }}
                                >
                                  <div className="fieldcontainer">
                                    <h3 className="item">{item?.item_text}</h3>
                                    <EuroInput
                                      disabled={bidType === TOTAL}
                                      initialValue={item.__bid_price__}
                                      onBlur={(e) =>
                                        onItemBlur(
                                          e.target.value,
                                          key,
                                          itemIndex
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            o?.items?.length && (
                              <Space onClick={() => onItemLvlPricing(key)}>
                                <PlusCircleOutlined />
                                Enter item level pricing.
                              </Space>
                            )
                          )}
                        </div>
                      );
                    })}

                    <div className="fieldcontainer">
                      <h3>Total</h3>
                      <EuroInput
                        value={total}
                        onChange={(e) => setTotal(e.target.value)}
                        disabled={bidType !== TOTAL}
                      />
                    </div>
                  </div>

                  <Radio.Group
                    className="radiogp"
                    onChange={(e) => setBidType(e.target.value)}
                    value={bidType}
                  >
                    <Radio value={FULL}>Full Bid</Radio>
                    <Radio value={PARTIAL}>Partial Bid</Radio>
                    <Radio value={TOTAL}>Total Bid</Radio>
                  </Radio.Group>

                  <div className="d-flex j-space">
                    <div className="btn" onClick={() => history.goBack()}>
                      Back
                    </div>
                    <div onClick={placeBid} className="btn">
                      Next
                    </div>
                  </div>
                </>
              )}
              {step == 2 && (
                <>
                  <FileUpload
                    filePostURL={FILE_POST_URL}
                    showTitle={false}
                    entityType={"B"}
                    entityID={bidID}
                    onFilesStatusChange={(e) => setFileToUploadList(e)}
                    onFilesUploadStatusChange={(e) => setFileList(e)}
                  />

                  <FileDisplay entityType={'B'} fileList={fileList} entityId={bidID} hideTitle={false} deleteFiles={true}/>

                  <div className="d-flex j-space">
                    <div
                      className="btn"
                      onClick={() => {
                        setStep((c) => c - 1);
                      }}
                    >
                      Back
                    </div>
                    <div onClick={onUploadNext} className="btn">
                      Next
                    </div>
                  </div>
                  <ConfirmationBox showConfirmationBox={showUploadConfirmationModal} message={'You have added files but not uploaded. Proceed without uploading?' } confirmSubmit={confirmSubmit} declineSubmit={declineSubmit} />
                </>
              )}
              {step == 3 && (
                <>
                  <BidSummary
                    showEdit
                    bidId={bidID}
                    onFileEdit={() => setStep(step - 1)}
                    onBidPricingEdit={() => setStep(step - 2)}
                  />
                  <div className="d-flex j-space">
                    <div
                      className="btn"
                      onClick={() => {
                        setStep(step - 1);
                      }}
                    >
                      Back
                    </div>
                    <div
                      className="btn"
                      type="primary"
                      onClick={() => setShowConfirmationModal(true)}
                    >
                      Submit Bid
                    </div>
                  </div>
                </>
              )}
              <Modal
                visible={showConfirmationModal}
                closable={false}
                footer={null}
              >
                <h2 style={{ textAlign: "center" }}>
                  Do you really want to submit this bid?
                </h2>
                <div className="d-flex j-center f-col">
                  <div className="btn" onClick={onConfirmation}>
                    Yes
                  </div>
                  <div
                    className="btn"
                    onClick={() => {
                      setShowConfirmationModal(false);
                    }}
                  >
                    No
                  </div>
                </div>
              </Modal>
              <Modal visible={showModal} closable={false} footer={null}>
                <div className="d-flex j-center f-col">
                  <div className="m-auto">
                    <CheckOutlined style={{ fontSize: 50, color: "#4caf50" }} />
                  </div>
                  <p className="m-auto" style={{ fontSize: 18 }}>
                    Bid Placed Successfully
                  </p>
                  <div className="d-flex j-center">
                    <Link className="btn" to="/">
                      Goto dashboard
                    </Link>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
