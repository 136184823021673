import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from 'lodash/get';
import { Spin, Modal, Form, Input, Button, message, Row, Col, Avatar } from 'antd';
import { FormattedMessage } from "react-intl";
import moment from 'moment'; 
import { fetchMessageCommunicationSendbox,
         fetchMessageCommunicationInbox,
         createMessageCommunication } from "../../actions/messages";

const MessageReplyCard = ({hideModal, modalOpen, tenderId, tenderTitle, receiverId, messageId, markAsReplied}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [visible, setVisible] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [prevMsgs, setPrevMsgs] = useState([]);

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
      setVisible(modalOpen);
  }, [modalOpen])

  useEffect(() => {
      if(tenderId)
        getTenderMsgs();
  }, [tenderId]);

  const userId = sessionStorage.getItem('user_id');
  //const user = useSelector(state => get(state, `entities.users.${userId}`));

  const getTenderMsgs = async() => {
      const receivedResponse = await dispatch(fetchMessageCommunicationInbox({limit: 10, order_by: 'created_at', sort_by: 'ASC', entity_id: tenderId }));
      const sendResponse = await dispatch(fetchMessageCommunicationSendbox({limit: 10, order_by: 'created_at', sort_by: 'ASC', entity_id: tenderId }));
      const msgsReceived = receivedResponse?.payload?.data?.result;
      const msgsSent = sendResponse?.payload?.data?.result;
      const allMsgs = msgsReceived.concat(msgsSent ?? []);
      let sortedArray = allMsgs.length > 1 ? await sortMsgs(allMsgs) : allMsgs;
      setPrevMsgs(sortedArray);
  }

  const sortMsgs = async(array) => {
      const sortedArray = array.sort(function(a,b){
        return new Date(a.created_at) - new Date(b.created_at);
      });
      return sortedArray;
  }

  const onFinish = async(values) => {
    setConfirmLoading(true);
    const data = {
      subject: tenderTitle,
      body: values.msg,
      entity_type: "tenders",
      entity_id: +tenderId,
      receiver_id: +receiverId,
      meta: {},
    };

    const response = await dispatch(createMessageCommunication(data));
    if(response.error) {
      message.error('Unable to send messages')
    }
    else {
      message.success('Answer send successfully');
    }
    form.resetFields();
    setConfirmLoading(false);
    setVisible(false);
    markAsReplied();
    hideModal();
  };

  const handleCancel = () => {
    setVisible(false);
    hideModal();
  };

  return (
      <Modal
        title={tenderTitle}
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        className="msgModal"
      >
      {
          prevMsgs?.map((msg,index) => (
              <Col key={index} sm={18} offset={ msg.sender_id !== +userId ? 0 : 6} className="msgBorder">
                {
                    msg.sender_id !== +userId ? (
                        <>
                            <Row className="msgWrapper rcvrMsg">
                                <Col>
                                    <div className="profilePic">
                                        <Avatar className="msgAvatar">{msg?.sender_meta?.name.substring(0,1)}</Avatar>
                                    </div>
                                </Col>
                                <Col>
                                    <p>{msg.body}</p>
                                </Col>
                            </Row>
                            <Row className="timeWrapper rcvrMsg">
                                <p>{moment(msg.created_at).format('DD-MM-YY')}{' '}{moment(msg.created_at).format('HH:mm')}</p>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row className="msgWrapper userMsg">
                                <Col>
                                    <p>{msg.body}</p>
                                </Col>
                                <Col>
                                    <div className="profilePic">
                                        <Avatar className="msgAvatar">{msg?.sender_meta?.name.substring(0,1)}</Avatar>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="timeWrapper userMsg">
                                <p>{moment(msg.created_at).format('DD-MM-YY')}{' '}{moment(msg.created_at).format('HH.mm')}</p>
                            </Row>
                        </>
                    
                    )
                }
            </Col>
          ))
      }
        <Form
          className="msgForm"
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
        >
        <Row>
            <Col sm={19}>
          <Form.Item
            name="msg"
            rules={[
              {
                required: true,
                message: <FormattedMessage id='error.msg' />,
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} showCount maxLength={300}/>
          </Form.Item>
          </Col>
          <div className="manage_form" >
            <Button className="btn" type="primary" htmlType="submit">
              <FormattedMessage id={'send'} />
            </Button>
          </div>
          </Row>
          </Form>
      </Modal>
  )
}

export default MessageReplyCard;