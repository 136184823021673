module.exports = {
    'name': 'Title',
    'closing_date': 'Closing Date',
    'bids': 'Bids',
    'status': 'Status',
    'created_by': 'Created By',
    'awarded_to': 'Awarded To',
    'tenders': 'Tenders',
    'add_new_tender': 'Add New Tender',
    'view_all_tender': 'View All Tenders',
    'published_tenders': 'Published Tenders',
    'published': 'Published',
    'draft_tenders': 'Draft Tenders',
    'draft': 'Draft',
    'pre_tenders': 'Pre Tenders',
    'awarded_tenders': 'Awarded Tenders',
    'show_more': 'Show More',
    'show_less': 'Show Less',
    'new': 'New',
    'tenders_submitted_bids': 'Tenders - Submitted Bids',
    'new_tenders': 'New Tenders',
    'add_new_pre_tender': 'Add New Pre Tender',
    'submitted': 'Submitted',
    'awarded': 'Awarded',

    //to add
    'supplier_registration_form': 'Supplier Registration Form',
    'company_name': 'Company Name',
    'business_id': 'Business ID',
    'main_contact_person': 'Main Contact Person',
    'email': 'Email',
    'mobile_number': 'Mobile Number',
    'alternate_mobile_number': 'Alternate Mobile Number',
    'full_address': 'Full Address',
    'pin_code': 'Postal Code',
    'cities_operating': 'Cities Operating',
    'choose_industry_type': 'Choose Service Type',
    'error.name': 'Please input your Name',
    'error.business_id': 'Please input your Business ID',
    'error.email': 'Please input your Email',
    'error.invalid_email': 'The input is not valid E-mail',
    'error.mobile_number': 'Please input your Mobile Number',
    'error.invalid_mobile_number': 'The input is not valid Phone number',
    'error.address': 'Please input your address',
    'error.pin_code': 'Please input your Postal Code',
    'error.invalid_pin_code': 'The input is not valid Postal Code',
    'error.city': 'Please Select the City',
    'error.service_type': 'Please Select your Service Type',
    'error.supplier_name': 'Please Enter Supplier Name',
    'error.msg': 'Please enter your question!',
    'error.organization': 'Please enter organization',
    'error.company_name': 'Please enter company name',
    'city': 'City',
    'submit': 'Submit',
    'submit_request': 'Thank You For Submitting Your Request',
    'response_back': 'We will evaluate your request and get back to you shortly.',
    'ok': 'Ok',
    'invalid_code': 'The supplied code is Invalid',
    'organization': 'Organization',
    'company': 'Company',
    'last_updated': 'Last Updated',
    'awarded_date': 'Awarded Date',
    'completion_date': 'Completion Date',
    'date_submitted': 'Date Submitted',
    'published_date': 'Published Date',
    'delete_tender_msg': 'Are you sure you want to delete this tender?',
    'supplier_details': 'Supplier Details',
    'label_name': 'Name',
    'address': 'Address',
    'country': 'Country',
    'industry_type': 'Service Type',
    'supplier_rating': 'Supplier Rating',
    'mark_favourite': 'Mark as favourite',
    'deactivate': 'Deactivate',
    'save': 'Save',
    'cancel': 'Cancel',
    'send_question': 'Send Question To Admin',
    'ask_question': 'Ask Questions',
    'send': 'Send',
    'message': 'Message',
    'edit_company_details': 'Edit Company Details',
    'add_company_details': 'Enter Company Details',
    'company_details': 'Company Details',
    'select_city': 'Select City',
    'select_organization': 'Select Organization',
    'default_emails': 'Default Emails',
    'default_email': 'Default Email',
    
    'tender.choose_tender_type.info': 'In this step you can choose what do you want to tender. By selecting “Repair” option, you can tender for various repairs, such as the repair of spare parts. By selecting the “Other” option, you can tender for e.g. cleaning, contracts, inspections and the like.',
    'tender.choose_company.info': 'Select here to whom you are creating the tender. Use the search functions, if needed. With this you can also create a tender for your own purchases.',
    'tender.tender_details.info': 'Add the title of the tender here. You can also provide additional information on the tender, if you wish.',
    'tender.choose_suppliers.info': 'Select the suppliers to whom you want to send this invitation to tender. If the supplier you want is not listed, you can send an invitation to your suppliers by adding an email address one at a time in the text box at the bottom of the page. After the supplier logs in, you will find them in the supplier list in the future.',
    'tender.asset_details.info': 'Create a tender by assets and items. For example, the asset may be an apartment 123 and the item may be a task or the object of renovation, e.g. the replacement of a toilet bowl. This entire step can be skipped, if the request to tender added in the next step. Contains all the necessary information.',
    'tender.file.info': 'Add possible attachments, such as offer request.',
    'tender.closing_date.info': 'Choose until when do you want to receive offers, as well as how many reminders do you want to send about the closing tender to those suppliers who have not yet responded to the tender with their own offer.',
    'tender.additional_info.info': 'Add any additional information related to the tender here. For example, you can mention where the keys can be picked up or what the door code is.',
    'tender.summary.info': 'From the summary, check that all the information and attachments are correct. You can navigate to any step and edit the information, if needed.'
}