import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import Moment from 'react-moment';
import { Typography, Tooltip, Table } from 'antd';
import { useHistory } from 'react-router-dom';
const { Paragraph } = Typography;

const ReportAsset = ({data, onVaultSelect}) => {
    const history = useHistory();
	const [reportData,setReportData] = useState([]);

	useEffect(() => {
		if(data) {
			const sortedData = data.sort(function(a,b){
				return new Date(b.inspection_date) - new Date(a.inspection_date);
			  });
			setReportData(sortedData);
		}
	},[data]);

    const columns = [
        {
			dataIndex: "inspection_date",
			title: "Report Date",
            key: "inspection_date",
			sorter: (a, b) => moment(a.inspection_date) - moment(b.inspection_date),
            render: (value, tableMeta, updateValue) => (
                <Moment format="DD-MM-YYYY">
                    {value}
                </Moment>
            ),
		},
        {
			dataIndex: "inspected_by",
			title: "Reported By",
            key: "inspected_by",
		},
		{
			dataIndex: "inspection_id",
			title: "View",
            key: "asset_id",
			width: 100,
			render: (value) => (
				<div
					className="viewIcon"
					onClick={() => history.push(`/inspection-report/${value}`)}
				>
					View
				</div>
			),
		},
	];

	return (
		<>
            <Table
              className="ant-table-root table-inspection"
			  scroll={{ x: 0, y: '40vh' }}
              columns={columns}
              dataSource={reportData}
              bordered
              rowKey='inspection_id'
            />
        </>
    )
};

ReportAsset.propTypes = {
    
  };

export default ReportAsset;