import React, { useState, useEffect } from "react";
import "./ConfirmTender.less";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useTender } from "../PreTenderSteps";
import { submitPreTender } from "../../../actions/pre-tender";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { sendEmailCommunication } from "../../../actions/communication";

const ConfirmTender = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { preTenderDetails, onStepChange: move, setConfirmTenderPage } = useTender();
  const [error, setError] = useState(preTenderDetails.is_published === 0);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    setConfirmTenderPage(true);
  }, [])

  // useEffect(() => {
  //   setError(false);
  //   setIsLoading(true);
  //   const publish = async() => {
  //     let putResponse = await dispatch(submitPreTender({
  //       state: parseInt(preTenderDetails.state) > 7 ? preTenderDetails.state : '7',
  //       is_submitted: true,
  //     }, preTenderDetails?.pretender_id));
  
  //     let editedPreTender = putResponse.payload?.entities?.preTender[preTenderDetails?.pretender_id]
  //     if (editedPreTender?.pretender_id) {
  //       //move(1);
  //       await dispatch(sendEmailCommunication('PRETENDER_SUBMISSION', {pretender_id: +preTenderDetails?.pretender_id}));
  //       setIsLoading(false);
  //     }
  //     else {
  //       setError(true);
  //     }
  //   }
  //   publish();
  // }, [preTenderDetails]);

  let content;

  if (isLoading) {
    content = <LoadingOutlined style={{ fontSize: "100px" }} />;
  } else {
    if (error) {
      content = (
        <>
          <CloseOutlined style={{ color: "red", fontSize: "100px" }} />
          <h2>Unable to publish tender.</h2>
        </>
      );
    } else {
      content = (
        <>
          <CheckOutlined style={{ color: "green", fontSize: "100px" }} />
          <h2>Tender has been published successfully.</h2>
          <div className="btn" onClick={() => history.push('/')}>
            Goto Dashboard.
          </div>
        </>
      );
    }
  }
  return (
    <div className="__ConfirmTender__">
      <div className="parentBlock2">
        <div className="confirm">{content}</div>
      </div>
    </div>
  );
};

export default ConfirmTender;
