import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from 'lodash/get';
import { Table } from 'antd';
import Header from "../Header";
import "./Company.css";
import axios from "axios";
import AddModal from "./AddModal";
import { domainUrl } from "../../helper";
import ConfirmationBox from '../ConfirmationBox';
import { compNames, getFilterValues } from '../../utils';
import { fetchCompanies } from '../../actions/company';

const Company = () => {
  const dispatch = useDispatch();

  const role_id = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));
  const login_org = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.entities`));

  const companies = useSelector(state => Object.values(get(state, `entities.companies`) || {}));

  const [openCompanyFormModel, setOpenCompanyFormModel] = useState({
    isOpen: false,
    isReadOnly: false,
  });
  const [company, setCompany] = useState();
  //const [companies, setCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10
  });
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deleteComp, setDeleteComp] = useState({});

  const columns = [
    {
      dataIndex: "company_name",
      title: "Company",
      filters: getFilterValues("company_name", companies),
      onFilter: (value, record) => record.company_name.indexOf(value) === 0,
      sorter: {
        compare: (a, b) => {
          return compNames(a.company_name, b.company_name);
        },
      },
    },
    {
      dataIndex: "organization_name",
      title: "Organization",
      filters: getFilterValues('organization_name', companies),
      onFilter: (value, record) => record.organization_name.indexOf(value) === 0,
      sorter: {
        compare: (a, b) => {
          return compNames(a.organization_name, b.organization_name);
        },
      },
    },
    {
      dataIndex: "company_id",
      title: "View",
      render: (value) => (
        <div className="viewIcon" onClick={() => openCompanyForm(value, { readOnly: true })}>View</div>
      ),
    },
    {
      dataIndex: "company_id",
      title: "Modify",
      render: (value, tableMeta, updateValue) => (
        <div
          className="btn-primary"
          onClick={() => openCompanyForm(value, { readOnly: false })}
        >
          Modify
        </div>
      ),
    },
    {
      dataIndex: "company_id",
      title: "Delete",
      render: (value, tableMeta, updateValue) => (
        <div className="deleteIcon" onClick={() => deleteCompany(value)}>
          Delete
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAllCompany();
  }, []);
  const getAllCompany = async () => {
    let params = {
      limit: 1000
    }
    if(role_id === 2) {
      params.organization_id = parseInt(login_org[0].entity_id)
    }
    await dispatch(fetchCompanies(params));
  };

  const getSelectedCompany = (company_id) => {
    return companies.find((ele) => ele.company_id === company_id);
  };

  const openCompanyForm = (company_id, opts) => {
    let selected = getSelectedCompany(company_id);
    setOpenCompanyFormModel({ isOpen: true, isReadOnly: opts?.readOnly });
    if(role_id === 2) {
      if(!selected)
        selected = {};
      selected.organization_id = parseInt(login_org[0].entity_id)
    }
    setCompany(selected);
  };

  const closeCompanyForm = () => {
    setOpenCompanyFormModel({ isOpen: false });
    setCompany();
  };

  const addCompany = async (data) => {
    const headers = {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    if (data?.company_id) {
      //modify current organization;
      const { company_id, ...rest } = data;
      console.log(rest);

      const res = await axios.put(
        `${domainUrl}/masterservicenew/master/company/${company_id}`,
        { ...rest },
        { headers }
      );
      console.log(res);

      getAllCompany();
      closeCompanyForm();
      return;
    }

    //create new organization
    const body = {
      ...data,
      country_id: +data.country_id,
      city_id: +data.city_id,
    };
    const res = await axios.post(
      `${domainUrl}/masterservicenew/master/company`,
      body,
      { headers }
    );
    console.log(res);

    getAllCompany();
    closeCompanyForm();
  };

  const deleteCompany = (id) => {
    setDeleteComp(id);
    setShowConfirmationBox(true);
  };

  const declineSubmit = () => {
    setShowConfirmationBox(false);
    setDeleteComp({});
  }

  const confirmDelete = async () => {
    const res = await axios.delete(
      `${domainUrl}/masterservicenew/master/company/${deleteComp}`,
      {
        headers: {
          client_code: "ht_front",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    getAllCompany();
    setShowConfirmationBox(false);
    setDeleteComp({});
  };

  return (
    <>
      <Header />
      <div className="mainContainer">
        <div className="innerContainer">
        <h2 className="title">Companies</h2>
          <Table
              className="ant-table-root"
              scroll={{ x: 0, y: '40vh' }}
              columns={columns}
              dataSource={companies}
              pagination={pagination}
              bordered
              rowKey='company_id'
            />

          <div className="button1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.701"
              height="29.701"
              viewBox="0 0 29.701 29.701"
            >
              <path
                fill="#fff"
                d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                transform="translate(-1 -1)"
              />
            </svg>
            <span onClick={() => openCompanyForm(null, { readOnly: false })}>
              {" "}
              Add New
            </span>
          </div>
        </div>
      </div>

      {openCompanyFormModel?.isOpen && (
        <AddModal
          readOnly={openCompanyFormModel?.isReadOnly}
          data={company}
          role_id={role_id}
          onClose={closeCompanyForm}
          onSubmit={addCompany}
        />
      )}
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={'Are you sure you want to delete this company?' } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
   
    </>
  );
};

export default Company;