import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl, domainUrl } from "../../helper";
import "./AddModal.css";

const AddModal = (props) => {
  const [company, setCompany] = React.useState(props.data);
  const [isReadOnly, setIsReadOnly] = useState(props.readOnly);
  const [errorMessage, setErrorMessage] = React.useState({});
  const [organization, setOrganization] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [cities, setCities] = React.useState([]);
console.log(props.data);
  function handleInputChange(event) {
    const data = {
      ...company,
      [event.target.name]:
        event.target.name === "organization_id" ||
        event.target.name == "city_id"
          ? +event?.target?.value
          : event.target.value,
    };
    setCompany(data);
    setErrorMessage({});
  }

  useEffect(() => {
    if(props.role_id !== 2)
      getOrganization();
    getAllCountries();
    company && company.country_id && getAllCities(company.country_id);
  }, []);

  const getOrganization = () => {
    axios
      .get(`${domainUrl}/masterservicenew/master/organization?`, {
        headers: {
          token: sessionStorage.getItem("token"),
          client_code: "ht_front",
        },
      })
      .then((res) => {
        let data = res.data.data;
        setOrganization(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCountries = () => {
    axios
      .get(
        `${domainUrl}/masterservicenew/master/country?limit=100&offset=0&order_by=created_at&sort_by=ASC`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        let data = res.data.data;
        setCountries(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCities = (countryId) => {
    axios
      .get(
        `${domainUrl}/masterservicenew/master/city?limit=100&country_id=${countryId}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        let data = res.data.data;
        setCities(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectCountry = (e) => {
    const countryId = +e?.target?.value || null;
    setCompany({ ...company, country_id: countryId });
    countryId && getAllCities(countryId);
  };

  const onAddNewEmail = () => {
    const currEmailAccounts = company?.default_emails ?? [];

    setCompany({
      ...company,
      default_emails: [...currEmailAccounts, ""],
    });
    console.log(currEmailAccounts);
  };

  const onDeleteEmail = (index) => {
    const filteredEmailAccounts = company?.default_emails?.filter(
      (_, i) => i !== index
    );
    setCompany({
      ...company,
      default_emails: [...filteredEmailAccounts],
    });
  };

  const onDefaultEmailChange = (index, e) => {
    const _ = company?.default_emails?.slice() ?? [];
    _[index] = e.target.value;

    setCompany({ ...company, default_emails: _ });
  };

  function handleSubmit() {
    if (!company) {
      setErrorMessage({
        org_id: "Please Select Organization",
        company_name: "Please Enter Name",
        business_id: "Please Enter Business ID",
        email: "Please Enter Email",
        mobile: "Please Enter Mobile",
        address: "Please Enter Address",
        country: "Please Select Country",
        city: "Please Select City",
        pincode: "Please Enter Postal code",
      });
    } else if (!company.organization_id || company.organization_id === "") {
      setErrorMessage({
        org_id: "Please Select Organization",
      });
    } else if (!company.company_name || company.company_name === "") {
      setErrorMessage({
        company_name: "Please Enter Name",
      });
    } else if (!company.business_id || company.business_id === "") {
      setErrorMessage({
        business_id: "Please Enter Business ID",
      });
    } else if (!company.email || company.email === "") {
      setErrorMessage({
        email: "Please Enter Email",
      });
    } else if (
      company.email &&
      company.email !== "" &&
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        company.email
      )
    ) {
      setErrorMessage({
        email: "Please Enter Valid Email",
      });
    } else if (!company.contact || company.contact === "") {
      setErrorMessage({
        mobile: "Please Enter Mobile",
      });
    } else if (!company.address || company.address === "") {
      setErrorMessage({
        address: "Please Enter Address",
      });
    } else if (!company.country_id || company.country_id === "") {
      setErrorMessage({
        country: "Please Enter Country",
      });
    } else if (!company.city_id || company.city_id === "") {
      setErrorMessage({
        city: "Please Enter City",
      });
    } else if (!company.pincode || company.pincode === "") {
      setErrorMessage({
        pincode: "Please Enter Postal code",
      });
    } else if (
      company &&
      company.organization_id !== "" &&
      company.company_name !== "" &&
      company.business_id !== "" &&
      company.email !== "" &&
      company.contact !== "" &&
      company.address !== "" &&
      company.country !== "" &&
      company.city !== "" &&
      company.pincode !== ""
    ) {
      props.onSubmit(company);
    } else {
      setErrorMessage({
        message: "Some issue occurred please try again",
      });
    }
  }

  return (
    <div className="companyAddModal">
      <div className="pageOverlay">
        <div className="modalParent">
          <div className="closeBtn2" onClick={() => props.onClose()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48.408"
              height="48.408"
              viewBox="0 0 48.408 48.408"
            >
              <g id="Group_2114" transform="translate(-198.987 -621.867)">
                <g id="Group_1542" transform="translate(198.987 621.867)">
                  <path
                    id="Path_61"
                    d="M26.2 50.408A24.2 24.2 0 1 0 2 26.2a24.2 24.2 0 0 0 24.2 24.2zm0-44.951A20.746 20.746 0 1 1 5.458 26.2 20.746 20.746 0 0 1 26.2 5.458z"
                    className="cls-1"
                    transform="translate(-2 -2)"
                  />
                  <path
                    id="Path_62"
                    d="M9.506 32.667a1.729 1.729 0 0 0 2.438 0l9.162-9.167 9.146 9.146a1.729 1.729 0 0 0 2.438-2.438l-9.146-9.146 9.146-9.142a1.729 1.729 0 0 0-2.438-2.438l-9.146 9.146-9.145-9.163A1.729 1.729 0 0 0 9.523 11.9l9.146 9.163-9.163 9.149a1.729 1.729 0 0 0 0 2.455z"
                    className="cls-1"
                    transform="translate(3.098 3.138)"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="modalOuterMain">
            <div className="modalOuter2">
              {errorMessage.message && (
                <div className="errorMessage">{errorMessage.message}</div>
              )}
              <h2 className="title">{!isReadOnly ? (company?.company_id ? 'Edit' : 'Enter') : ''} Company Details</h2>
              {props.role_id !== 2 && 
              
              <div className="inputFieldGroup">
                <label>Organization</label>
                <div className="inputText">
                  {isReadOnly ? (
                    <input
                      readOnly={isReadOnly}
                      className="inputFiled"
                      type="text"
                      name="Organization"
                      value={company?.organization_name ?? ""}
                    />
                  ) : (
                    <div className="costumSelect">
                      <select
                        name="organization_id"
                        id="slct"
                        className="inputFiled"
                        value={company ? company.organization_id : ""}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Organization</option>
                        {organization?.map((el, index) => (
                          <option key={index} value={el.organization_id}>
                            {el?.organization_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="errorMessage">{errorMessage.org_id}</div>
                </div>
              </div>
              }
              <div className="inputFieldGroup">
                <label>Name</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="company_name"
                    value={company ? company.company_name : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">
                    {errorMessage.company_name}
                  </div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Business ID</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="business_id"
                    value={company ? company.business_id : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.business_id}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Email</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="email"
                    name="email"
                    value={company ? company.email : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.email}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Mobile</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="contact"
                    value={company ? company.contact : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.mobile}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Address</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="address"
                    value={company ? company.address : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.address}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Country</label>
                <div className="inputText">
                  {isReadOnly ? (
                    <input
                      readOnly={isReadOnly}
                      className="inputFiled"
                      type="text"
                      name="Country"
                      value={company?.country_name ?? ""}
                    />
                  ) : (
                    <div className="costumSelect">
                      <select
                        name="country_id"
                        id="slct"
                        className="inputFiled"
                        value={company?.country_id ?? ""}
                        onChange={(e) => onSelectCountry(e)}
                      >
                        <option value="">Select Country</option>
                        {countries?.map((el, index) => (
                          <option key={index} value={el.country_id}>
                            {el?.country_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="errorMessage">{errorMessage.country}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>City</label>
                <div className="inputText">
                  {isReadOnly ? (
                    <input
                      readOnly={isReadOnly}
                      className="inputFiled"
                      type="text"
                      name="City"
                      value={company?.city_name ?? ""}
                    />
                  ) : (
                    <div className="costumSelect">
                      <select
                        name="city_id"
                        id="slct"
                        className="inputFiled"
                        value={company?.city_id ?? ""}
                        onChange={handleInputChange}
                      >
                        <option value="">Select City</option>
                        {cities?.map((el, index) => (
                          <option key={index} value={el.city_id}>
                            {el?.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="errorMessage">{errorMessage.city}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Postal Code</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="pincode"
                    value={company ? company.pincode : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.pincode}</div>
                </div>
              </div>
              {isReadOnly && company?.default_emails?.length > 0 && (
                 <div className="inputFieldGroup emailGroup">
                 <label >Default Emails</label>
                 <div className="inputText">
                {company?.default_emails?.map((el, index) => (

                   <input
                     readOnly={isReadOnly}
                     className="inputFiled"
                     type="text"
                     value={el || ""}
                     name={"default_email"}
                   />
                   ))}
                 </div>
               </div>
                
              )}
            </div>
            {!isReadOnly && (
              <div className="modalOuter2">
                {company?.default_emails?.map((el, index) => (
                  <div key={index} className="inputFieldGroup">
                    {index === 0 && <label>Default Email</label>}
                    <div className="inputText ml-auto">
                      <input
                        className="inputField"
                        type="email"
                        name={"default_email"}
                        value={el || ""}
                        onChange={(e) => onDefaultEmailChange(index, e)}
                      />
                    </div>
                    <div
                      onClick={() => onDeleteEmail(index)}
                      className="deleteIcon"
                    >
                      Delete
                    </div>
                  </div>
                ))}

                <div className="inputFieldGroup">
                  <div className="button1 button2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29.701"
                      height="29.701"
                      viewBox="0 0 29.701 29.701"
                    >
                      <path
                        fill="#fff"
                        d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                        transform="translate(-1 -1)"
                      />
                    </svg>
                    <span onClick={onAddNewEmail}>Add Default Email</span>
                  </div>
                </div>

                <div className="buttonBlock">
                  <div
                    className="btn cancelBtn"
                    onClick={() => props.onClose()}
                  >
                    Cancel
                  </div>
                  <div className="btn" onClick={() => handleSubmit()}>
                    Save
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;