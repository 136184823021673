import React, { useState } from "react";
import "./AddModal.css";

const AddModal = (props) => {
  const [companyType, setCompanyType] = React.useState(props.data);
  const [errorMessage, setErrorMessage] = React.useState("");
  function handleInputChange(event) {
    const data = {
      ...companyType,
      [event.target.name]: event.target.value,
    };
    setCompanyType(data);
    setErrorMessage("");
  }
  function handleSubmit() {
    if (companyType && companyType.company_type_name !== "") {
      props.onSubmit(companyType);
    } else {
      setErrorMessage("Please Enter Name");
    }
  }

  return (
    <div className="pageOverlay">
      <div className="modalOuter">
        <div className="closeBtn" onClick={() => props.onClose()}>
          close
        </div>
        <div className="inputFieldGroup">
          <label>Name</label>
          <input
            className="inputFiled"
            type="text"
            name="company_type_name"
            placeholder=""
            value={companyType ? companyType.company_type_name : ""}
            onChange={handleInputChange}
          />
          <div className="errorMessage">{errorMessage}</div>
        </div>
        <div className="buttonBlock">
          <div className="btn cancelBtn" onClick={() => props.onClose()}>
            Cancel
          </div>
          <div className="btn" onClick={() => handleSubmit()}>
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
