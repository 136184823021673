import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";

import get from 'lodash/get';
import { FormattedMessage } from "react-intl";
import { Form, Input, Button, Checkbox, Rate, Modal, InputNumber, Select } from 'antd';
import { segKeyData } from '../../utils';

import { fetchServiceTypes } from '../../actions/services';
import { fetchCountries, fetchCities } from '../../actions/list';

const useStyles = makeStyles((theme) => ({
    closeBtn: {
        float: "right",
        cursor: "pointer",
      },
      group: {
          flex: .92,
      },
    inputFieldGroup: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        justifyContent: 'space-between',
    },
    buttonBlock: {
       display: 'flex',
       justifyContent: 'space-between',
       width: '94%',
       marginRight: '3%',
       justifyContent: 'center',
    }
}));

const SupplierDetails = ({ readOnly, isOpen, data, onClose, onSubmit, onDelete, allowRating }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [errorMessage, setErrorMessage] = useState({});
    const [supplierData, setSupplierData] = useState();
    const [serviceTypeData, setServiceTypeData] = useState([]);
    const [countries, setCountries] = useState([]);
    //const [cities, setCities] = useState([]);

    const [selectedServices, setSelectedServices] = useState({});

    const handleInputChange = (event) => {
    const data = {
      ...supplierData,
      [event.target.name]: event.target.value,
    };
    setSupplierData(data);
    setErrorMessage({});
  };

  const cities = useSelector(state => segKeyData('city_name', 'city_id', Object.values(get(state, `entities.cities`) || {})));

  //const getAllcities = useSelector(state => get(state, `entities.cities`));

   const services = useSelector(state => segKeyData('service_type_name', 'service_type_id', Object.values(get(state, `entities.services`) || {})));

   const getCities = async() => {
       await dispatch(fetchCities(1));
   }

   const getServiceTypes = async() => {
       await dispatch(fetchServiceTypes());
   }

   useEffect(() => {
     if(data) {
       data.service_cities_values = data.service_cities?.map(city => {
          return !readOnly ? city.city_id.toString() : city.city_name;
       })
       data.service_types_values = data.service_types?.map(service => {
          return service.service_type_id.toString();
       });
       data.contact = parseInt(data.contact);
       /* Have to get from backend for the city selected. For multiple cities and if different countries */
       data.country_name = 'Finland';
       data.country_id = 1;
       setSupplierData(data);
     }
   }, [data])

  useEffect(() => {
        getServiceTypes();
        if(!readOnly) {
            getAllCities(1);
        }
    }, [dispatch]);

    const getAllCities = async (countryId) => {
      const response = await dispatch(fetchCities(countryId));
      const cities = Object.values(response?.payload?.entities?.cities || []);
      //setCities(cities);
    };

  const setServices = () =>{
      let obj = {};
      data.service_types.map(service => obj[service.service_type_id] = true);
      setSelectedServices(obj);
      setServiceTypeData(data.service_types);

  }

  const onSelectCountry = (e) => {
    const countryId = +e?.target?.value || null;
    setSupplierData({ ...supplierData, country_id: countryId });
    countryId && getAllCities(countryId);
  };

   const handleSubmit = async(values) => {
      // let services = Object.keys(selectedServices).filter(serv => !selectedServices[serv]);
     values.service_cities = values.service_cities_values.map(item => {
            return {
                'city_id': item
            }
        })

        values.service_types = values.service_types_values.map(item => {
            return {
                'service_type_id': item
            }
        })
      values.is_favourite = values.is_favourite ? 1: 0;
      values.contact = values.contact.toString();
      values.pincode = values.pincode.toString();
      values.country_id = 1;
      onSubmit(values);
  }

  const handleMarkAsFavourite = () => {
      let data = supplierData;
    if(supplierData.supplier_id){
      data.is_favourite = data.is_favourite === 0 ? 1 : 0;
      setSupplierData(data);
      onSubmit(data);
    }
    else {
      setSupplierData({ ...supplierData, is_favourite: data.is_favourite === 1 ? 0 : 1 });
    }
  }

  const handleDelete = () => {
      onDelete(supplierData);
  }

  const handleChange = (event) => {
    setSelectedServices({ ...selectedServices, [event.target.name]: event.target.checked });
    setErrorMessage({});
  };

    return (

      <Modal
          visible={isOpen}
          onCancel={onClose}
          centered
          maskClosable={false}
          title={<FormattedMessage id={'supplier_details'} />}
          footer={null}
        >
        { 
          supplierData && 
           <Form
              name="supplier_form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
              initialValues={supplierData}
              onFinish={handleSubmit}
              className="manage_form"
            >
              <Form.Item
                label={<FormattedMessage id={'label_name'} />}
                name="supplier_name"
                rules={[{ required: true, message: <FormattedMessage id={'error.supplier_name'} /> }]}
              >
                <Input disabled={readOnly}/>
              </Form.Item>

              <Form.Item
                label={<FormattedMessage id={'address'} />}
                name="address"
                rules={[{ required: true, message: <FormattedMessage id={'error.address'} /> }]}
              >
                <Input disabled={readOnly}/>
              </Form.Item>

              <Form.Item
                label={<FormattedMessage id={'pin_code'} />}
                name="pincode"
                rules={[
                    {
                    pattern: new RegExp(/\d+/g),
                    message: <FormattedMessage id={'error.invalid_pin_code'} />,
                    },
                    { required: true, message: <FormattedMessage id={'error.pin_code'} /> }]}
                >
                    <Input disabled={readOnly}/>
              </Form.Item>

              <Form.Item
                label={<FormattedMessage id={'cities_operating'} />}
                name="service_cities_values"
                rules={[
                    { required: true, message: <FormattedMessage id={'error.city'} /> }]}
                >
                    <Select
                        mode="multiple"
                        disabled={readOnly}
                        style={{ width: '100%' }}
                        placeholder="Select Operating Cities"
                        notFoundContent={null}
                        optionFilterProp="city_name"
                        options={cities}
                    >
                    </Select>
                </Form.Item>

                <Form.Item
                label={<FormattedMessage id={'country'} />}
                name="country_name"
                >
                    <Input disabled/>
                </Form.Item>

                <Form.Item
                    label={<FormattedMessage id={'mobile_number'} />}
                    name="contact"
                    rules={[
                        {
                        type: 'number',
                        message: <FormattedMessage id={'error.invalid_mobile_number'} />,
                        },
                        { required: true, message: <FormattedMessage id={'error.mobile_number'} /> }]}
                    >
                        <InputNumber disabled={readOnly} style={{width: '100%'}}/>
                  </Form.Item>

                  <Form.Item
                    label={<FormattedMessage id={'email'} />}
                    name="email"
                    rules={[ {
                        type: 'email',
                        message: <FormattedMessage id={'error.invalid_email'} />,
                        },
                        { required: true, 
                        message: <FormattedMessage id={'error.email'} /> 
                        } ]}
                    >
                        <Input disabled={readOnly}/>
                    </Form.Item>

                    <Form.Item
                    label={<FormattedMessage id={'industry_type'} />}
                    name="service_types_values"
                    rules={[
                        { required: true, message: <FormattedMessage id={'error.service_type'} /> }]}
                    >
                        <Checkbox.Group
                          disabled={readOnly}
                          options={services}
                          name="service_type_name"
                        />
                    </Form.Item>
                    {
                      allowRating && 
                        <Form.Item
                          label={<FormattedMessage id={'supplier_rating'} />}
                          name="rating"
                          >
                              <Rate allowHalf disabled={readOnly}/>
                          </Form.Item>
                    }
                    
                    {
                      !readOnly && 
                        <Form.Item
                        name="is_favourite"
                        valuePropName="checked"
                        wrapperCol={{
                          offset: 8,
                          span: 14,
                        }}
                        >
                            <Checkbox disabled={readOnly}>
                              <FormattedMessage id={'mark_favourite'} />
                            </Checkbox>
                        </Form.Item>
                    }

              <div className={classes.buttonBlock} >
                 
                    <Button className="btn cancelBtn" type="secondary" onClick={onClose}>
                        <FormattedMessage id={'cancel'} />
                    </Button>
                    {!readOnly && (
                        <>
                      <Button className="btn" type="primary" htmlType="submit">
                        <FormattedMessage id={'save'} />
                      </Button>

                      {/* <div className="btn" onClick={() => handleMarkAsFavourite()}>
                        {supplierData.is_favourite === 1 ? 'Unmark As Favourite' : 'Mark As Favourite' }
                      </div> */}
                      {
                         (!readOnly && supplierData.supplier_id) && 
                           <Button type="primary" className="btn" onClick={() => handleDelete()}>
                            <FormattedMessage id={'deactivate'} />
                          </Button>
                      }
                      
                      </>
                    )}
                  </div>
          </Form>
          }
        </Modal>
       
    );
};

SupplierDetails.propTypes = {
  readOnly: PropTypes.bool,
  isOpen: PropTypes.bool,
  data: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SupplierDetails;