import React from "react";
import PropTypes from "prop-types";

const SettingHeadingLabel = (props) => {
  return <div className={props.className}>{props.label}</div>;
};

SettingHeadingLabel.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

export default SettingHeadingLabel;
