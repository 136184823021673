const initialState = { locale: 'en', auth: {}, user: {}};

const authReducer = function (state = initialState, action) {
  switch (action?.type) {
    case "FETCH_LOGIN_SUCCESS":
      return { ...state, auth: action.payload.data };
    case "FETCH_LOGIN_FAILURE":
      return { ...state, auth: action.payload.body };
    case "FETCH_USER_SUCCESS":
    //localStorage.setItem('auth', JSON.stringify(action.payload))
    // axios.defaults.headers.common['Authorization'] = 'bearer '+ action.payload.token;
    return { ...state, user: {...state?.user, ...action?.payload?.entities?.users} };
    case "UPDATE_LOCALE":
      localStorage.setItem("locale", action.payload)
      return { ...state, locale: action.payload };
    case "LOGOUT":
      localStorage.setItem('auth', null)
      // delete axios.defaults.headers.common['Authorization']
      return { locale: 'en', user: null };
    default:
      return state;
  }
};

export default authReducer;