import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const AddTender = (props) => {
  const history = useHistory();

  return (
    <>
      <div className="card">
        <h2><FormattedMessage id={'tenders'} /></h2>
        <div onClick={() => history.push("/tender")} className="addTenderBtn">
          <span className="btn-icon"></span> <span><FormattedMessage id={'add_new_tender'}/></span>
        </div>
        <div className="viewAll" onClick={() => history.push('/tenders')}><FormattedMessage id={'view_all_tender'}/></div>
      </div>
    </>
  );
};

export default AddTender;
