import React, { useState, useCallback, useEffect } from "react";
import { Form, Input, message, Select, Button, Divider, Col, Row, Space } from "antd";
import EuroIcon from '@material-ui/icons/Euro';
import { useTender } from "../TenderCreationSteps";
import get from 'lodash/get';
import { useSelector } from 'react-redux';

import "../AdditionalInfo/AdditionalInfo.css";
import "./EnterAssetDetails.css";
import { MinusCircleOutlined } from "@ant-design/icons";
import { fetchAssets } from "../../../actions/assets";
import { useDispatch } from "react-redux";
import { createTenderAsset, updateTenderAsset,deleteTenderAsset, createTenderAssetItem, deleteTenderAssetItem } from "../../../actions/tenderAssets";
import AssetItemForm from './AssetItemsForm';
import ToolTip from '../../ToolTip';
//TODO : Rewrite component : Instead of one state, use 2 to handle assets and items separately. will become easier to understand then

const EnterAssetDetails = ({modifyAssets,getTenderAssets, updateTenderData}) => {
  const dispatch = useDispatch();
  const { tenderDetails, onStepChange: move, modifyData, tenderModifiedDetails } = useTender();

  const [form] = Form.useForm();
  
  const [formValue, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [asset_names, setAssetNames] = useState([]);
  const [selectedAssetDb, setSelectedAssetDb] = useState([]);

   useEffect(() => {
     let assetAdded;
     if(tenderDetails.is_published === 0){
       tenderDetails?.assetsFormValues.map(item => {
           item.asset_name = item.line_text || item.asset_name;
           return item;
        })
        assetAdded = (!tenderDetails?.assetsFormValues || tenderDetails?.assetsFormValues?.length === 0) ? [ {asset_name: undefined, items: [] }] : tenderDetails?.assetsFormValues;
        if(tenderDetails?.assetsFormValues && tenderDetails?.assetsFormValues.length>0)
          setAssetNames(segKeyData('asset_name','asset_id',tenderDetails?.assetsFormValues));
      }
      else {
        tenderModifiedDetails?.assetsFormValues.map((item, index) => {
          item.asset_name = item.line_text || (Array.isArray(item.asset_name) && !isNaN(item?.asset_name[0]) ? tenderModifiedDetails?.selectedAssetDb[index].label : item.asset_name);
          return item;
       })
        assetAdded = (!tenderModifiedDetails?.assetsFormValues || tenderModifiedDetails?.assetsFormValues?.length === 0) ? [ {tenderModifiedDetails: undefined, items: [] }] : tenderModifiedDetails?.assetsFormValues;
        if(tenderModifiedDetails?.assetsFormValues && tenderModifiedDetails?.assetsFormValues.length>0)
          setAssetNames(segKeyData('asset_name','asset_id',tenderModifiedDetails?.assetsFormValues));
      }
      setFormValues({assets: assetAdded})
   },[]);

   tenderDetails.assetsFormValues = useSelector(state => Object.values(get(state, `entities.tenderAssets`) || {}).filter(asset => {
    return asset.tender_id === tenderDetails.tender_id
  }));

  let statetenderAssets = useSelector(state => Object.values(get(state, `entities.tenderAssets`) || {}).filter(asset => {
    return asset.tender_id === tenderDetails.tender_id
  }));

  const onBack = () => {
    move(-1, { assetsFormValues: form.getFieldsValue().assets, selectedAssetDb });
  };

  const onFinish = async (e) => {
    //return;
    if(tenderDetails.is_published !== 1) {
      //await deleteOldAssets();
    }
    try{
      if(e?.assets && e?.assets[0].asset_name) {
      let tenderAssets = [];
      const createAssetCalls = await Promise.all(
        e?.assets?.map(async (asset, index) => {
          let obj = Object.assign({}, asset);
          let data = {};
          if(Array.isArray(asset.asset_name))
             data = isNaN(parseInt(asset.asset_name[0])) ? {line_text: asset.asset_name[0]} : {asset_id: parseInt(asset.asset_name[0])};
          else
            data = asset;
            //data.asset_id = Array.isArray(asset.asset_name) && 
            if(tenderDetails.is_published !== 1) {
              let assetCall;
              
              if(!asset.tender_line_id){
                assetCall = await dispatch(createTenderAsset(data, tenderDetails.tender_id));
                let asset_line_id = assetCall.payload?.result[0];
                if(asset.items !== null)
                  await dispatch(createTenderAssetItem(asset.items, parseInt(asset_line_id)));
              }
              else {
               assetCall = await dispatch(updateTenderAsset(data, asset.tender_line_id));
                if(tenderDetails?.assetsFormValues[index]?.items != null ) {
                await Promise.all(
                  tenderDetails?.assetsFormValues[index]?.items?.map(async item => {
                    await dispatch(deleteTenderAssetItem(item.tender_line_item_id)); //update instead of delete
                  })
                )
                }
                await dispatch(createTenderAssetItem(asset.items, asset.tender_line_id));
              }
              obj.tender_line_id = assetCall.payload?.result[0];

            }
            // console.log(obj.asset_name, asset.asset_name[0])
            //   obj.asset_name = !Array.isArray(asset.asset_name) ? obj.asset_name : selectedAssetDb[index];
            //   console.log(obj.asset_name, selectedAssetDb, index)
            // if(selectedAssetDb[index]?.value)
            //   obj.asset_id = [selectedAssetDb[index]?.value]
            tenderAssets = e?.assets;
            statetenderAssets[asset?.tender_line_id] = [];
            await getTenderAssets();
        }));
    //  console.log(createAssetCalls);
      if(tenderDetails.is_published !== 1) {
        const status = await updateTenderData(
          {
            state: '5',
          }
        )
    }
      move(tenderDetails.is_published !== 1 ? 1 : 4, {assetsFormValues: form.getFieldsValue().assets, selectedAssetDb }, tenderDetails.is_published === 1);
  }
  else {
    if(tenderDetails.is_published !== 1) {
      const status = await updateTenderData(
        {
          state: '5',
        }
      )
  }
    move(tenderDetails.is_published !== 1 ? 1 : 4, {assetsFormValues: [], selectedAssetDb }, tenderDetails.is_published === 1);

  }
    }
    catch (e) {
      message.error("Unable to create asset(s).");
      console.error(e);
      setIsLoading(false);
    }
  // else {
  //   move(3, {assetsFormValues: e.assets, subtotal: e.subtotal });
  // }
  };

  // const deleteOldAssets = async () => {
  //   const getAllExistingAssets = await dispatch(fetchAssetsPreTender(tenderDetails?.tender_id))
  //   await Promise.all(
  //     Object.values(getAllExistingAssets.payload.entities.assets || [])?.map(async (asset) => {
  //       await dispatch(deleteAssetPreTender(asset.tender_line_id));
  //     }));
  // };

  const deleteAssetById = async(line_id) => {
    if(line_id)
      await dispatch(deleteTenderAsset(line_id));
  }

  const segKeyData = (key,valueKey, data) => {
    let keyArray = data.map(obj => { 
      return {label: obj[key], value: String(obj[valueKey])}});
      return keyArray.filter((value, index, self) => self.map(x => x.value).indexOf(value.value) == index)
  }

  const handleSearch = async (value) => {
    if(value) {
      const response = await dispatch(fetchAssets({limit: 1000, asset_name: value}));
      const assetData = Object.values(response?.payload?.entities?.assets || []);
      setAssetNames(segKeyData('asset_name','asset_id',assetData));
    }
    else {
      setAssetNames([]);
    }
  }

  const publishData = () => {
    move(0, {
      assetsFormValues: formValue.assets, subtotal: formValue.subtotal, selectedAssetDb
    },true);
    modifyData();
  }

  return (
    <div className="__AssetDetails__ preTenderAsset">
      <div className="parentBlock2">
        <div className="hedding">
          <h2>Asset Details <ToolTip text_id="tender.asset_details.info" placement="top" /></h2>
        </div>
        {
          formValue?.assets?.length > 0 && 
       
        <Form form={form} name="dynamic_form_nest_item" initialValues={formValue} onFinish={onFinish} autoComplete="off">
      <Form.List name="assets">
      
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <>
                <Row align="middle" key={key}>
                  {
                    fieldKey != 0 &&
                      <Divider />
                  }
                  
              <Col lg={10}>
                <Form.Item
                  {...restField}
                  label="Select Asset" 
                  labelAlign={'left'} 
                  labelCol={{span: 10}}
                  wrapperCol={{span: 15}}
                  colon={false}
                  name={[name, 'asset_name']}
                  fieldKey={[fieldKey, 'asset_name']}
                  // rules={[
                  //   {
                  //     required: true, message: 'Please add an asset'
                  //   },
                  // ]}
                >
                  <Select
                    showSearch
                    mode="tags"
                    className="preTender_asset"
                    placeholder="Enter Asset"
                    showArrow={false}
                    onSearch={handleSearch}
                    notFoundContent={null}
                    optionFilterProp="label"
                    options={asset_names}
                    onChange={(value,option) => {
                      if (value?.length > 1) {
                        // if you want only one element :).
                        value.pop();
                      }
                      else {
                        let assets = selectedAssetDb;
                        assets[fieldKey] = option[0]?.label ? option[0] : value[0];
                        setSelectedAssetDb(assets);
                      }
                     
                    }}
                  >
                  </Select>
                </Form.Item>
                </Col>
                <Col>
                <MinusCircleOutlined onClick={() => {deleteAssetById(formValue?.assets[fieldKey]?.tender_line_id); remove(name)}} />
                </Col>
                </Row>
                <Row className="assetItems">
                  <AssetItemForm fieldKey={key} />
                </Row>
                </>
            ))}
            <Form.Item>
              
            <div className="formGroup">
            <label className="leftCol"></label>
            <div  onClick={() => add()} className="addAssets">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64.413"
                height="64.413"
                viewBox="0 0 64.413 64.413"
              >
                <g
                  id="Icon_material-add-circle-outline"
                  data-name="Icon material-add-circle-outline"
                  transform="translate(0 0)"
                >
                  <path
                    id="Icon_material-add-circle-outline-2"
                    data-name="Icon material-add-circle-outline"
                    d="M38.427,19.1H31.986V31.986H19.1v6.441H31.986V51.309h6.441V38.427H51.31V31.986H38.427ZM35.207,3A32.206,32.206,0,1,0,67.413,35.206,32.218,32.218,0,0,0,35.207,3Zm0,57.971A25.765,25.765,0,1,1,60.972,35.206,25.8,25.8,0,0,1,35.207,60.971Z"
                    transform="translate(-3 -3)"
                    fill="#bfc5e2"
                  />
                </g>
              </svg>
              <span>Add Asset</span>
            </div>
          </div>
              
            </Form.Item>
          </>
        )}
      </Form.List>
      <Divider />
          {/* <Form.Item 
                      label="Total" 
                      name="subtotal"
                      labelAlign='right'
                      labelCol={{span:4, offset: 17}}
                      wrapperCol={{span:4}} 
                      colon={false}
                >
                  <Input disabled/>
                </Form.Item> */}
 
        <div className="buttonBlock">
          <div onClick={isLoading ? null : onBack} className="btn cancelBtn">
            Back
          </div>
          {
              tenderDetails?.is_published === 1 && 
              <div className="btn" onClick={publishData}>
                Publish
              </div>
            }
          <Button type="primary" htmlType="submit" className="btn">
          Next
        </Button>

        </div>
    </Form>
     }
        
      </div>
    </div>
  );
};

export default EnterAssetDetails;
