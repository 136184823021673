import React from 'react';
import Header from "../../components/Header";
import AssetList from '../../components/AssetList';

const Asset = () => {
	return (
    <>
      <Header />
      <div className="mainContainer">
      	<div className="innerContainer">
        	<AssetList />
        </div>
      </div>
    </>
  );
};
 export default Asset;
