import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import AdminTender from '../../components/AdminTender';
import PropertyManagerTender from '../../components/PropertyManagerTender';

import Header from "../../components/Header";

const Tender = () => {
  let role_id = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));

	return (
    <>
    <div className="__Tender__">

      <Header />
      <div className="mainContainer" style={{padding: 30}}>
        {
          role_id === 1 ? (
            <AdminTender />
          ) : (
            <PropertyManagerTender />
          )
        }
      </div>
    </div>
    </>
  );
};
 export default Tender;
