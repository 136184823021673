import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table } from 'antd';
import Header from "../Header";
import Setting from "../Setting";
import SettingHeadingLabel from "../Common/SettingHeadingLabel";
import axios from "axios";
import AddModal from "./AddModal";
import ConfirmationBox from '../ConfirmationBox';
// import { useConfirm } from "material-ui-confirm";
import { apiUrl, domainUrl } from "../../helper";
import { compNames, getFilterValues } from '../../utils';
import "./ServiceType.css";

const ServiceType = (props) => {
  // const confirm = useConfirm();
  const [openServiceTypeFormModel, setOpenServiceTypeFormModel] = useState(
    false
  );
  const [serviceType, setServiceType] = useState();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [pagination, setPagination] = useState({
		pageSize: 10
	  });
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deldata, setDeleteData] = useState({});

  const columns = [
    {
      dataIndex: "service_type_name",
      title: "Supplier",
      filters: getFilterValues("service_type_name", serviceTypes),
			onFilter: (value, record) => record.service_type_name.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.service_type_name, b.service_type_name);
				},
			},
    },
    {
      dataIndex: "service_type_id",
      title: "Edit",
      render: (value, tableMeta, updateValue) => (
        <div
                      onClick={() => openServiceTypeForm(value)}
                      className="editIcon settingsIcon"
                    >
                      Edit
                    </div>
      ),
    },
    {
      dataIndex: "service_type_id",
      title: "Delete",
      render: (value, tableMeta, updateValue) => (
        <div className="deleteIcon settingsIcon" onClick={() => deleteServiceType(value)}>
          Delete
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAllServiceType();
  }, []);

  const getAllServiceType = () => {
    axios
      .get(
        `${domainUrl}/masterservicenew/master/service_type?order_by=created_at&sort_by=DESC`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        let data = res.data.data;
        setServiceTypes(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

   const getSelectedService = (service_id) => {
    return serviceTypes.find((ele) => ele.service_type_id === service_id);
  };

  const openServiceTypeForm = (service_id) => {
    let selected = getSelectedService(service_id);
    setOpenServiceTypeFormModel(true);
    setServiceType(selected);
  };

  const closeServiceTypeForm = () => {
    setOpenServiceTypeFormModel(false);
    setServiceType("");
  };

  const addServiceType = async (data) => {

    const headers = {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    if (data?.service_type_id) {
      //modify current organization;
      const { service_type_id, ...rest } = data;

      const res = await axios.put(
        `${domainUrl}/masterservicenew/master/service_type/${service_type_id}`,
        { ...rest },
        { headers }
      );

      getAllServiceType();
     closeServiceTypeForm();
      return;
    }

    //create new organization
    const body = {
      ...data
    };
    const res = await axios.post(
      `${domainUrl}/masterservicenew/master/service_type`,
      body,
      { headers }
    );

     getAllServiceType();
     closeServiceTypeForm();
  };

  const deleteServiceType = (id) => {
		setDeleteData(id);
		setShowConfirmationBox(true);
	};
	
	const declineSubmit = () => {
		setShowConfirmationBox(false);
		setDeleteData({});
	}

  const confirmDelete = () => {

    axios
      .delete(
        `${domainUrl}/masterservicenew/master/service_type/${deldata}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        getAllServiceType();
      })
      .catch((err) => {
        console.log(err);
      });
      
    setShowConfirmationBox(false);
		setDeleteData({});
  };

  return (
    <>
      <Header />
      <div className="settingOuter">
        <Setting page="ServiceType" />
        <div className="tabContainer">
          <h2>Service Type</h2>
          <div className="tabContentBlock">
            <div className="contentBlock">
            <Table
              className="ant-table-root"
			        scroll={{ x: 0, y: '40vh' }}
              columns={columns}
              dataSource={serviceTypes}
              pagination={pagination}
              bordered
              rowKey='service_type_id'
            />

              <div className="button1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.701"
                  height="29.701"
                  viewBox="0 0 29.701 29.701"
                >
                  <path
                    fill="#fff"
                    d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                    transform="translate(-1 -1)"
                  />
                </svg>
                <span onClick={() => openServiceTypeForm()}> Add New</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openServiceTypeFormModel && (
        <AddModal
          data={serviceType}
          onClose={closeServiceTypeForm}
          onSubmit={addServiceType}
        />
      )}
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={'Are you sure you want to delete this service type?' } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
    </>
  );
};

ServiceType.propTypes = {};

export default ServiceType;
