import React, { useState } from "react";
import { message } from "antd";

import "../../Tender/AdditionalInfo/AdditionalInfo.css";
import "../../Tender/EnterTenderDetails/EnterTenderDetails.less";
import { useTender } from "../PreTenderSteps";

import TextEditor from '../../TextEditor';
import { updatePreTender } from "../../../actions/pre-tender";
import { useDispatch } from "react-redux";
import ToolTip from "../../ToolTip";

const EnterTenderDetails = () => {
  const dispatch = useDispatch();
  const { preTenderDetails, onStepChange: move, modifyData } = useTender();

  const [title, setTitle] = useState(preTenderDetails?.title ?? "");
  const [description, setDescription] = useState(
    preTenderDetails?.description ?? ""
  );
  // const [visibility, setVisibility] = useState(
  //   preTenderDetails?.visibility ?? false
  // );
  const [error, setError] = useState(false);

  const onBack = () => {
    move(-1, { title, description });
  };

  const onNext = async () => {
    if (!title?.trim()) {
      setError(true);
      return;
    }
    if(preTenderDetails.is_submitted !== 1) {
    let putResponse = await dispatch(updatePreTender({
      state: parseInt(preTenderDetails.state) > 3 ? preTenderDetails.state : '3',
      title,
      description,
    }, preTenderDetails?.pretender_id));

    let editedPreTender = putResponse.payload?.entities?.preTender[preTenderDetails?.pretender_id]
    if (editedPreTender?.pretender_id) {
      move(1, { title, description });
    }
    else {
      message.error("Unable to reach server.");
    }
  }
  else {
    move(4, { title, description }, true);
  }
  };

  const saveDescription = (data) => {
    setDescription(data);
  }

  const publishData = () => {
    if (!title?.trim()) {
      setError(true);
      return;
    }
    move(0, {
      title, description,
    },true);
    modifyData();
  }

  return (
    <div className="__TenderCreate_TenderDetails__">
      <div className="hedding">
        <h2>Enter Pre Tender Details <ToolTip text_id="tender.tender_details.info" placement="top" /></h2>
      </div>
      <div className="formGroup">
        <label className="leftCol">Title</label>
        <input
          value={title}
          onChange={({ target }) => {
            setError(false);
            setTitle(target.value);
          }}
          type="text"
          className="formFiled"
        />
      </div>
      <div className="formGroup">
        <label className="leftCol">Description</label>
        <TextEditor textData={description} onChangeData={saveDescription} />

      </div>
      {/* <div className="formGroup">
        <label className="leftCol"></label>
        <div className="visibility">
          <input
            type="checkbox"
            id="visibility"
            checked={visibility}
            onChange={(e) => setVisibility(e.target.checked)}
            title="Only visible to me"
          />
          <label htmlFor="visibility">Only visible to me</label>
        </div>
      </div> */}
      {error && (
        <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
          Please enter a title for the tender.
        </p>
      )}
      <div className="buttonBlock">
        <div onClick={onBack} className="btn cancelBtn">
          Back
        </div>
        {
          preTenderDetails?.is_submitted === 1 && 
          <div className="btn" onClick={publishData}>
            Publish
          </div>
        }
        <div onClick={onNext} className="btn">
          Next
        </div>
      </div>
    </div>
  );
};

// Tender.propTypes = {};

export default EnterTenderDetails;
