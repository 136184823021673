import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Modal, Spin, message } from "antd";
import { EditOutlined } from "@ant-design/icons";

import "../../Tender/Summary/Summary.css";
import { domainUrl, awsDocumentUrl } from "../../../helper";
import { genHeaders } from "../../Tender/Tender";
import EmailFormatter from "../../EmailFormatter";
import ConfirmationBox from "../../ConfirmationBox";
import { sendEmailCommunication } from "../../../actions/communication";
import { useDispatch } from "react-redux";

const emailSections = [
  {
    value: "Tender Info",
    selected: false,
  },
  {
    value: "List of chosen Supplier",
    selected: false,
  },
  {
    value: "Attached File",
    selected: false,
  },
];

const Summary = ({ tenderId }) => {
  const { onStepChange: move } = {
    onStepChange: null,
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [sentEmailModel, setSentEmailModel] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');

  const editIconProps = {
    style: {
      borderRadius: "50%",
      color: "white",
      background: "#57b8ff",
      padding: "10px",
    },
  };

  const onFileClick = async (file) => {
    try {
      if (file?.document_id) {
        const documentDetails = await axios.get(
          `${awsDocumentUrl}/Stage/v1/document/${file.document_id}`
        );

        let link;
        if ((link = documentDetails?.data?.data?.signed_link)) {
          const win = window.open(link, "_blank");
          win.focus();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      const assetsCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}/asset`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const generalInfoCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const filesCall = axios
        .get(
          `${awsDocumentUrl}/Stage/v1/document?entity_type=T&entity_id=${tenderId}`,
          { token: sessionStorage.getItem("token") }
        )
        .catch((e) => {
          console.error(e);
          return null;
        });

      const suppliersCall = axios
        .get(`${domainUrl}/tender/v2/tender/${tenderId}/supplier`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const companiesCall = axios
        .get(`${domainUrl}/tender/v2/tender/${tenderId}/company`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      try {
        let title,
          description,
          tender_type,
          additional_info,
          items,
          files,
          closing_date,
          suppliers,
          companies;

        const [
          assetsRes,
          generalInfoRes,
          filesRes,
          suppliersRes,
          companiesRes,
        ] = await Promise.all([
          assetsCall,
          generalInfoCall,
          filesCall,
          suppliersCall,
          companiesCall,
        ]);

        if (assetsRes) {
          const itemsRes = await Promise.all(
            assetsRes.data.data.assets.map((el) =>
              axios
                .get(
                  `${domainUrl}/tender/v2/tender/asset/${el.tender_line_id}/items`,
                  genHeaders()
                )
                .catch((e) => {
                  console.error(e);
                  return [];
                })
            )
          );

          items = itemsRes.map((el, index) => {
            const key =
              assetsRes?.data?.data?.assets[index]?.line_text ??
              assetsRes?.data?.data?.assets[index]?.asset_name;
            const value = el.data.data.items;
            return {
              key,
              value,
            };
          });
        }

        if (generalInfoRes) {
          ({
            title,
            description,
            additional_info,
            tender_type,
            closing_date,
          } = generalInfoRes.data.data);

          tender_type = tender_type === "r" ? "Repair" : "Others";
        }

        if (filesRes) {
          files = filesRes?.data?.data?.documents;
        }

        if (suppliersRes) {
          suppliers = suppliersRes?.data?.data?.suppliers;
        }

        if (companiesRes) {
          companies = companiesRes?.data?.data?.companies;
        }

        setInfo({
          title,
          description,
          tender_type,
          closing_date,
          additional_info,
          items,
          files,
          suppliers,
          companies,
        });
      } catch (e) {
        console.error(e);
        message.error(
          e?.message ?? "Unable to retrieve information from server."
        );
      } finally {
        setLoading(false);
      }
    };

    getInfo();
  }, []);

  const onDelete = async () => {
    try {
      //TODO: tenders once created should not be deleted as per spec, plz make sure it is only disabled in backend.not deleted.
      await axios.delete(`/tender/v1/tender/${tenderId}`, genHeaders());
      await dispatch(sendEmailCommunication('DELETE_TENDER', {tender_id: parseInt(tenderId)}));
      history.goBack();
    } catch (e) {
      console.error(e);
      message.error("Unable to delete tender.");
    }
  };

  const modifyTender = () => {
    setConfirmMsg('Are you sure you want to modify this tender?');
    setShowModifyModal(true)
  }

  const declineSubmit = () => {
    setShowModifyModal(false);
    setConfirmMsg('');
}

const confirmSubmit = async () => {
  setShowModifyModal(false);
  setConfirmMsg('');
  history.push(`/tender/${tenderId}`)
};

  if (loading)
    return <Spin style={{ display: "flex", justifyContent: "center" }} />;
  return (
    <div className="summaryOuter">
      <div className="summaryHeadder">
        <h2>Summary</h2>
      </div>
      <div className="mainContainer">
        <div className="block01">
          {/* <div className="topLeftInfo"> */}
          {/* <svg */}
          {/* xmlns="http://www.w3.org/2000/svg" */}
          {/* width="61.659" */}
          {/* height="57.254" */}
          {/* viewBox="0 0 61.659 57.254" */}
          {/* > */}
          {/* <path */}
          {/* id="Icon_ionic-ios-star" */}
          {/* data-name="Icon ionic-ios-star" */}
          {/* d="M61.569,23.194H41.324L35.172,4.834a2.229,2.229,0,0,0-4.184,0l-6.152,18.36H4.452a2.209,2.209,0,0,0-2.2,2.2,1.618,1.618,0,0,0,.041.372,2.116,2.116,0,0,0,.922,1.555l16.64,11.726L13.467,57.615a2.208,2.208,0,0,0,.757,2.477,2.13,2.13,0,0,0,1.239.537,2.7,2.7,0,0,0,1.376-.5L33.08,48.559,49.32,60.134a2.579,2.579,0,0,0,1.376.5,1.977,1.977,0,0,0,1.225-.537,2.181,2.181,0,0,0,.757-2.477L46.292,39.049l16.5-11.836.4-.344a2.309,2.309,0,0,0,.716-1.473A2.331,2.331,0,0,0,61.569,23.194Z" */}
          {/* transform="translate(-2.25 -3.375)" */}
          {/* fill="#21d59b" */}
          {/* /> */}
          {/* </svg> */}
          {/* <span>Consultation purchased from Janne</span> */}
          {/* </div> */}
        </div>
        <div className="textBlock">
          <div className="labelBLock">Title</div>
          <div className="contentBLock">{info?.title}</div>
        </div>
        <div className="textBlock">
          <div className="labelBLock">Description</div>
          <div
            style={{ whiteSpace: "pre-wrap" }}
            className="contentBLock"
            dangerouslySetInnerHTML={{ __html: info?.description }}
          ></div>
        </div>
        <div className="textBlock">
          <div className="labelBLock">Tender Type</div>
          <div className="contentBLock">{info?.tender_type}</div>
        </div>
        <div className="textBlock">
          <div className="labelBLock">Company</div>
          <div className="contentBLock">{info?.companies && info?.companies[0]?.company_name}</div>
        </div>
      </div>

      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile">Items</div>
        </div>
        {info?.items?.map((item, index) => (
          <React.Fragment key={index}>
            <div
              style={{ margin: "10px 0", flexDirection: "column" }}
              className=" textBlock list01"
            >
              <div className="labelBLock">{item?.key}</div>
              <ul>
                {item?.value?.map((i, itemIndex) => (
                  <li key={itemIndex}>{i?.item_text}</li>
                ))}
              </ul>
            </div>
            <div className="bdrb"></div>
          </React.Fragment>
        ))}
      </div>

      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile">Files</div>
        </div>

        {info?.files?.map((file, fileIndex) => (
          <div
            onClick={() => onFileClick(file)}
            key={fileIndex}
            style={{ cursor: "pointer" }}
            className="filesNames mt-2"
          >
            <div className="filesDes">
              <span>File Description</span>
              <span>{file?.file_description}</span>
            </div>
            <div className="fileFormat" style={{ padding: "0 20px" }}>
              <div className="fileType">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="51.467"
                  height="68.622"
                  viewBox="0 0 51.467 68.622"
                >
                  <path
                    id="Icon_awesome-file-image"
                    data-name="Icon awesome-file-image"
                    d="M51.467,16.344v.812H34.311V0h.812A3.217,3.217,0,0,1,37.4.942L50.525,14.069A3.217,3.217,0,0,1,51.467,16.344Zm-18.228,5.1a3.226,3.226,0,0,1-3.217-3.217V0H3.217A3.217,3.217,0,0,0,0,3.217V65.406a3.217,3.217,0,0,0,3.217,3.217H48.25a3.217,3.217,0,0,0,3.217-3.217V21.444ZM15.084,23.589a6.433,6.433,0,1,1-6.433,6.433A6.433,6.433,0,0,1,15.084,23.589ZM42.962,55.756H8.651l.065-6.5,5.3-5.3a1.532,1.532,0,0,1,2.209.065l5.3,5.3L35.391,35.448a1.608,1.608,0,0,1,2.275,0l5.3,5.3Z"
                    fill="#57b8ff"
                  />
                </svg>
              </div>
              <div className="fileInfo">
                <div className="text">{file?.file_name}</div>
                {/* <div className="fileSize">200kb</div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile">Additional Information/Comment</div>
        </div>
        <p
          style={{ whiteSpace: "pre-wrap" }}
          className="contentBLock"
          dangerouslySetInnerHTML={{ __html: info?.additional_info }}
        ></p>
      </div>
      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile">Chosen Suppliers</div>
        </div>
        <div className="list01">
          <ul>
            {info?.suppliers?.map((supplier, index) => (
              <li key={index}>{supplier?.supplier_name}</li>
            ))}
          </ul>
        </div>
      </div>
      {
        info?.closing_date && 
          <div className="mainContainer">
            <div className="textBlock">
              <div className="labelBLock">Closing Date</div>
              <div className="contentBLock">{info?.closing_date}</div>
            </div>
          </div>
      }
      
      <div
        className="d-flex j-space"
        style={{ marginBottom: 20, flexWrap: "wrap" }}
      >
        <Link className="btn" to={`/bid/received/${tenderId}/bids`}>
          Evaluate Bids
        </Link>
        <div
          onClick={modifyTender}
          className="btn"
          style={{ background: "#fabf01" }}
        >
          Modify Tender
        </div>

        <div
          onClick={() => setShowDeleteModal(true)}
          className="btn"
          style={{ background: "red" }}
        >
          Delete Tender
        </div>
        <div onClick={() => setSentEmailModel(true)} className="btn">
          Send email with all Tender info
        </div>
      </div>
      {sentEmailModel && (
        <EmailFormatter
          isOpen={sentEmailModel}
          onClose={() => setSentEmailModel(false)}
          contentList={emailSections}
          tender_id={tenderId}
        />
      )}

    <ConfirmationBox showConfirmationBox={showModifyModal} message={confirmMsg } confirmSubmit={confirmSubmit} declineSubmit={declineSubmit} />

      {/* <Modal visible={showModifyModal} footer={null}>
        <div className="hedding">
          <h2>Reason for modifying tender?</h2>
        </div>

        <div className="d-flex f-col">
          <textarea className="comments"></textarea>

          <Link className="btn" to={`/tender/${tenderId}`}>
            Continue
          </Link>
        </div>
      </Modal> */}
      <Modal visible={showDeleteModal} footer={null}>
        <div className="hedding">
          <h2>Are you sure you want to delete this tender?</h2>
        </div>

        <div className="d-flex f-col">
          <div className="btn" onClick={onDelete}>
            Yes
          </div>
          <div className="btn" onClick={() => setShowDeleteModal(false)}>
            No
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Summary;
