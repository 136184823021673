import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import Setting from "../Setting";
import SettingHeadingLabel from "../Common/SettingHeadingLabel";
import './PaymentGateway.css';

const PaymentGateway = (props) => {
  return (
    <>
      <Header />
      <div className="settingOuter">
        <Setting page="PaymentGateway" />
        <div className="tabContainer">
          <h2>Payment Gateway</h2>
          <div className="tabContentBlock">
            <div className="contentBlock">
              <div className="formSettings">
                <div className="form-group">
                  <label className="form-label">Provider URL</label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label">Client ID</label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label">Secret Key</label>
                  <input className="form-control" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PaymentGateway.propTypes = {};

export default PaymentGateway;
