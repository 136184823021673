import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Spin } from 'antd';
import get from 'lodash/get';
import Header from "../../components/Header";
import InspectionReport from '../../components/InspectionReport';
import ConfirmationBox from '../../components/ConfirmationBox';
import MessageToast from "../../components/MessageToast";
import {
	fetchAsset,
  fetchAssetProperties,
} from "../../actions/assets";

import { fetchCompany } from "../../actions/company";
import { createInspectionReports, deleteInspectionReports } from "../../actions/inspection-report";
import {getUser} from '../../actions/auth';

const AddInspectionReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {assetId} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [inspectionReportId, setInspectionReportId ] = useState();
  const [showFeedback, setShowFeedback] = useState({
    open: false,
    success: false,
    error: false,
    info: false,
    message: "",
  });
  let windows_event;
  let has_disconnected = false;
  //const assetDetails = useSelector(state => get(state, `entities.assets.${assetId}`, {}));

  const userName = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}`).name || 'Admin');
  const [reportDetails, setReportDetails] = useState({});

  useEffect(() => {
    const createReport = async() => {
      if(!localStorage.getItem('reportId')) {
        const response = await dispatch(createInspectionReports({},assetId));
        let reportId = response.payload?.result[0] || '';
        localStorage.setItem('reportId', reportId);
        setInspectionReportId(reportId)
      }
      else {
        setInspectionReportId(parseInt(localStorage.getItem('reportId')));
       // console.log(inspectionReportId);
      }
      //window.addEventListener('beforeunload', refreshPage);
      
    }
    createReport();

  }, [assetId]);

  useEffect(() => {
   

    // returned function will be called on component unmount 
    return () => {
      deleteReport();
      //setShowConfirmationBox(true);
      //window.removeEventListener('beforeunload', refreshPage, {capture: true});
    }
  }, []);

  const deleteReport = async () => {
    if(inspectionReportId && inspectionReportId!=''){
        let response = await dispatch(deleteInspectionReports(inspectionReportId));
        localStorage.removeItem('reportId');
    }
  //  window.removeEventListener('beforeunload', refreshPage, {capture: true})
  }
  //setShowConfirmationBox(false)
  const confirmDelete = () => {
    deleteReport();
  }

  const declineSubmit = () => {
    setShowConfirmationBox(false);
  }
  useEffect(() => {
    const getReportDetails = async() => {
      const asset = await dispatch(fetchAsset(assetId));
      let userId = sessionStorage.getItem('user_id');
      await dispatch(getUser(userId));
      const companyId = asset?.payload?.entities?.assets[assetId]?.company_id;
      const company = await dispatch(fetchCompany(companyId));
      const report  = (Object.assign(asset.payload.entities?.assets[assetId], company.payload.entities?.companies[companyId]) );
      setIsLoading(false);
      const response = await dispatch(fetchAssetProperties(1));
      const propertyData = Object.values(
        response.payload.entities.assetProperty
      );
      mapPropertyIdNames(propertyData, asset.payload.entities.assets[assetId], report, userName);
    }
		getReportDetails();
	}, [dispatch, assetId]);

  const mapPropertyIdNames = (propertyData, asset, report, userName) => {
    let propertiesData = [];
    let propertiesForm = {};
    propertyData.map((prop) => {
      let obj = {};
      obj.property_id = prop.property_id;
      const propSelected = asset.asset_values.find(
        (ele) => ele.property_id === prop.property_id
      );
      if(propSelected ) {
        obj.property_name = prop.property_name;
        obj.property_value = propSelected ? propSelected.property_value : "";
        propertiesData.push(obj);
      }
    });
    const data = {
      ...report,
      inspected_by: userName,
      asset_properties: propertiesData,
    };
    setReportDetails(data);
  };

  const finishReportCreation = (isSuccess) => {
    setShowFeedback({
      open: true,
      success: isSuccess,
      error: !isSuccess,
      info: false,
      message: isSuccess ? "Inspection Report Saved Successfully" : "Inspection Report Not Saved",
    });
    localStorage.removeItem('reportId');
    setTimeout(() => {
      history.push(`/inspection-report/${inspectionReportId}`)
    },1000);
  }

  const closeFeedback = () => {
    setShowFeedback({
      open: false,
      success: false,
      error: false,
      info: false,
      message: "",
    });
  };

	return (
    <>
    {showFeedback.open && (
        <MessageToast
          open={showFeedback.open}
          success={showFeedback.success}
          error={showFeedback.error}
          message={showFeedback.message}
          onClose={closeFeedback}
        />
      )}
      <Header />
      <div className="mainContainer" style={{padding: '20px 0'}}>
      	<div className="innerContainer">
        <Spin spinning={isLoading}>
          <h2 className="title">New Inspection Report</h2>
              {
                Object.values(reportDetails).length > 0 &&
                  <InspectionReport reportDetails={reportDetails} inspectionReportId={inspectionReportId} finishReportCreation = {finishReportCreation}/>
              }
           </Spin>   
        </div>
      </div>
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={'Entered data will be lost if you refresh or navigate from this page. Are you sure you want to continue?' } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />	
   
    </>
  );
};
 export default AddInspectionReport;
