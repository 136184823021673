import React, { useEffect, useState } from "react";
import { Spin, message, Modal } from "antd";

import "../../Tender/Summary/Summary.css";
import { useTender } from "../PreTenderSteps";
import PreTenderDetails from "../../PreTenderDetail/PreTenderDetails";
import { submitPreTender } from "../../../actions/pre-tender";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { sendEmailCommunication } from "../../../actions/communication";

const Summary = () => {

  const dispatch = useDispatch();
  const { preTenderDetails, onStepChange: move, modifyData } = useTender();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState();

  const editIconProps = {
    style: {
      borderRadius: "50%",
      color: "white",
      background: "#57b8ff",
      padding: "10px",
    },
  };

  const publishTender = async() => {
    if(preTenderDetails.is_submitted !== 1){
      setLoading(true);
       let putResponse = await dispatch(submitPreTender({
        state: parseInt(preTenderDetails.state) > 7 ? preTenderDetails.state : '7',
        is_submitted: true,
      }, preTenderDetails?.pretender_id));
  
      let editedPreTender = putResponse.payload?.entities?.preTender[preTenderDetails?.pretender_id]
      if (editedPreTender?.pretender_id) {
        await dispatch(sendEmailCommunication('PRETENDER_SUBMISSION', {pretender_id: +preTenderDetails?.pretender_id}));
      }
      setLoading(false);
      move(1);
    }
    else {
      setShowConfirmationModal(false);
      modifyData();
    }
  }

  if (loading)
    return <Spin style={{ display: "flex", justifyContent: "center" }} />;
  return (
    <>
    <PreTenderDetails preTenderId={preTenderDetails.is_submitted != 1 ? preTenderDetails.pretender_id : undefined} preTenderDetails={preTenderDetails.is_submitted === 1 ? preTenderDetails : undefined} setIsLoading={setLoading} isSupplierSummary={true} allowEdit={true} move={move} showToolTip={true}/>

      <div className="buttonBlock">
        <div className="btn cancelBtn">Preview</div>
        <div onClick={() => setShowConfirmationModal(true)} className="btn">
          Submit
        </div>
      </div>

      <Modal visible={showConfirmationModal} closable={false} footer={null}>
        <h2 style={{ textAlign: "center" }}>
          Do you really want to submit this tender?
        </h2>
        <div className="d-flex j-center f-col">
          <div className="btn" onClick={publishTender}>
            Yes
          </div>
          <div
            className="btn"
            onClick={() => {
              setShowConfirmationModal(false);
            }}
          >
            No
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Summary;
