import React from "react";
import { useSelector } from "react-redux";
import get from 'lodash/get';
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { DeleteTwoTone } from '@ant-design/icons';
import AddTender from "../Tenders/AddTender";

import TenderCard from "../../__TenderCardAdmin";

const Tenders = () => {

  const stateUser = useSelector(state => get(state, `entities.users`));
  const stateSupplier = useSelector(state => get(state, `entities.suppliers`));

  return (
    <>
    <TenderCard
        status="awarded"
        titleSlot={
          <>
            <li className="four-column"><FormattedMessage id={'name'} /></li>
            <li className="four-column"><FormattedMessage id={'company'} /></li> 
            <li className="four-column"><FormattedMessage id={'awarded_to'} /></li>
            <li className="four-column"><FormattedMessage id={'awarded_date'} /></li>
          </>
        }
      >
        {(tender) => (
          <>
            <div className="four-column">{tender.name}</div>
            <div className="four-column">{tender?.company_name}</div>
            <div className="four-column">{stateSupplier[tender?.winner_id]?.supplier_name}</div>
            <div className="four-column">{moment(tender?.award_datetime).format("DD-MM-YYYY")}</div>
          </>
        )}
      </TenderCard>
      <AddTender />
       <div style={{ marginTop: "10px" }}>
        <TenderCard
          status="published"
          titleSlot={
            <>
               <li className="four-column-action"><FormattedMessage id={'name'} /></li>
              {/* <li>Status</li> */}
              <li className="four-column-action"><FormattedMessage id={'company'} /></li>
              <li className="four-column-action"><FormattedMessage id={'closing_date'} /></li>
              <li className="four-column-bids"><FormattedMessage id={'bids'} /></li>
              {/* <li>Last Updated</li> */}
            </>
          }
        >
          {(tender) => (
            <>
              <div className="four-column-action">{tender.name}</div>
              {/* <div style={{ color: "green", fontWeight: "bold" }}>
                {tender?.stat}
              </div> */}
              <div className="four-column-action">{tender?.company_name}</div>
              <div className="four-column-action">{moment(tender?.closing_date).format("DD-MM-YYYY")}</div>
              <div className="four-column-bids">{tender?.bids}{tender?.no_of_suppliers && '/'+tender?.no_of_suppliers}</div>
              {/* <div>{tender?.bids}</div> */}
            </>
          )}
        </TenderCard>
      </div>

      <TenderCard
        status="draft"
        titleSlot={
          <>
            <li className="three-column"><FormattedMessage id={'name'} /></li>
            {/* <li><FormattedMessage id={'status'} /></li> */}
            <li className="three-column"><FormattedMessage id={'company'} /></li>
            {/* <li><FormattedMessage id={'created_by'} /></li> */}
            <li className="three-column"><FormattedMessage id={'last_updated'} /></li>
            <li style={{width: '5%'}}></li>
          </>
        }
      >
        {(tender, deleteDraftTender, index) => (
          <>
            <div className="three-column">{tender.name}</div>
            {/* <div style={{ color: "red", fontWeight: "bold" }}>
              {tender?.stat}
            </div> */}
              <div className="three-column">{tender?.company_name}</div>
            {/* <div>{stateUser[tender?.created_by]?.name}</div> */}
            <div className="three-column">{moment(tender?.updated_at).format("DD-MM-YYYY")}</div>
            <DeleteTwoTone twoToneColor={'red'} onClick={() => deleteDraftTender(tender.id)}/>
          </>
        )}
      </TenderCard>
    </>
  );
};

export default Tenders;
