import React, { useState, useEffect } from "react";
import { Table } from 'antd';
import Header from "../Header";
import Setting from "../Setting";
import SettingHeadingLabel from "../Common/SettingHeadingLabel";
import axios from "axios";
import AddModal from "./AddModal";
import ConfirmationBox from '../ConfirmationBox';
// import { useConfirm } from "material-ui-confirm";
import { domainUrl }  from "../../helper";
import { compNames, getFilterValues } from '../../utils';

const CompanyType = (props) => {
  // const confirm = useConfirm();
  const [openCompanyTypeFormModel, setOpenCompanyTypeFormModel] = useState(
    false
  );
  const [companyType, setCompanyType] = useState();
  const [companyTypes, setCompanyTypes] = useState([]);

  const [pagination, setPagination] = useState({
		pageSize: 10
	  });
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deldata, setDeleteData] = useState({});

  const columns = [
    {
      dataIndex: "company_type_name",
      title: "Company Type",
      filters: getFilterValues("company_type_name", companyTypes),
			onFilter: (value, record) => record.company_type_name.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.company_type_name, b.company_type_name);
				},
			},
    },
    {
      dataIndex: "company_type_id",
      title: "Edit",
      render: (value, tableMeta, updateValue) => (
        <div
          onClick={() => openCompanyTypeForm(value)}
          className="editIcon settingsIcon"
        >
          Edit
        </div>
      ),
    },
    {
      dataIndex: "company_type_id",
      title: "Delete",
      render: (value, tableMeta, updateValue) => (
        <div className="deleteIcon settingsIcon" onClick={() => deleteCompanyType(value)}>
          Delete
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAllCompanyType();
  }, []);
  const getAllCompanyType = () => {
    axios
      .get(
        `${domainUrl}/masterservicenew/master/company_type?order_by=created_at&sort_by=DESC`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        let data = res.data.data;
        setCompanyTypes(data.company_type);
      })
      .catch((err) => {
        console.log(err);
      });  
  };

  const openCompanyTypeForm = (company_type_id) => {
    setOpenCompanyTypeFormModel(true);
    let data = companyTypes.find(comp => company_type_id === comp.company_type_id);
    setCompanyType(data);
  };

  const closeCompanyTypeForm = () => {
    setOpenCompanyTypeFormModel(false);
    setCompanyType("");
  };

  const addCompanyType = (data) => {
    if(data.company_type_id) {
      axios
      .put(
        `${domainUrl}/masterservicenew/master/company_type/${data.company_type_id}`,data,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        getAllCompanyType();
        closeCompanyTypeForm();
      })
      .catch((err) => {
        console.log(err);
      }); 
    }
    else {
     axios
      .post(
        `${domainUrl}/masterservicenew/master/company_type`,data,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        getAllCompanyType();
        closeCompanyTypeForm();
      })
      .catch((err) => {
        console.log(err);
      }); 
      } 
  };

  const deleteCompanyType = (id) => {
		setDeleteData(id);
		setShowConfirmationBox(true);
	};
	
	const declineSubmit = () => {
		setShowConfirmationBox(false);
		setDeleteData({});
	}

  const confirmDelete = () => {

    axios
      .delete(
        `${domainUrl}/masterservicenew/master/company_type/${deldata}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        getAllCompanyType();
      })
      .catch((err) => {
        console.log(err);
      }); 

      setShowConfirmationBox(false);
		  setDeleteData({});
  };

  return (
    <>
      <Header />
      <div className="settingOuter">
        <Setting page="CompanyType" />
        <div className="tabContainer">
          <h2>Company Type</h2>
          <div className="tabContentBlock">
            <div className="contentBlock">
            <Table
              className="ant-table-root"
			        scroll={{ x: 0, y: '40vh' }}
              columns={columns}
              dataSource={companyTypes}
              pagination={pagination}
              bordered
              rowKey='company_type_id'
            />
              <div className="button1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.701"
                  height="29.701"
                  viewBox="0 0 29.701 29.701"
                >
                  <path
                    fill="#fff"
                    d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                    transform="translate(-1 -1)"
                  />
                </svg>
                <span onClick={() => openCompanyTypeForm()}> Add New</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openCompanyTypeFormModel && (
        <AddModal
          data={companyType}
          onClose={closeCompanyTypeForm}
          onSubmit={addCompanyType}
        />
      )}
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={'Are you sure you want to delete this company type?' } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
    </>
  );
};

CompanyType.propTypes = {};

export default CompanyType;
