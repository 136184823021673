import "./Tender.less";

import React, { useCallback, useContext, useState, useEffect } from "react";
import { Spin, Modal, message, Row, Col } from "antd";
import { useHistory, useParams } from "react-router-dom";
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import AdditionalInfo from "./AdditionalInfo/AdditionalInfo";
import EnterTenderDetails from "./EnterTenderDetails/EnterTenderDetails";
import EnterAssetDetails from "./EnterAssetDetails/EnterAssetDetails";
import AttachFile from "./AttachFile/AttachFile";
import ChooseTenderType from "./ChooseTenderType/ChooseTenderType";
import ChooseHousingCompany from "./ChooseHousingCompany/ChooseHousingCompany";
import Summary from "./Summary/Summary";
import ConfirmTender from "./ConfirmTender";
import { fetchAssetsPreTender, fetchCompanyPreTender, fetchPreTender, updatePreTender, createAssetPreTender, updateAssetPreTender, deleteCompanyPreTender, createCompanyPreTender } from "../../actions/pre-tender";
import { useDispatch } from "react-redux";
import { sendEmailCommunication } from "../../actions/communication";

export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});

const TenderContext = React.createContext({
  onStepChange: null,
  preTenderDetails: null,
  modifyData: null,
  setConfirmTenderPage: null,
});

export const useTender = () => {
  const ctx = useContext(TenderContext);
  return ctx;
};

const steps = [
  "Choose Pre Tender Type",
  "Choose Company",
  "Enter Pre Tender Details",
  "Select Assets",
  "Attach File",
  "Additional Info",
  "Summary",
];

const PreTenderSteps = (props) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [confirmTenderPage, setConfirmTenderPage] = useState(false);
  const [preTenderDetails, setPreTenderDetails] = useState({});
  const [preTenderModifiedDetails, setPreTenderModifiedDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModificationModal, setShowModificationModal] = useState(false);
  const [reason, setReason] = useState('');
  const [organizationData, setOrganizationData] = useState({});
  const currentCompanyDetails = useSelector(state => Object.values(get(state, `entities.preTenderCompany`) || {}));
   const organizations = useSelector(state => get(state, `entities.organizations`));
  
  const history = useHistory();
  const { preTenderId } = useParams();

  useEffect(() => {
    if(organizations && currentCompanyDetails && currentCompanyDetails.length > 0) {
      setOrganizationData(organizations[currentCompanyDetails[0]?.organization_id]);
    }
  }, [organizations, currentCompanyDetails])


  const modifyData = () => {
     setShowModificationModal(true);
  }

  useEffect(() => {
    if(currentCompanyDetails) {
      preTenderDetails.selectedCompanies = currentCompanyDetails;
    }
  }, [currentCompanyDetails])

  const onStepChange = useCallback((moveBy, preTenderDetails,isModified) => {
    if (preTenderDetails) {
        setPreTenderDetails((current) => {
          console.log("cuur tender --->", current);
          return { ...current, ...preTenderDetails };
        });
      if(isModified)  {
        setPreTenderModifiedDetails((current) => {
          return {...current, ...preTenderDetails};
        })
      }
    }
    setStep((currStep) => {
      const finalStep = currStep + moveBy;
      if (finalStep < 1 || finalStep > 10) {
        history.goBack();
        return;
      }
      return finalStep;
    });
  }, []);

  const onNavClick = (finalStep) => {
    if (finalStep >= step) return;
    const diff = finalStep - step;
    onStepChange(diff);
  };
  const modificationReason = (e) => {
    setReason(e.target.value)
  }
  
  const confirmModify = async() => {
    setShowModificationModal(false);
    setLoading(true);
    if(preTenderModifiedDetails.hasOwnProperty('assetsFormValues')) {
      await modifyAssets(preTenderModifiedDetails.assetsFormValues, preTenderModifiedDetails.selectedAssetDb);
    }
    if(preTenderModifiedDetails.hasOwnProperty('selectedCompanies')) {
      await modifyCompanies(preTenderModifiedDetails.selectedCompanies);
    }
    let data = Object.assign({}, preTenderModifiedDetails);
    delete data.assetsFormValues;
    delete data.selectedCompanies;
    delete data.selectedAssetDb;
    const response = await dispatch(updatePreTender({...data, comments: reason}, preTenderDetails.pretender_id));
    const status = response.payload?.entities?.preTender[preTenderDetails.pretender_id] ? true : false;
  //   if(reason && tenderDetails.is_published){
  //     await dispatch(sendEmailCommunication('NOTIFY_ALL_SUPPLIER', {tender_id: preTenderDetails.pretender_id}));
  //  }
      
    setLoading(false);
    if(status)
     onStepChange(1);
    else 
      message.error("Unable to reach server.");
    //   finishTenderCreation(status, status ? `Pre Tender successfully ${statusData.status}` : `Pre Tender not ${statusData.status}`);
    //   setStatusMsg('');
    //   setStatusData({});
  }

  const modifyAssets = async(e, selectedAssetDb) => {
    const createAssetCalls = await Promise.all(
      e?.map(async (asset, index) => {

          let data = isNaN(parseInt(asset.asset_name[0])) ? {line_text: asset.asset_name[0]} : {asset_id: parseInt(asset.asset_name[0])};
          data.amount = parseInt(asset.amount);
            let assetCall;
            if(!asset.pretender_line_id)
              assetCall = await dispatch(createAssetPreTender(data, preTenderDetails.pretender_id));
            else {
              data = asset;
              if(!asset.asset_id)
                data.line_text = asset.asset_name[0];
              data.amount = parseInt(asset.amount);
              assetCall = await dispatch(updateAssetPreTender(data, asset.pretender_line_id));
            }

           
      }));
  }

  const modifyCompanies = async(selectedCompanies) => {
    if(preTenderDetails?.selectedCompanies) {
      await Promise.all(
        preTenderDetails?.selectedCompanies?.map(async company => {
          await dispatch(deleteCompanyPreTender(preTenderDetails.pretender_id, company));
        }));
    }
    const companies_data = selectedCompanies?.map(el => {
      return { company_id: el }
    })
    const companyResp = await dispatch(createCompanyPreTender(companies_data, preTenderDetails.pretender_id));
  }

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      const generalInfoCall = await dispatch(fetchPreTender(preTenderId));
      const generalInfoData = generalInfoCall.payload?.entities?.preTender[preTenderId];

      const companiesCall = await dispatch(fetchCompanyPreTender(preTenderId));
      const companies = Object.values(companiesCall.payload?.entities?.preTenderCompany || [])?.map(el => {
        return el.company_id
      });
      const companyNames = Object.values(companiesCall.payload?.entities?.preTenderCompany || []);
      const assetsCall = await dispatch(fetchAssetsPreTender(preTenderId));
      let assetsFormTemplate = [];
      let assetsFormValues = [];
      Object.values(assetsCall?.payload?.entities?.preTenderAsset || []).map((asset,assetIndex) => {
        const key = `asset${assetIndex + 1}`;
        const assetValue = { 'asset_name': [asset?.asset_id ? String(asset?.asset_name) :  asset?.line_text], amount: asset?.amount, asset_id: asset?.asset_id, pretender_line_id: asset?.pretender_line_id };
        assetsFormValues.push(assetValue);

      })

      const nextStep = parseInt(generalInfoData?.state || 1) || 1 + 1;

        onStepChange(nextStep || 1, {
          ...generalInfoData,
           selectedCompanies: companies || [],
           companyNames,
          // title,
          // files,
          // description,
          // visibility: !is_visible_to_all,
          // additional_info,
           assetsFormTemplate,
           assetsFormValues,
        });
        setLoading(false);
      } 
      // catch (e) {
      //   console.error(e);
      //   message.error(
      //     e?.message ?? "Unable to retrieve information from server."
      //   );
      //   history.goBack();
      // }
    //};

    if (preTenderId) getDetails();
    else onStepChange(1);
  }, []);

  let content = (
    <TenderContext.Provider value={{ onStepChange, preTenderDetails, modifyData, setConfirmTenderPage }}>
      {step === 1 && <ChooseTenderType />}
      {step === 2 && <ChooseHousingCompany />}
      {step === 3 && <EnterTenderDetails />}
      {step === 4 && <EnterAssetDetails />}
      {step === 5 && <AttachFile />}
      {step === 6 && <AdditionalInfo />}
      {step === 7 && <Summary />}
      {step === 8 && <ConfirmTender />}
    </TenderContext.Provider>
  );

  if (loading) {
    content = (
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      {!confirmTenderPage && 
        <ul className="tenderHeader">
          {steps?.map((s, i) => (
            <li
              key={i}
              className={step >= i + 1 ? "active" : null}
              onClick={() => onNavClick(i + 1)}
            >
              {s}
            </li>
          ))}
        </ul>
      }
        <div className="innerContainer">
        {
              (step != 8 && (preTenderDetails?.title || organizationData)) && 
            <Row className="header_summary" align="top" justify="space-between">
            
                <Col>
                  {/* <Row>
                    <h4>
                      Title:
                    </h4>
                    </Row> */}
                    <Row>
                    <h4>
                  {preTenderDetails?.title}
                  </h4>
                      </Row></Col>
              
             {
             (organizations && organizationData) && (
                <Col>
                  <h4>
                    {organizationData?.organization_name}
                    {organizationData?.address && ', '+organizationData.address}
                    {organizationData?.city_name && ', '+organizationData.city_name}
                    {organizationData?.country_name && ', '+organizationData.country_name} - {organizationData?.pincode}
                  </h4>
                    

                </Col>
              )
            }
            </Row>
}
          
          {content}
        </div>
        <Modal visible={showModificationModal} footer={null}>
        <div className="hedding">
          <h2>Reason for modifying tender?</h2>
        </div>

        <div className="d-flex f-col">
          <textarea className="comments" onChange={modificationReason}></textarea>

          <div className="btn" onClick={confirmModify}>
                Continue
          </div>
        </div>
      </Modal>
      </>
  );
};

PreTenderSteps.propTypes = {};

export default PreTenderSteps;
