import React, { useState, useEffect } from "react";
import axios from "axios";
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { apiUrl, domainUrl } from "../../helper";
import { fetchCompanyTypes } from "../../actions/company";
import "./AddModal.css";

const AddModal = (props) => {

  const dispatch = useDispatch();

  const companyTypes = useSelector(state => get(state, `entities.companyTypes`));

  const [organization, setOrganization] = useState(props.data);
  const [isReadOnly, setIsReadOnly] = useState(props.readOnly);
  const [errorMessage, setErrorMessage] = useState({});
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  function handleInputChange(event) {
    const data = {
      ...organization,
      [event.target.name]: event.target.value,
    };
    setOrganization(data);
    setErrorMessage({});
  }
  const getCompanyTypes = async() => {
     await dispatch(fetchCompanyTypes({ limit: 1000 })); 
  }

  useEffect(() => {
    getAllCountries();
    getCompanyTypes();
    organization &&
      organization.country_id &&
      getAllCities(organization.country_id);
  }, []);

  const onAddNewEmail = () => {
    const currEmailAccounts = organization?.default_emails ?? [];

    setOrganization({
      ...organization,
      default_emails: [...currEmailAccounts, ""],
    });
  };

  const onDeleteEmail = (index) => {
    const filteredEmailAccounts = organization?.default_emails?.filter(
      (_, i) => i !== index
    );
    setOrganization({
      ...organization,
      default_emails: [...filteredEmailAccounts],
    });
  };

  const onDefaultEmailChange = (index, e) => {
    const _ = organization?.default_emails?.slice() ?? [];
    _[index] = e.target.value;

    setOrganization({ ...organization, default_emails: _ });
  };

  const onSelectCountry = (e) => {
    const countryId = +e?.target?.value || null;
    setOrganization({ ...organization, country_id: countryId });
    countryId && getAllCities(countryId);
  };

  const getAllCountries = () => {
    axios
      .get(
        `${domainUrl}/masterservicenew/master/country?limit=100&offset=0&order_by=created_at&sort_by=ASC`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        let data = res.data.data;
        console.log("setCountries ", data);
        setCountries(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCities = (countryId) => {
    axios
      .get(
        `${domainUrl}/masterservicenew/master/city?limit=100&country_id=${countryId}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        let data = res.data.data;
        console.log("setCities ", data);
        setCities(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleSubmit() {
    const errors = {};

    if (!organization) {
      setErrorMessage({
        companyType: "Please Select Company Type",
        org_name: "Please Enter Name",
        business_id: "Please Enter Business ID",
        email: "Please Enter Email",
        mobile: "Please Enter Mobile",
        address: "Please Enter Address",
        country: "Please Select Country",
        city: "Please Select City",
        pincode: "Please Enter Postal code",
      });
      return;
    }
     if (
      !organization.company_type_id ||
      organization.company_type_id === ""
    ) {
      errors.companyType = "Please Select Company Type";
    }
    if (
      !organization.organization_name ||
      organization.organization_name === ""
    ) {
      errors.org_name = "Please Enter Name";
    }
    if (!organization.business_id || organization.business_id === "") {
      errors.business_id = "Please Enter Business ID";
    }
    if (!organization.email || organization.email === "") {
      errors.email = "Please Enter Name";
    }
    if (
      organization.email &&
      organization.email !== "" &&
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        organization.email
      )
    ) {
      errors.email = "Please Enter Valid Email";
    }
    if (!organization.contact || organization.contact === "") {
      errors.mobile = "Please Enter Mobile";
    }
    if (!organization.address || organization.address === "") {
      errors.address = "Please Enter Address";
    }
    if (!organization.country_id || organization.country_id === "") {
      errors.country = "Please Enter Country";
    }
    if (!organization.city_id || organization.city_id === "") {
      errors.city = "Please Enter City";
    }
    if (!organization.pincode || organization.pincode === "") {
      errors.pincode = "Please Enter Postal code";
    }

    if (Object.keys(errors).length >= 1) {
      setErrorMessage({ ...errors });
      return;
    } else if (
      organization &&
      organization.organization_name !== "" &&
      organization.business_id !== "" &&
      organization.email !== "" &&
      organization.mobile !== "" &&
      organization.address !== "" &&
      organization.country_id !== "" &&
      organization.city_id !== "" &&
      organization.pincode !== ""
    ) {
      console.log(organization);
      props.onSubmit(organization);
    } else {
      setErrorMessage({
        message: "Some issue occurred please try again",
      });
    }
  }

  return (
    <div className="organizationAddModal">
      <div className="pageOverlay">
        <div className="modalParent">
          <div className="closeBtn2" onClick={() => props.onClose()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48.408"
              height="48.408"
              viewBox="0 0 48.408 48.408"
            >
              <g id="Group_2114" transform="translate(-198.987 -621.867)">
                <g id="Group_1542" transform="translate(198.987 621.867)">
                  <path
                    id="Path_61"
                    d="M26.2 50.408A24.2 24.2 0 1 0 2 26.2a24.2 24.2 0 0 0 24.2 24.2zm0-44.951A20.746 20.746 0 1 1 5.458 26.2 20.746 20.746 0 0 1 26.2 5.458z"
                    className="cls-1"
                    transform="translate(-2 -2)"
                  />
                  <path
                    id="Path_62"
                    d="M9.506 32.667a1.729 1.729 0 0 0 2.438 0l9.162-9.167 9.146 9.146a1.729 1.729 0 0 0 2.438-2.438l-9.146-9.146 9.146-9.142a1.729 1.729 0 0 0-2.438-2.438l-9.146 9.146-9.145-9.163A1.729 1.729 0 0 0 9.523 11.9l9.146 9.163-9.163 9.149a1.729 1.729 0 0 0 0 2.455z"
                    className="cls-1"
                    transform="translate(3.098 3.138)"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="modalOuterMain">
            <div className="modalOuter2">
              {errorMessage.message && (
                <div className="errorMessage">{errorMessage.message}</div>
              )}
              <h2 className="title">{!isReadOnly ? (organization?.organization_id ? 'Edit' : 'Enter') : ''} Organization Details</h2>
              <div className="inputFieldGroup">
                <label >Company Type</label>
                <div className="inputText">
                      {isReadOnly ? (
                        <input
                          readOnly={isReadOnly}
                          className="inputFiled"
                          type="text"
                          name="company_type_name"
                          value={companyTypes[organization?.company_type_id].company_type_name}
                        />
                      ) : (
                        <div className="costumSelect">
                          <select
                            name="company_type_id"
                            id="slct"
                            className="inputFiled"
                            value={organization?.company_type_id ?? ""}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Company Type</option>
                            {Object.values(companyTypes || {})?.map((el, index) => (
                              <option key={index} value={el.company_type_id}>
                                {el?.company_type_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className="errorMessage">{errorMessage.companyType}</div>
                    </div>
              </div>
              <div className="inputFieldGroup">
                <label>Name</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="organization_name"
                    value={organization?.organization_name ?? ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.org_name}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Business ID</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="business_id"
                    value={organization?.business_id ?? ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.business_id}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Email</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="email"
                    name="email"
                    value={organization?.email ?? ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.email}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Mobile</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="contact"
                    value={organization?.contact ?? ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.mobile}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Address</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="address"
                    value={organization?.address ?? ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.address}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Country</label>
                <div className="inputText">
                  {isReadOnly ? (
                    <input
                      readOnly={isReadOnly}
                      className="inputFiled"
                      type="text"
                      name="Country"
                      value={organization?.country_name ?? ""}
                    />
                  ) : (
                    <div className="costumSelect">
                      <select
                        name="country_id"
                        id="slct"
                        className="inputFiled"
                        value={organization?.country_id ?? ""}
                        onChange={(e) => onSelectCountry(e)}
                      >
                        <option value="">Select country</option>
                        {countries?.map((el, index) => (
                          <option key={index} value={el.country_id}>
                            {el?.country_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="errorMessage">{errorMessage.country}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>City</label>
                <div className="inputText">
                  {isReadOnly ? (
                    <input
                      readOnly={isReadOnly}
                      className="inputFiled"
                      type="text"
                      name="City"
                      value={organization?.city_name ?? ""}
                    />
                  ) : (
                    <div className="costumSelect">
                      <select
                        name="city_id"
                        id="slct"
                        className="inputFiled"
                        value={organization?.city_id ?? ""}
                        onChange={handleInputChange}
                      >
                        <option value="">Select city</option>
                        {cities?.map((el, index) => (
                          <option key={index} value={el.city_id}>
                            {el?.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="errorMessage">{errorMessage.city}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label>Postal Code</label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="pincode"
                    value={organization?.pincode ?? ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.pincode}</div>
                </div>
              </div>
              {isReadOnly && organization?.default_emails?.length > 0 && (
                 <div className="inputFieldGroup emailGroup">
                 <label >Default Emails</label>
                 <div className="inputText">
                {organization?.default_emails?.map((el, index) => (

                   <input
                     readOnly={isReadOnly}
                     className="inputFiled"
                     type="text"
                     value={el || ""}
                     name={"default_email"}
                   />
                   ))}
                 </div>
               </div>
                
              )}
            </div>
            {!isReadOnly && (
              <div className="modalOuter2">
                {organization?.default_emails?.map((el, index) => (
                  <div key={index} className="inputFieldGroup">
                    {index === 0 && <label>Default Email</label>}
                    <div className="inputText ml-auto">
                      <input
                        className="inputField"
                        type="email"
                        name={"default_email"}
                        value={el || ""}
                        onChange={(e) => onDefaultEmailChange(index, e)}
                      />
                    </div>
                    <div
                      onClick={() => onDeleteEmail(index)}
                      className="deleteIcon"
                    >
                      Delete
                    </div>
                  </div>
                ))}

                <div className="inputFieldGroup">
                  <div className="button1 button2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29.701"
                      height="29.701"
                      viewBox="0 0 29.701 29.701"
                    >
                      <path
                        fill="#fff"
                        d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                        transform="translate(-1 -1)"
                      />
                    </svg>
                    <span onClick={onAddNewEmail}>Add Default Email</span>
                  </div>
                </div>

                <div className="buttonBlock">
                  <div
                    className="btn cancelBtn"
                    onClick={() => props.onClose()}
                  >
                    Cancel
                  </div>
                  <div className="btn" onClick={handleSubmit}>
                    Save
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;