import "./bid.less";
import React, { useEffect, useState } from "react";
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import Header from "../../components/Header";
import BidSummary from "../bid/Summary";
import CompareBids from "./Compare";
import { Modal, Button, Card, message, Table, Spin } from "antd";
import { EyeFilled, VerifiedOutlined } from "@ant-design/icons";
import axios from "axios";
import { useHistory } from "react-router";
import { domainUrl } from "../../helper";
import { genHeaders } from "../../components/Tender/Tender";
import ConfirmationBox from '../../components/ConfirmationBox';
import { sendEmailCommunication } from "../../actions/communication";
import { useDispatch } from "react-redux";

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export default function Bid(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [viewId, setViewId] = useState();
  const [awardedSupplier, setAwardedSupplier] = useState();
  const [awardedSupplierName, setAwardedSupplierName] = useState();
  const [compareBids, setCompareBids] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);

  const userId = sessionStorage.getItem('user_id');
  const user = useSelector(state => get(state, `entities.users.${userId}`));

  const history = useHistory();
  const { tenderId } = props.match.params;

  useEffect(() => {
    const headers = {
      headers: {
        client_code: "ht_front",
        token: sessionStorage.getItem("token"),
      },
    };

    axios
      .get(`/bid/v1/tender/${tenderId}/bid`, headers)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setData(response.data.data.result);
          setAwardedSupplier(response?.data?.data?.result[0]?.winner_id);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        message.error("Could not receive bid information");
        history.goBack();
      });
  }, []);

  const placeAward = async() => {
    axios
      .put(
        `${domainUrl}/tender/v1/tender/${tenderId}`,
        {
          winner_id: awardedSupplier,
        },
        genHeaders()
      )
      .then(async() => {
        await dispatch(sendEmailCommunication('AWARD_TENDER', {tender_id: parseInt(tenderId), company_name: user.roles.role_id === 1 ? 'Hissitaito OY' : ''}));
        history.push("/");
      })
      .catch((e) => {
        message.error("Unable to place award for supplier.");
      });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const selectedSupplier = selectedRows.map(bid => { return bid});
      setAwardedSupplier(selectedSupplier[0]?.supplier_id);
      setAwardedSupplierName(selectedSupplier[0]?.supplier_name);
    }
};

  const columns = [
    
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_id",
      render: (name) => <div>{name}</div>,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "bid_id",
      render: (datetime) => {
        return <div>{datetime?.split("T")[0]}</div>;
      },
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "bid_id",
      render: (time) => <div>{formatAMPM(new Date(time))}</div>,
    },
    {
      title: "Total",
      key: "bid_id",
      dataIndex: "sub_total",
      render: (price) => <div>{price}&nbsp;&euro;</div>,
      sorter: {
        compare: (a, b) => a.sub_total - b.sub_total,
      },
    },
    {
      title: "View",
      render: (_, record) => {
        return (
          <Button
            type="link"
            style={{ marginTop: 0 }}
            shape="circle"
            icon={
              <EyeFilled
                onClick={() => setViewId(record?.bid_id)}
                style={{ fontSize: "30px" }}
              />
            }
          />
        );
      },
    },
  ];

  const awardClasses = ["btn"];
  if (isNaN(awardedSupplier)) {
    awardClasses.push("disabled");
  }

  const declineSubmit = () =>{
    setShowConfirmationBox(false);
  }

  return (
    <>
      <Header />
      <div className="bid-receive-root container">
        {loading ? (
          <div style={{ height: 500 }} className="d-flex j-center">
            <Spin style={{ margin: "auto" }}></Spin>
          </div>
        ) : (
          <Card style={{ width: "100%" }}>
            <div className="t-center">
              <h1 style={{ textTransform: "capitalize" }}>
                Title : {data[0]?.title}
              </h1>
            </div>
            <hr />
            <div>
              <h2>Bids Received</h2>
            </div>
            <Table rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }} columns={columns} dataSource={data} pagination={false} rowKey='bid_id' />
            <div className="d-flex j-space">
              <div
                onClick={() => setShowConfirmationBox(true)}
                className={awardClasses.join(" ")}
                style={{ background: "#fabf01" }}
              >
                <VerifiedOutlined />
                &nbsp; Award Bid
              </div>
              <div onClick={() => setCompareBids(true)} className="btn">
                Compare Bids
              </div>
            </div>
          </Card>
        )}
      </div>
      <Modal
        destroyOnClose
        centered
        footer={null}
        onCancel={() => setViewId(null)}
        visible={viewId}
      >
        <BidSummary showEdit={false} bidId={viewId} />
      </Modal>

      <Modal
        destroyOnClose
        className="compareModal"
        centered
        footer={null}
        onCancel={() => setCompareBids(false)}
        visible={compareBids}
        width={'100%'}
      >
        <CompareBids />
      </Modal>

      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={'You have choosen to award the bid to '+ awardedSupplierName +'. Do you want to proceed ?' } confirmSubmit={placeAward} declineSubmit={declineSubmit} />
    </>
  );
}
