import React, { useState } from "react";
import axios from "axios";
import { message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import "../../Tender/AdditionalInfo/AdditionalInfo.css";
import "../../Tender/AttachFile/AttachFile.css";

import FileUpload from "../../../components/FileUpload";
import { awsDocumentUrl, domainUrl } from "../../../helper";
import { genHeaders, useTender } from "../PreTenderSteps";

import ConfirmationBox from '../../ConfirmationBox';
import { useDispatch } from "react-redux";
import { updatePreTender } from "../../../actions/pre-tender";
import FileDisplay from "../../../components/FileDisplay";

const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

const AttachFile = () => {
  const dispatch = useDispatch();
  const { preTenderDetails, onStepChange: move } = useTender();

  // const [description, setDescription] = useState("");
  const [fileToUploadList, setFileToUploadList] = useState(preTenderDetails?.files ?? []);
  const [fileList, setFileList] = useState(preTenderDetails?.files ?? []);
  const [isUploading, setIsUploading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // const onFile = (e) => {
  // console.log(e.target.files);

  // if (e.target.files && e.target.files.length) {
  // setFileList((c) => [
  // ...c,
  // ...[...e.target.files].map((file) => ({ documentId: null, file })),
  // ]);
  // }
  // };

  const onBack = () => {
    move(-1, { files: fileList });
  };

  const onNext = async() => {
    const filesToUpload = fileToUploadList.filter(doc => doc.documentId === null);
    if(filesToUpload.length > 0) {
       setShowConfirmationModal(true);
    }
    else{
      await dispatch(updatePreTender({
        state: parseInt(preTenderDetails.state) > 5 ? preTenderDetails.state : '5',
      },preTenderDetails.pretender_id));
      if(preTenderDetails.is_submitted !== 1) {
        move(1, { files: fileList });
      }
      else {
        move(2, { files: fileList },true);
      }
    }
  };

  const onUploadStatusChange = (e) => {
    setIsUploading(e);
  };

   const onFilesStatusChange = (e) => {
    setFileToUploadList(e);
  };

   const onFilesUploadStatusChange = (e) => {
    setFileList(e);
  };

  const confirmSubmit = (e) => {
    setShowConfirmationModal(false);
    move(1, { files: fileList });
  }

  const declineSubmit = (e) => {
    setShowConfirmationModal(false);
  }

  return (
    <div className="__AttachFile__">
      <div className="parentBlock2">
        {/* <div className="hedding"> */}
        {/* <h2>Input File</h2> */}
        {/* </div> */}
        {/* <div className="formGroup"> */}
        {/* <label className="leftCol">File Description</label> */}
        {/* <input */}
        {/* value={description} */}
        {/* onChange={(e) => setDescription(e.target.value)} */}
        {/* type="text" */}
        {/* className="formFiled" */}
        {/* /> */}
        {/* </div> */}
        {/* <div className="formGroup" style={{ flexDirection: "column" }}> */}
        {/* <div className="file-drop-area"> */}
        {/* <svg */}
        {/* xmlns="http://www.w3.org/2000/svg" */}
        {/* width="68.289" */}
        {/* height="47.803" */}
        {/* viewBox="0 0 68.289 47.803" */}
        {/* > */}
        {/* <path */}
        {/* id="Icon_awesome-cloud-upload-alt" */}
        {/* data-name="Icon awesome-cloud-upload-alt" */}
        {/* d="M57.363,23.014A10.256,10.256,0,0,0,47.8,9.079a10.191,10.191,0,0,0-5.687,1.729,17.074,17.074,0,0,0-31.872,8.515c0,.288.011.576.021.864a15.369,15.369,0,0,0,5.1,29.866H54.632a13.657,13.657,0,0,0,2.732-27.038ZM41.977,29.566H35V41.516a1.712,1.712,0,0,1-1.707,1.707H28.169a1.712,1.712,0,0,1-1.707-1.707V29.566H19.484a1.7,1.7,0,0,1-1.206-2.913L29.525,15.406a1.713,1.713,0,0,1,2.411,0L43.182,26.653a1.706,1.706,0,0,1-1.206,2.913Z" */}
        {/* transform="translate(0 -2.25)" */}
        {/* fill="#57b8ff" */}
        {/* /> */}
        {/* </svg> */}

        {/* <span className="file-msg"> */}
        {/* Drop files here to upload or Choose file */}
        {/* </span> */}
        {/* <input */}
        {/* onChange={onFile} */}
        {/* className="file-input" */}
        {/* type="file" */}
        {/* multiple */}
        {/* /> */}
        {/* </div> */}
        {/* <div */}
        {/* onClick={isUploading ? null : onFileUploadClick} */}
        {/* className="btn" */}
        {/* style={{ alignSelf: "flex-end" }} */}
        {/* > */}
        {/* Attach File */}
        {/* </div> */}
        {/* </div> */}

        {/* {fileList?.map((el, index) => ( */}
        {/* <div key={index} style={{ position: "relative" }}> */}
        {/* <div className="filecard"> */}
        {/* <h3>Name : {el.file.name}</h3> */}
        {/* <h3>Size : {Math.round(el.file.size / 1024)} KB</h3> */}
        {/* </div> */}
        {/* <DeleteOutlined */}
        {/* onClick={() => (isUploading ? null : onFileDelete(index))} */}
        {/* style={{ */}
        {/* position: "absolute", */}
        {/* right: "10px", */}
        {/* top: "10px", */}
        {/* color: "red", */}
        {/* }} */}
        {/* /> */}
        {/* </div> */}
        {/* ))} */}
        <FileUpload
          filePostURL={FILE_POST_URL}
          entityType={"P"}
          entityID={preTenderDetails?.pretender_id}
          onFilesStatusChange={onFilesStatusChange}
          onUploadStatusChange={onUploadStatusChange}
          onFilesUploadStatusChange={onFilesUploadStatusChange}
        />
        <FileDisplay entityType={'P'} fileList={fileList} entityId={preTenderDetails?.pretender_id} hideTitle={false} deleteFiles={true}/>
        <div className="buttonBlock">
          <div onClick={isUploading ? null : onBack} className="btn cancelBtn">
            Back
          </div>
          <div onClick={isUploading ? null : onNext} className="btn">
            Next
          </div>
        </div>
      </div>
      <ConfirmationBox showConfirmationBox={showConfirmationModal} message={'You have added files but not uploaded. Proceed without uploading?' } confirmSubmit={confirmSubmit} declineSubmit={declineSubmit} />
    </div>
  );
};

export default AttachFile;
