import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Moment from 'react-moment';
import { makeStyles } from "@material-ui/core/styles";
import { Table } from 'antd';
import Paper from '@material-ui/core/Paper';
import { Button, TextField } from "@material-ui/core";
import axios from "axios";

import ConfirmationBox from '../ConfirmationBox';

import { domainUrl }  from "../../helper";
import { compNames, getFilterValues } from '../../utils';

import {
    fetchNewSuppliersRequest,
    supplierRequestDecision,
} from "../../actions/suppliers";

const useStyles = makeStyles((theme) => ({
    favIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    fav: {
        marginRight: 5,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
      height: 45,
    border: 'solid 0.5px #d5d7e3',
    backgroundColor: '#e6e9f4',
    padding: '5px 15px',
    fontFamily: 'Poppins',
    fontSize: 16,
    color: '#3f3f41',
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: 8,
      backgroundColor: '#57b8ff',
      color: '#fff',
      fontFamily: 'Poppins',
      fontSize: 16,
    fontWeight: 500,
    },
    inviteWrapper: {
        padding: 20,
        marginTop: 30,

    },
    form: {
        margin: '0 auto',
        width: '95%',
    }
}));

const SupplierNewRequestList = ({ value, showFeedBack, viewSupplierDetails }) => {

    const classes = useStyles();
    
    const [suppliersRequest, setSuppliersRequest] = useState([]);

    const [formEmailInput, setFormEmailInput] = useState(
        {
          supplier_email: ""
        }
      );
    const [pagination, setPagination] = useState({
        pageSize: 10
        });
    const [message, setMessage] = useState('');
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [decision, setDecision] = useState('');
    const [supplierId, setSupplierId] = useState();

    const dispatch = useDispatch();

    async function getSuppliers() {
        const response = await dispatch(fetchNewSuppliersRequest({limit: 1000, status: 'pending'}));
        const supplierRequestData = Object.values(response?.payload?.entities?.suppliersRequest || []);
        setSuppliersRequest(supplierRequestData);
    }

    useEffect(() => {
        if(value === 0)
            getSuppliers();
    }, [value]);

    // const mapCompanyIdNames = (assetData, companyData) => {
    // 	assetData.map((asset) => {
    // 		const assetCompany = companyData.find(
    // 			(company) => company.company_id === asset.company_id
    // 		);
    // 		asset.company_name = assetCompany.company_name;
    // 		asset.organization_name = assetCompany.organization_name;
    // 	});
    // 	setAssets(assetData);
    // };

    const columns = [{
            dataIndex: "supplier_name",
            title: "Supplier Name",
            filters: getFilterValues("supplier_name", suppliersRequest),
			onFilter: (value, record) => record.supplier_name.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.supplier_name, b.supplier_name);
				},
			},
        },
        {
            dataIndex: "request_datetime",
            title: "Date Recieved",
            render: (value, tableMeta, updateValue) => (
                <Moment format="DD-MM-YYYY">
                    {value}
                </Moment>
            ),
        },
        {
            dataIndex: "supplier_request_id",
            title: "View",
            width:70,
            render: (value, tableMeta, updateValue) => (
                <div
                    className="viewIcon"
                    onClick={() => openSupplierForm(value, { readOnly: true })}
                >
                    View
                </div>
            ),
        },
        {
            dataIndex: "supplier_request_id",
            title: "Accept",
            width:150,
            render: (value, tableMeta, updateValue) => (
                <div
                    className="btn-primary"
                    onClick={() =>{
                        makeDecision(value, 'approve')
                      }
                    }
                >
                    Accept
                </div>
            ),
        },
        {
            dataIndex: "supplier_request_id",
            title: "Reject",
            width: 150,
            render: (value, tableMeta, updateValue) => (
                <div
                    className="btn-secondary"
                    onClick={() =>
                        makeDecision(value, 'reject')
                    }
                >
                    Reject
                </div>
            ),
        },
    ];

    const declineSubmit = () => {
		setShowConfirmationBox(false);
		setMessage('' );
        setSupplierId('');
	}

    const makeDecision = (supplierId, decision) => {
        setMessage('Are you sure you want to '+ decision + ' the supplier?' );
        setSupplierId(supplierId);
        setDecision(decision);
        setShowConfirmationBox(true);
    }

    const acceptRejectRequest = async () => {
        setMessage('' );
        setShowConfirmationBox(false);
        const response = await dispatch(supplierRequestDecision(supplierId, { status: decision }));
        setSupplierId('');
        const responseStatus = response.payload.body;
        if (responseStatus && !responseStatus?.success) {
            showFeedBack({
                open: true,
                success: false,
                error: true,
                info: false,
                message: responseStatus?.error?.message,
            })
            
        } else {
            getSuppliers();
            showFeedBack({
                open: true,
                success: true,
                error: false,
                info: false,
                message: `The request has been ${decision === 'approve' ? 'approved' : 'rejected'} successfully`,
            })
        }
        
    }

    const getSelectedSupplier = (supplier_request_id) => {
    	return suppliersRequest.find(supplier => supplier.supplier_request_id === supplier_request_id);
    };

    const openSupplierForm = (supplier_request_id, opts) => {
        let selected = getSelectedSupplier(supplier_request_id);
        viewSupplierDetails(selected, opts);
    };

    const handleInput = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormEmailInput({ [name]: newValue });
    };

    const handleSubmit = evt => {
        evt.preventDefault();

        axios
        .post(
          `${domainUrl}/masterservicenew/master/supplier_request/invite`,
          formEmailInput,
          {
            headers: {
                token: sessionStorage.getItem("token"),
                client_code: "ht_front",
            },
          }
        )
        .then(() => {
            showFeedBack({
                open: true,
                success: true,
                error: false,
                info: false,
                message: 'Invitation send successfully',
            })
        })
        .catch((e) => {
            console.log(e.error)
           showFeedBack({
                open: true,
                success: false,
                error: true,
                info: false,
                message: 'Unable to send invitation to supplier',
            })
        })
        .finally(() => {
          setFormEmailInput({
              supplier_email: ""
          })
        });
    };


   return (
		<>
		
            <Table
                className="ant-table-root"
                scroll={{ x: 0, y: '40vh' }}
                columns={columns}
                dataSource={suppliersRequest}
                pagination={pagination}
                bordered
                rowKey='supplier_request_id'
            />
			<div className="invite">
				<Paper elevation={3} className={classes.inviteWrapper}>
                    <form onSubmit={handleSubmit} className={classes.form}>
                    <label>Invite new suppliers to join</label>
                    <input
                      className={classes.textField}
                            placeholder="Enter email id"
                            onChange={handleInput}
                      type="text"
                      name="supplier_email"
                      value={formEmailInput.supplier_email}
                    />
                       
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                          >
                            Send Invitation
                          </Button>
                    </form>
                </Paper>
			</div>
            <ConfirmationBox showConfirmationBox={showConfirmationBox} message={message} confirmSubmit={acceptRejectRequest} declineSubmit={declineSubmit} />
		
		</>
	);
};

export default SupplierNewRequestList;