import React from "react";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import {  Radio, Form, Input, Row, Col } from 'antd';

const InspectionLine = ({ lineData, isReadOnly }) => {

	return (
		<>
			<Row className="line_row" key={lineData.inspection_item_id}>
				<Col  md={6} lg={8} xl={8}>
					{lineData.inspection_item_name}
				</Col>
				<Col md={8} lg={5} xl={5}>
				
								{
									!isReadOnly ? 
									(<Form.Item
								label=""
								name={['lineItems',lineData.inspection_item_id,'inspection_amc_cost']}
							>
									<Radio.Group name={lineData.inspection_item_id+'radioGroup'} initialValues={-1}  disabled={isReadOnly}>
									<Row gutter={{ md: 16, lg: 12}}>
								{lineData.inspection_charges.map(charges => (
									<Col>
									<Radio value={[charges.display_name, charges.inspection_amc_cost, charges.inspection_amc_id]}></Radio>
									</Col>
								))}
								</Row>
  								</Radio.Group>
								  </Form.Item>)
								  :
								  (<Form.Item
									label=""
								>
										<Radio.Group name={lineData.inspection_item_id+'radioGroup'} defaultValue={lineData.display_name}  disabled={isReadOnly}>
										<Row gutter={{ md: 16, lg: 12}}>
										<Col>
										<Radio value={'1'}></Radio>
										</Col>
										<Col>
										<Radio value={'2'}></Radio>
										</Col>
										<Col>
										<Radio value={'3'}></Radio>
										</Col>
										<Col>
										<Radio value={'4'}></Radio>
										</Col>
										<Col>
										<Radio value={'Ok'}></Radio>
										</Col>
									</Row>
									  </Radio.Group>
									  </Form.Item>)
								}
								
								
				</Col>
				<Col span={2}>
					{
						!isReadOnly ? (
							<Form.Item
						label=""
						name={['lineItems',lineData.inspection_item_id,'inspection_item_value']}>
							<Input />
					</Form.Item>
						) : (
							<Form.Item
								label="">
									<Input value={lineData.inspection_item_value} disabled={isReadOnly}/>
							</Form.Item>
						)
					}
					
				</Col>

				<Col span={8} offset={1}>
					{
						!isReadOnly ? (
							<Form.Item
								label=""
								name={['lineItems',lineData.inspection_item_id,'inspection_remarks']}
							>
								<Input.TextArea disabled={isReadOnly}/>
								</Form.Item>
						) : (
							<Form.Item
								label=""
							>
							<Input.TextArea value={lineData.inspection_remarks} disabled={isReadOnly}/>
							</Form.Item>
						)
					}
				
				</Col>
			</Row>
		</>
	);
};

InspectionLine.propTypes = {
	lineData: PropTypes.shape({}),
	isReadOnly: PropTypes.bool,
};

export default InspectionLine;