import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import get from 'lodash/get';
import {
    handleResponse,
    handleDataSuccessResponse,
    handleError,
    handleQuery,
} from "../utils";
import { domainUrl } from "../helper";

export const FETCH_SUPPLIERS_REQUEST = "FETCH_SUPPLIERS_REQUEST";
export const FETCH_SUPPLIERS_SUCCESS = "FETCH_SUPPLIERS_SUCCESS";
export const FETCH_SUPPLIERS_FAILURE = "FETCH_SUPPLIERS_FAILURE";

export const FETCH_NEW_SUPPLIERS_REQUEST = "FETCH_NEW_SUPPLIERS_REQUEST";
export const FETCH_NEW_SUPPLIERS_SUCCESS = "FETCH_NEW_SUPPLIERS_SUCCESS";
export const FETCH_NEW_SUPPLIERS_FAILURE = "FETCH_NEW_SUPPLIERS_FAILURE";

export const CREATE_NEW_SUPPLIERS_REQUEST = "CREATE_NEW_SUPPLIERS_REQUEST";
export const CREATE_NEW_SUPPLIERS_SUCCESS = "CREATE_NEW_SUPPLIERS_SUCCESS";
export const CREATE_NEW_SUPPLIERS_FAILURE = "CREATE_NEW_SUPPLIERS_FAILURE";

export const FETCH_SUPPLIER_DECISION_REQUEST = "FETCH_SUPPLIER_DECISION_REQUEST";
export const FETCH_SUPPLIER_DECISION_SUCCESS = "FETCH_SUPPLIER_DECISION_SUCCESS";
export const FETCH_SUPPLIER_DECISION_FAILURE = "FETCH_SUPPLIER_DECISION_FAILURE";

export const FETCH_SUPPLIER_REQUEST = "FETCH_SUPPLIER_REQUEST";
export const FETCH_SUPPLIER_SUCCESS = "FETCH_SUPPLIER_SUCCESS";
export const FETCH_SUPPLIER_FAILURE = "FETCH_SUPPLIER_FAILURE";

export const CREATE_SUPPLIER_REQUEST = "CREATE_SUPPLIER_REQUEST";
export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const CREATE_SUPPLIER_FAILURE = "CREATE_SUPPLIER_FAILURE";

export const UPDATE_SUPPLIER_REQUEST = "UPDATE_SUPPLIER_REQUEST";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_FAILURE = "UPDATE_SUPPLIER_FAILURE";

export const DELETE_SUPPLIER_REQUEST = "DELETE_SUPPLIER_REQUEST";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_FAILURE = "DELETE_SUPPLIER_FAILURE";

export const supplierSchema = new schema.Entity("suppliers", [], {
    idAttribute: "supplier_id",
});

export const supplierRequestSchema = new schema.Entity("suppliersRequest", [], {
    idAttribute: "supplier_request_id",
});

/**
 * Returns a redux api middleware action that makes a request for a collection of Suppliers.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchSuppliers = (params = {}) =>
    createAction({
        endpoint: `${domainUrl}/masterservicenew/master/supplier?${handleQuery(params)}`,
        method: "GET",
        headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        types: [
            { type: FETCH_SUPPLIERS_REQUEST },
            {
                type: FETCH_SUPPLIERS_SUCCESS,
                payload: (action, state, res) =>
                    handleResponse([supplierSchema], action, state, res),
            },
            { type: FETCH_SUPPLIERS_FAILURE, payload: handleError },
        ],
    });

/**
 * Returns a redux api middleware action that makes a request for a collection of new suppliers request.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchNewSuppliersRequest = (params) => 
    createAction({
        endpoint: `${domainUrl}/masterservicenew/master/supplier_request?${handleQuery(params)}`,
        method: "GET",
        headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        types: [
            { type: FETCH_NEW_SUPPLIERS_REQUEST },
            {
                type: FETCH_NEW_SUPPLIERS_SUCCESS,
                payload: (action, state, res) =>
                    handleResponse([supplierRequestSchema], action, state, res),
            },
            { type: FETCH_NEW_SUPPLIERS_FAILURE, payload: handleError },
        ],
    });

/**
 * Returns a redux api middleware action that makes a request to create a supplier.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const createSuppliersRequest = (data, params) => 
    createAction({
        endpoint: `${domainUrl}/masterservicenew/master/supplier_request?${handleQuery(params)}`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            client_code: "ht_front",
            "Content-Type": "application/json",
        },
        types: [
            { type: CREATE_NEW_SUPPLIERS_REQUEST },
            {
                type: CREATE_NEW_SUPPLIERS_SUCCESS,
                payload: (action, state, res) =>
                    handleResponse([supplierRequestSchema], action, state, res),
            },
            { type: CREATE_NEW_SUPPLIERS_FAILURE, payload: handleError },
        ],
    });

/**
 * Returns a redux api middleware action that makes a request to accept/reject a new supplier request.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const supplierRequestDecision = (request_id, data) =>
    createAction({
        endpoint: `${domainUrl}/masterservicenew/master/supplier_request/${request_id}/status`,
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        types: [
            { type: FETCH_SUPPLIER_DECISION_REQUEST },
            {
                type: FETCH_SUPPLIER_DECISION_SUCCESS,
                payload: (action, state, res) =>
                    handleDataSuccessResponse([supplierRequestSchema], action, state, res),
            },
            { type: FETCH_SUPPLIER_DECISION_FAILURE, payload: handleError },
        ],
    });

/**
 * Returns a redux api middleware action that fetches an supplier.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchSupplier = (supplier_id) =>
    createAction({
        endpoint: `${domainUrl}/masterservicenew/master/supplier/${supplier_id}`,
        method: "GET",
        headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        bailout: store => get(store, `entities.suppliers.${supplier_id}`, false),
        types: [
            { type: FETCH_SUPPLIER_REQUEST },
            {
                type: FETCH_SUPPLIER_SUCCESS,
                payload: (action, state, res) =>
                    handleResponse(supplierSchema,
                        action,
                        state,
                        res
                    ),
            },
            { type: FETCH_SUPPLIER_FAILURE, payload: handleError },
        ],
    });

/**
 * Returns a redux api middleware action that creates an supplier.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const createSupplier = (data) =>
    createAction({
        endpoint: `${domainUrl}/masterservicenew/master/supplier`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        types: [
            { type: CREATE_SUPPLIER_REQUEST },
            {
                type: CREATE_SUPPLIER_SUCCESS,
                payload: (action, state, res) =>
                    handleDataSuccessResponse({},
                        action,
                        state,
                        res
                    ),
            },
            { type: CREATE_SUPPLIER_FAILURE, payload: handleError },
        ],
    });

/**
 * Returns a redux api middleware action that updates an supplier.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const updateSupplier = (supplier_id, data) =>
    createAction({
        endpoint: `${domainUrl}/masterservicenew/master/supplier/${supplier_id}`,
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        types: [
            { type: UPDATE_SUPPLIER_REQUEST },
            {
                type: UPDATE_SUPPLIER_SUCCESS,
                payload: (action, state, res) =>
                    handleDataSuccessResponse({},
                        action,
                        state,
                        res
                    ),
            },
            { type: UPDATE_SUPPLIER_FAILURE, payload: handleError },
        ],
    });

/**
 * Returns a redux api middleware action that deletes an Asset.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const deleteSupplier = (supplier_id) =>
    createAction({
        endpoint: `${domainUrl}/masterservicenew/master/supplier/${supplier_id}`,
        method: "DELETE",
        headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
        },
        types: [
            { type: DELETE_SUPPLIER_REQUEST },
            {
                type: DELETE_SUPPLIER_SUCCESS,
                payload: (action, state, res) =>
                    handleDataSuccessResponse({},
                        action,
                        state,
                        res
                    ),
            },
            { type: DELETE_SUPPLIER_FAILURE, payload: handleError },
        ],
    });