import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import { handleResponse, handleError, handleQuery } from "../utils";
import { domainUrl } from "../helper";

export const FETCH_ORGS_REQUEST = "FETCH_ORGS_REQUEST";
export const FETCH_ORGS_SUCCESS = "FETCH_ORGS_SUCCESS";
export const FETCH_ORGS_FAILURE = "FETCH_ORGS_FAILURE";

export const FETCH_ORG_REQUEST = "FETCH_ORG_REQUEST";
export const FETCH_ORG_SUCCESS = "FETCH_ORG_SUCCESS";
export const FETCH_ORG_FAILURE = "FETCH_ORG_FAILURE";

export const orgSchema = new schema.Entity("organizations", [], {
  idAttribute: "organization_id",
});

/**
 * Returns a redux api middleware action that makes a request for a collection of Organizations.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchOrganizations = (params = {}) =>
  createAction({
    endpoint: `${domainUrl}/masterservicenew/master/organization?${handleQuery(params)}`,
    method: "GET",
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: FETCH_ORGS_REQUEST },
      {
        type: FETCH_ORGS_SUCCESS,
        payload: (action, state, res) =>
          handleResponse([orgSchema], action, state, res),
      },
      { type: FETCH_ORGS_FAILURE, payload: handleError },
    ],
  });

  /**
 * Returns a redux api middleware action that makes a request for a collection of Organizations.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchOrganization = (organization_id) =>
  createAction({
    endpoint: `${domainUrl}/masterservicenew/master/organization/${organization_id}`,
    method: "GET",
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: FETCH_ORG_REQUEST },
      {
        type: FETCH_ORG_SUCCESS,
        payload: (action, state, res) =>
          handleResponse(orgSchema, action, state, res),
      },
      { type: FETCH_ORG_FAILURE, payload: handleError },
    ],
});